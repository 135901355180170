import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocationService } from '../service/location.service';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss'],
})
export class LocationComponent  {
  editMode: boolean = false;
  activeSection: string = 'map-location';

  constructor(private router: Router) { }
  
  setActiveSection(section: string): void {
    this.activeSection = section;
    if (section === 'map-location') {
      this.router.navigate(['/location']); 
      //this.activeSection = section;
    } else if (section === 'address-proof') {
      this.router.navigate(['/address']); 
      //this.activeSection = section;
    }
  }
}
