
import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
    selector: 'otp-type',
    template: `
        <div class="card flex justify-content-center">
      <div class="flex flex-column align-items-center">
        <p class="text-color-secondary block mb-3 mt-4">Please enter the code sent to your phone.</p>
        <p-inputOtp 
          [(ngModel)]="value" 
          [length]="6" 
          (ngModelChange)="onOtpChange($event)" 
          style="gap: 0">
          <ng-template pTemplate="input" let-token let-events="events" let-index="index">
            <input 
              pInputText 
              type="text" 
              [maxLength]="1" 
              (input)="events.input($event)" 
              (keydown)="events.keydown($event)"
              (paste)="events.paste($event)"
              [attr.value]="token" 
              class="custom-otp-input" />
            <div *ngIf="index === 3" class="px-3">
              <i class="pi pi-minus"></i>
            </div>
          </ng-template>
        </p-inputOtp>
      </div>
    </div>
    `,
    styles: [
        `
        .custom-otp-input {
            width: 48px;
            height: 48px;
            font-size: 24px;
            appearance: none;
            text-align: center;
            transition: all 0.2s;
            border-radius: 0;
            border: 1px solid var(--surface-400);
            background: transparent;
            outline-offset: -2px;
            outline-color: transparent;
            border-right: 0 none;
            transition: outline-color 0.3s;
            color: var(--text-color);
        }

        .custom-otp-input:focus {
            outline: 2px solid var(--primary-color);
        }

        .custom-otp-input:first-child,
        .custom-otp-input:nth-child(5) {
            border-top-left-radius: 12px;
            border-bottom-left-radius: 12px;
        }

        .custom-otp-input:nth-child(3),
        .custom-otp-input:last-child {
            border-top-right-radius: 12px;
            border-bottom-right-radius: 12px;
            border-right-width: 1px;
            border-right-style: solid;
            border-color: var(--surface-400);
        }
        `
    ],
})
export class OtpTypeComponent extends FieldType{
    value: any;

    onOtpChange(value: string) {
        this.formControl.setValue(value); // Update the FormControl's value
        this.formControl.markAsTouched(); // Mark it as touched
        this.formControl.updateValueAndValidity(); // Trigger validation
    }
}