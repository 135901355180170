import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-get-started',
  templateUrl: './get-started.component.html',
  styleUrl: './get-started.component.scss'
})
export class GetStartedComponent {

  constructor(private router: Router) {}

  navigateTo(role: string) {
    if (role === 'service-seeker') {
      //this.router.navigate(['/signup']); // Replace with your route
    } else if (role === 'service-provider') {
      this.router.navigate(['/otp']); // Replace with your route
    }
  }
}
