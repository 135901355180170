// custom-type
import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  OnInit,
  Input,
} from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { ChangeDetectorRef } from '@angular/core';
import { HttpEventType } from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { UserService } from '../../user-service.service';
import { ServiceAdvisorFormStateService } from '../../services/formstate/service-advisor-form-stae-service.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'formly-field-file-upload',
  template: `
      <!-- <p-toast position="bottom-center"></p-toast> -->
      <div
        class="file-upload"
        [ngClass]="{ 'file-upload-hover': isHovering }"
        (dragover)="onDragOver($event)"
        (dragleave)="onDragLeave($event)"
        (drop)="onDrop($event)"
        (click)="fileInput.click()"
      >
        <input
          type="file"
          #fileInput
          (change)="onFileSelect($event)"
          style="display: none;"
        />
  
        <!-- Display when no file is selected -->
        <ng-container *ngIf="!fileName && !isUploading">
          <i class="pi pi-cloud-upload" style="font-size:2.5rem;"></i>
          <p class="top">
            <span id="click">Click to upload</span> or drag and drop
          </p>
          <p class="svg">SVG, PNG, JPG, PDF (max. 800x400px)</p>
        </ng-container>
  
        <!-- Display when a file is selected or loaded from saved data -->
        <ng-container *ngIf="fileName && !isUploading">
        <ng-container *ngIf="isImageFile(fileUrl)">
  <img
    [src]="fileUrl"
    alt="Selected file"
    class="uploaded-image"
    width="500"
    height="600"
  />
</ng-container>
<ng-container *ngIf="!isImageFile(fileUrl)">
  <img
    src="assets/image/pdf-icon.png"
    alt="PDF Icon"
    class="pdf-icon"
    style="width: 100px; height: auto;"
  />
  <p>{{ fileName }}</p>
</ng-container>

          <button
            (click)="removeFile($event)"
            class="remove-button"
            style="font-weight: 700;"
          >
            <i
              class="pi pi-trash"
              style="font-weight:700; font-size:13px; margin-right:5px"
            ></i
            >Remove
          </button>
        </ng-container>
  
        <!-- Display during file upload -->
        <ng-container *ngIf="isUploading">
          <div class="spinner-container">
            <div class="spinner"></div>
            <div class="progress-text">{{ uploadProgress }}%</div>
          </div>
        </ng-container>
      </div>
    `,
  styles: [
    `
        .top {
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
        }
        #click {
          color: var(--Brand-700, #d47500);
        }
        .svg {
          color: var(--Gray-600, #475467);
          text-align: center;
          font-family: Inter;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
        }
        .file-upload {
          border: 2px dashed #e4e7ec;
          padding: 16px 24px;
          text-align: center;
          cursor: pointer;
          transition: border-color 0.3s ease;
          height: 287px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
        }
        .file-upload-hover {
          border-color: #ff9f2a;
        }
        .file-upload:hover {
          border-color: #ff9f2a;
        }
        .file-upload p {
          margin: 0;
          color: #666;
        }
        .uploaded-image {
          max-width: 463px;
          max-height: 255px;
          margin-bottom: 10px;
        }
        .remove-button {
          background: none;
          border: none;
          color: #b42318;
          cursor: pointer;
          font-size: 14px;
          top: 110px;
          position: absolute;
          right: 30px;
        }
        .spinner-container {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .spinner {
          border: 4px solid #ff8c00;
          border-top: 4px solid #ff8c00;
          border-radius: 50%;
          width: 58px;
          height: 58px;
          animation: spin linear infinite;
        }
        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
        .progress-text {
          margin-top: 10px;
          font-family: Inter;
          font-size: 14px;
          font-weight: 600;
          color: #ff8c00;
        }
      `,
  ],
})
export class FormlyFieldFileUploadComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit {
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;
  isHovering = false;
  fileName: string | undefined;
  fileUrl: any;
  isUploading = false;
  uploadProgress = 0;
  @Input() fileSourceId: any;
  safeFileUrl: SafeUrl | undefined;
  constructor(
    private cdr: ChangeDetectorRef,
    private fileService: ServiceAdvisorFormStateService,
    private api: UserService,
    private messageService: MessageService,
    private sanitizer: DomSanitizer,
  ) {
    super();
  }

  ngOnInit() {
    this.fileService.stateUpdated.subscribe(() => {
      const updatedData = this.fileService.getFormData('resumeFile');
      console.log('FormlyFieldFileUploadComponent: Updated data:', updatedData);
      this.patchWithSavedData(updatedData);
    });
  
    const savedData = this.fileService.getFormData('resumeFile');
    console.log('FormlyFieldFileUploadComponent: Saved data on init:', savedData);
  
    if (savedData) {
      this.patchWithSavedData(savedData);
    }
  }
  
  patchWithSavedData(savedData: any) {
    if (savedData) {
      this.fileName = savedData.fileName;
      this.fileUrl = savedData.fileUrl;
      this.safeFileUrl = this.sanitizer.bypassSecurityTrustUrl(this.fileUrl);
      this.formControl.setValue(savedData.file || savedData.fileUrl);
      this.cdr.detectChanges();
    }
  }
  

  onFileSelect(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      this.fileName = file.name;
      this.fileUrl = URL.createObjectURL(file);
      this.formControl.setValue(file);
      this.fileService.setFormData('resumeFile', {
        file,
        fileName: this.fileName,
        fileUrl: this.fileUrl,
      });
      this.uploadFile(file);
      this.cdr.detectChanges();
    }
  }

  uploadFile(file: File) {
    const formData = new FormData();
    formData.append('File', file);
    const fileSourceId = this.to['fileSourceId'];
    if (!fileSourceId) {
      console.error('file sourceId is undefined');
      return;
    }
    formData.append('FileSourceId', fileSourceId.toString());
    this.isUploading = true;
    this.uploadProgress = 0;

    this.api.uploadFile(formData).subscribe(
      (event) => {
        if (event.type === HttpEventType.UploadProgress && event.total) {
          this.uploadProgress = Math.round((100 * event.loaded) / event.total);
          this.cdr.detectChanges();
        } else if (event.type === HttpEventType.Response) {
          const fileId = event.body.fileId;
          if (fileId) {
            this.fileService.setUploadedFileId('resumeFile', fileId);
          } else {
            console.error('File ID is missing in response');
          }
          this.isUploading = false;
          this.cdr.detectChanges();
        }
      },
      (error) => {
        console.error('Error uploading file', error);
        this.isUploading = false;
        this.cdr.detectChanges();
      }
    );
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    this.isHovering = true;
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    this.isHovering = false;
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    this.isHovering = false;
    const files = event.dataTransfer!.files;
    if (files.length > 0) {
      const file = files[0];
      this.fileName = file.name;
      this.fileUrl = URL.createObjectURL(file);
      this.formControl.setValue(file);
      this.fileService.setFormData('resumeFile', {
        file,
        fileName: this.fileName,
        fileUrl: this.fileUrl,
      });
      this.uploadFile(file);
    }
  }

  removeFile(event: Event) {
    event.stopPropagation();
    this.fileName = undefined;
    this.fileUrl = undefined;
    this.formControl.setValue(null);
    this.fileService.setFormData('resumeFile', null);
    this.fileInput.nativeElement.value = '';
    this.cdr.detectChanges();
  }
  showCustomToast() {
    this.messageService.add({
      key: 'customToast',
      severity: 'success',
      summary: 'Service Seeker application was approved successfully',
      detail: 'Close',
    });
  }

  onCloseToast() {
    this.messageService.clear('customToast');
  }
  isImageFile(fileNameOrUrl: string): boolean {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];
    const fileExtension = fileNameOrUrl.split('.').pop()?.toLowerCase();
    if (!fileExtension) {
      console.warn('Unable to determine file type for:', fileNameOrUrl);
      return false; // Default to non-image
    }
    return imageExtensions.includes(fileExtension);
  }
}
