<div style="margin-bottom: 25px; font-size: 20px; font-weight: 600">
  Add Business Details
</div>

<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <formly-form [model]="model" [fields]="fields" [form]="form"></formly-form>
  <button type="submit" class="btn btn-primary" style="font-weight: 600">
    Proceed
  </button>
</form>
<app-custom-toast></app-custom-toast>