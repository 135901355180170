import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  Observable,
} from 'rxjs';
import { ServiceProviderService } from '../serviceProvider/service-provider.service';

interface FormData {
  businessDetails?: any;
  subDomainName?: string;
  [key: string]: any;
}

@Injectable({
  providedIn: 'root',
})
export class FormStateService {
  constructor(private api: ServiceProviderService) {
    this.refreshBusinessTypes(); // Fetch the initial data
  }

  private formData = new Map<string, any>();
  private selectedCategory: string | null = null;
  isClearing = false;
  private selectedFiles: File[] = [];
  private loadFormDataFlag = true;
  private ownerDetails: any = {};

  getFormData(key: string): any {
    // return this.formData.get(key);
    const data = this.formData.get(key);
    console.log(`Getting form data for key: ${key}`, data); // Debugging line
    return data;
  }

  setFormData(key: string, data: any) {
    this.formData.set(key, data);
    console.log('Setting form data:', key, data);
  }

  saveFormData(key: string, data: any) {
    this.form[key] = data;
  }
  setSubDomainName(subDomainName: string): void {
    this.setFormData('subDomainName', subDomainName);
  }

  getSubDomainName(): string | undefined {
    return this.getFormData('subDomainName');
  }
  setServiceProviderId(id: any) {
    this.setFormData('serviceProviderId', id);
  }

  setUserId(id: any) {
    this.setFormData('CustomerId', id);
  }
  setServiceAdvisorId(id: any) {
    this.setFormData('serviceAdvisorId', id);
  }
  getServiceProviderId() {
    return this.getFormData('CustomerId');
  }
  getUserId() {
    return this.getFormData('CustomerId');
  }
  getServiceAdvisorId() {
    return this.getFormData('CustomerId');
  }

  getSelectedCategory(): string | null {
    return this.selectedCategory;
  }

  setSelectedCategory(category: string): void {
    this.selectedCategory = category;
  }

  private selectedFile: File | null = null;

  getSelectedFile(): File | null {
    return this.selectedFile;
  }

  setSelectedFile(file: File | null): void {
    this.selectedFile = file;
  }
  setSelectedFiles(files: File[]) {
    this.selectedFiles = files;
  }

  getSelectedFiles(): File[] {
    return this.selectedFiles;
  }

  clearSelectedFiles() {
    this.selectedFiles = [];
  }
  private profileDetailss = new BehaviorSubject<any>({});
  setProfileDetails(details: any) {
    this.profileDetailss.next(details);
  }
  private businessDetails = new BehaviorSubject<any>({});
  private ownerInchargeDetails = new BehaviorSubject<any>({});
  private services = new BehaviorSubject<any>({});
  private technicianCount = new BehaviorSubject<any>({});

  setBusinessDetails(details: any) {
    this.businessDetails.next(details);
  }

  setOwnerInchargeDetails(data: any) {
    this.ownerInchargeDetails.next(data);
  }

  setServices(details: any) {
    this.services.next(details);
  }

  setTechnicianCount(details: any) {
    console.log('Setting Technician Count:', details);
    const technicianData = Object.keys(details)
      .map((key) => ({
        technicianId: parseInt(key, 10),
        count: details[key] || null,
      }))
      .filter((technician) => technician.count !== null);

    this.technicianCount.next(technicianData);
  }

  getCombinedData() {
    const combined = {
      ...this.businessDetails.value,
      ...this.ownerInchargeDetails.value,
      serviceOfferedIds: this.services.value.serviceOfferedIds || [],
      noOfTechnician: this.technicianCount.value || [],
    };

    if (
      this.businessDetails.value.documents &&
      this.ownerInchargeDetails.value.documents
    ) {
      combined.documents = [
        ...this.businessDetails.value.documents,
        ...this.ownerInchargeDetails.value.documents,
      ];
    } else {
      combined.documents = [];
    }

    return combined;
  }

  clearFormData(): void {
    this.formData.clear();
    this.formData.set('service-offered', '')

    this.selectedCategory = null;
    this.selectedFile = null;
    this.selectedFiles = [];
    this.businessDetails.next({});
    this.ownerInchargeDetails.next({});
    this.services.next({});
    this.technicianCount.next({});
    this.selectedBusinessTypeSubject.next(null);
    this.businessTypesSubject.next([]);
    this.serviceProviderDetailsSubject.next({})
    console.log('FormStateService: Cleared all form data.');
  }

  private form: { [key: string]: any } = {};

  clearForm(key: string) {
    delete this.form[key];
  }

  //
  private businessTypesSubject = new BehaviorSubject<any[]>([]);
  private businessTypes$ = new BehaviorSubject<any[]>([]);

  private selectedBusinessTypeSubject = new BehaviorSubject<any>(null);
  selectedBusinessType$ = this.selectedBusinessTypeSubject.asObservable();

  setSelectedBusinessType(businessType: any): void {
    const normalizedBusinessType = {
      businessTypeId: businessType.value || businessType.businessTypeId,
      name: businessType.label || businessType.name,
      services: businessType.services,
    };
    if (
      JSON.stringify(normalizedBusinessType) !==
      JSON.stringify(this.selectedBusinessTypeSubject.value)
    ) {
      this.selectedBusinessTypeSubject.next(normalizedBusinessType);
    }
  }

  getBusinessTypes(): Observable<any[]> {
    return this.businessTypes$.asObservable();
  }

  refreshBusinessTypes() {
    this.api.getBusinessTypeList('').subscribe((allBusinessTypes: any[]) => {
      this.businessTypes$.next(allBusinessTypes);
    });
  }

  setBusinessTypes(businessTypes: any[]): void {
    this.businessTypesSubject.next(businessTypes);
  }

  private businessTypeSubject = new BehaviorSubject<any>(null);

  setBusinessType(businessType: string) {
    this.businessTypeSubject.next(businessType);
    console.log(businessType);
  }

  getBusinessType() {
    return this.businessTypeSubject.asObservable();
  }

  //for Sp Details
  private serviceProviderDetailsSubject = new BehaviorSubject<any>({});
  spDetails = this.serviceProviderDetailsSubject.asObservable();

  updateObject(newObject: any) {
    this.serviceProviderDetailsSubject.next(newObject);
  }

  // Add this method to return the current value without subscribing
  getCurrentSpDetails(): any {
    return this.serviceProviderDetailsSubject.value;
  }

  setOwnerDetails(details: any) {
    this.ownerDetails = details;
  }

  getOwnerDetails(): any {
    return this.ownerDetails;
  }

  private selectedPlan: any;

  setSelectedPlan(plan: any): void {
    this.selectedPlan = plan;
  }

  getSelectedPlan(): any {
    return this.selectedPlan;
  }
}
