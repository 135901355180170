import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private toastSubject = new BehaviorSubject<{
    message: string;
    type: 'success' | 'error';
    visible: boolean;
  }>({
    message: '',
    type: 'success',
    visible: false,
  });

  toastState$ = this.toastSubject.asObservable();

  showToast(message: string, type: 'success' | 'error') {
    this.toastSubject.next({ message, type, visible: true });

    setTimeout(() => {
      this.hideToast();
    }, 15000);
  }
  showOTP(message: string, type: 'success' | 'error') {
    this.toastSubject.next({ message, type, visible: true });
  }

  hideToast() {
    this.toastSubject.next({ ...this.toastSubject.value, visible: false });
  }
}
