import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OtpService } from '../services/otpservice/otp.service';
import { ToastService } from '../../toastr/toastr_service/service.service';
import { ServiceProviderService } from '../services/serviceProvider/service-provider.service';
import { environment } from '../../../environments/environment.development';

@Component({
  selector: 'app-otp-page',
  templateUrl: './otp-page.component.html',
  styleUrls: ['./otp-page.component.scss'],
})

export class OtpPageComponent implements OnInit {
  model: any = {
    phoneNumber: '',
    countryCode: '91', // Default to India's country code (numeric only)
    countryId: 1, // Default to India's countryId
    otp: '',
  };

  countryCodes: any[] = []; // Dynamically populated from API
  otpSent = false;
  otpInputs: string[] = Array(6).fill('');
  otpVerified = false;
  resendTimer: number = 0;
  resendInterval: any;

  constructor(
    private otpService: OtpService,
    private spService: ServiceProviderService,
    private toastService: ToastService,
    private router: Router
  ) { }

  ngOnInit() {
    // Clear local storage when the component is initialized
    localStorage.clear();
    console.log('Environment:', environment);
    this.fetchCountries(); // Load countries on component initialization
  }

  // Fetch country list from the API

  countryCodeMap: { [key: string]: string } = {
    India: 'IN',
    Oman: 'OM',
    // Add more countries as needed
  };
  
  fetchCountries() {
    this.spService.getCountry('').subscribe({
      next: (response) => {
        this.countryCodes = response.map((country: any) => ({
          label: `${this.countryCodeMap[country.name] || country.name} (${country.countryCode})`,
          value: country.countryCode.replace('+', ''), // Strip the '+' from the country code
          id: country.countryId,
        }));

        console.log('Fetched countries:', this.countryCodes);
      },
      error: (err) => {
        console.error('Error fetching countries:', err);
        this.toastService.showToast('Failed to load countries. Please try again.', 'error');
      },
    });
  }

  // Handle Phone Number Input
  handlePhoneNumberChange() {
    this.otpSent = false; // Reset OTP state when phone number changes
    this.model.otp = ''; // Clear the OTP field
    this.resetOtpState();
  }

  // Update countryId when countryCode changes
  updateCountryId() {
    const selectedCountry = this.countryCodes.find(
      (code) => code.value === this.model.countryCode
    );
    this.model.countryId = selectedCountry ? selectedCountry.id : 0;
  }

  // Send OTP logic
  sendOtp() {
    if (this.model.phoneNumber.length !== 10) {
      this.toastService.showToast('Please enter a valid 10-digit phone number.', 'error');
      return;
    }

    // Construct payload
    const { phoneNumber, countryId } = this.model;

    console.log('Sending OTP with payload:', phoneNumber, countryId);

    this.otpService.sendOtp(phoneNumber, countryId).subscribe({
      next: (response) => {
        if (response.isSuccess) {
          this.otpSent = true;
          const otp = response.result.otp || '';
          const message = response.result.message || `Your OTP is: ${otp}`;
          this.toastService.showOTP(message, 'success');
          this.startResendTimer();
        } else {
          this.toastService.showToast(response.errors.join(', '), 'error');
        }
      },
      error: (err) => {
        console.error('Error sending OTP:', err);
        this.toastService.showToast('Failed to send OTP. Please try again.', 'error');
        this.resetOtpState();
      },
    });
  }

  // Resend OTP logic
  resendOtp() {
    const payload: any = {
      phoneNumber: this.model.phoneNumber, // Send only the phone number
      countryId: this.model.countryId, // Send the selected countryId
    };

    console.log('Resending OTP with payload:', payload);

    this.otpService.resendOtp(payload).subscribe({
      next: (response) => {
        if (response.isSuccess) {
          const otp = response.result.otp || '';
          const message = response.result.message || `Your OTP is: ${otp}`;
          this.toastService.showToast(message, 'success');
          this.startResendTimer();
        } else {
          this.toastService.showToast(response.errors.join(', '), 'error');
        }
      },
      error: (err) => {
        console.error('Error resending OTP:', err);
        this.toastService.showToast('Failed to resend OTP. Please try again.', 'error');
      },
    });
  }

  // Reset OTP State
  resetOtpState() {
    this.otpSent = false;
    this.resendTimer = 0; // Reset the timer
    if (this.resendInterval) {
      clearInterval(this.resendInterval); // Stop the timer
    }
    this.model.otp = ''; // Clear the OTP field
  }

  startResendTimer() {
    this.resendTimer = 30; // Set timer to 30 seconds
    if (this.resendInterval) {
      clearInterval(this.resendInterval); // Clear any existing interval
    }

    this.resendInterval = setInterval(() => {
      this.resendTimer--;
      if (this.resendTimer <= 0) {
        clearInterval(this.resendInterval); // Stop the timer when it reaches 0
      }

    }, 1000); // Decrement the timer every second
  }
  // OTP Change Handler
  onOtpChange(otp: string) {
    this.model.otp = otp; // Update the model's OTP value
    console.log('Current OTP:', otp);
  }
  // Check if OTP is Valid
  isOtpValid() {
    return this.model.otp && this.model.otp.length === 6; // Ensure OTP is 6 digits
  }

  // Verify OTP
  onSubmit() {
    if (!this.isOtpValid()) {
      this.toastService.showToast('Please enter a valid OTP.', 'error');
      return;
    }

    const { phoneNumber, otp } = this.model;

    console.log('Verifying OTP with phoneNumber and otp:', { phoneNumber, otp });

    this.otpService.verifyOtp(phoneNumber, otp).subscribe({
      next: (response) => {
        if (response.isSuccess) {
          this.otpVerified = true;
          this.toastService.showToast(response.result.message, 'success');

          const { phoneNumber, tempRegId } = response.result;

          // Store data in OtpService for later use
          this.otpService.setOtpVerificationData({ phoneNumber, tempRegId });

          // Navigate to the Signup page
          this.router.navigate(['/signup']);
        } else {
          this.toastService.showToast(response.errors.join(', '), 'error');
        }
      },
      error: (err) => {
        console.error('Failed to verify OTP:', err);
        this.toastService.showToast('Failed to verify OTP. Please try again.', 'error');
      },
    });
  }

}
