import { AbstractControl } from "@angular/forms";

export function fieldMatchValidator(control: AbstractControl) {
  const subDomain = control.get('subDomain')?.value;
  const databaseName = control.get('databaseName')?.value;

  if (!databaseName || !subDomain) {
    return null;
  }

  if (databaseName === subDomain) {
    return null;
  }

  return { fieldMatch: { message: 'Sub Domain does not match Database Name' } };
}