import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { ServiceProviderService } from '../services/serviceProvider/service-provider.service';
//import { FormStateService } from './form-state.service';
import { FormStateService } from '../services/formstate/form-stae-service.service';
import { environment } from '../../../environments/environment.development';

@Component({
  selector: 'formly-field-file',
  template: `
    <div class="custom-file-upload mt-2">
      <label
        *ngIf="!fileSelected && !selectedFile"
        for="{{ id }}"
        class="custom-file-upload-label"
      >
        <img [src]="imgPath" class="mr-2" />
        {{ to.placeholder }}
      </label>
      <div
        *ngIf="fileSelected || selectedFile"
        class="custom-file-upload-label"
      >
        <span (click)="previewFile()" class="file-status">{{
          fileStatus
        }}</span>
        <i
          (click)="clearFileSelection()"
          class="pi pi-trash"
          style="color: red; cursor: pointer;"
        ></i>
      </div>
      <input
        id="{{ id }}"
        type="file"
        (change)="onFileSelect($event)"
        class="custom-file-input"
        [formControl]="formControl"
        [formlyAttributes]="field"
      />
    </div>

    <div *ngIf="previewSrc" class="preview-container">
      <img
        *ngIf="isImageFile()"
        [src]="previewSrc"
        alt="Image preview"
        class="img-preview"
      />
      <a
        *ngIf="isPdfFile()"
        [href]="previewSrc"
        target="_blank"
        class="pdf-preview"
        >View PDF</a
      >
    </div>
  `,
  styles: [
    `
      .custom-file-upload {
        display: flex;
        align-items: center;
      }
      .custom-file-upload-label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        max-width: 300px;
        border: 1px solid #d0d5dd;
        border-radius: 0.375rem;
        padding: 10px 16px;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .custom-file-upload-label:hover {
        border-color: #9ca3af;
      }
      .custom-file-input {
        display: none;
      }
      .file-status {
        color: #d47500;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 80%;
    
      }
      .preview-container {
        margin-top: 10px;
      }
      .img-preview {
        max-width: 100%;
        max-height: 200px;
        border: 1px solid #d0d5dd;
        border-radius: 0.375rem;
      }
      .pdf-preview {
        display: block;
        color: #d47500;
        text-decoration: none;
        margin-top: 5px;
        font-weight: 600;
      }
    `,
  ],
})
export class FormlyFieldFile extends FieldType<FieldTypeConfig> implements OnInit {
  imgPath = 'assets/images/upload-svg.svg';
  fileStatus: string = 'No file chosen';
  fileSelected = false;
  selectedFile: File | null = null;
  previewSrc: string | null = null;

  constructor(
    private api: ServiceProviderService,
    private cdr: ChangeDetectorRef,
    private fileService: FormStateService
  ) {
    super();
  }

  ngOnInit() {
    // Initialize with the current value if available
    this.initializeFilePreview(this.formControl.value);

    // React to value changes
    this.formControl.valueChanges.subscribe((value) => {
      this.initializeFilePreview(value);
    });
  }

  private initializeFilePreview(value: any) {
    if (value && value.id && value.path) {
      // Ensure the value contains both id and path
      this.fileStatus = value.path.split('/').pop(); // Extract file name from path
      this.fileSelected = true;
      this.previewSrc = `${environment.baseURL}/${value.path}`; // Construct URL for preview
      this.cdr.detectChanges();
    } else {
      this.clearFileSelection(); // Reset if value is null or invalid
    }
  }

  onFileSelect(event: any) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const selectedFile = input.files[0];
      this.fileStatus = selectedFile.name;
      this.fileSelected = true;
      this.selectedFile = selectedFile;

      const formData = new FormData();
      formData.append('file', selectedFile);
      formData.append('description', 'File upload description');
      formData.append('fileSourceId', '1');

      this.api.createFiles(formData).subscribe((res: any) => {
        const fileId = res.fileId;
        const filePath = res.filePath;

        // Update the formControl with the new file data
        this.formControl.setValue({ id: fileId, path: filePath });
        console.log('File uploaded. Received:', { id: fileId, path: filePath });

        this.previewSrc = `${environment.baseURL}/${filePath}`;
        this.cdr.detectChanges();
      });
    } else {
      this.clearFileSelection();
    }
  }

  clearFileSelection() {
    this.fileStatus = 'No file chosen';
    this.fileSelected = false;
    this.selectedFile = null;
    this.previewSrc = null;

    const fileInput = document.getElementById(this.id) as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }

    this.formControl.setValue(null);
    this.cdr.detectChanges();
  }

  previewFile() {
    if (this.selectedFile) {
      if (this.isPdfFile()) {
        const reader = new FileReader();
        reader.onload = () => {
          const blob = new Blob([reader.result as ArrayBuffer], {
            type: 'application/pdf',
          });
          this.previewSrc = window.URL.createObjectURL(blob);
          this.cdr.detectChanges();
        };
        reader.readAsArrayBuffer(this.selectedFile);
      } else if (this.isImageFile()) {
        const reader = new FileReader();
        reader.onload = () => {
          this.previewSrc = reader.result as string;
          this.cdr.detectChanges();
        };
        reader.readAsDataURL(this.selectedFile);
      }
    }
  }

  isImageFile(): boolean {
    return this.selectedFile
      ? this.selectedFile.type.startsWith('image/')
      : false;
  }

  isPdfFile(): boolean {
    return this.selectedFile
      ? this.selectedFile.type === 'application/pdf'
      : false;
  }
}

