import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingPageComponent } from './serviseAdvisor_module/landing-page/landing-page.component';
import { GetStartedComponent } from './serviseAdvisor_module/get-started/get-started.component';
import { SignupComponent } from './serviseAdvisor_module/signup/signup.component';

import { AbstractControl, FormsModule, ReactiveFormsModule, ValidationErrors, ValidatorFn } from '@angular/forms';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormlyPrimeNGModule } from '@ngx-formly/primeng';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import { LoadingScreenComponent } from './serviseAdvisor_module/loading-screen/loading-screen.component';
import { WelcomeDashboardComponent } from './serviseAdvisor_module/welcome-dashboard/welcome-dashboard.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { LocationComponent } from './serviseAdvisor_module/location/location.component';
import { SidebarModule } from 'primeng/sidebar';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import { AddServiceAdvisorComponent } from './serviseAdvisor_module/add-service-advisor/add-service-advisor.component';
import { AddServiceAdvisorDetailsComponent } from './serviseAdvisor_module/add-service-advisor/add-service-advisor-details/add-service-advisor-details.component';
import { ServiceAdvisorCertificatesComponent } from './serviseAdvisor_module/add-service-advisor/service-advisor-certificates/service-advisor-certificates.component';
import { ServiceAdvisorResumeComponent } from './serviseAdvisor_module/add-service-advisor/service-advisor-resume/service-advisor-resume.component';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { FormlyFieldFileUploadComponent } from './serviseAdvisor_module/add-service-advisor/Types/FormlyFieldFileUpload.Component';
import { FieldFileUploadComponent } from './serviseAdvisor_module/add-service-advisor/Types/FieldFileUpload.Component';
import { CustomDateTypeComponent } from './serviseAdvisor_module/add-service-advisor/Types/CustomDateType.Component';
import { CalendarModule } from 'primeng/calendar';
import { MessageService } from 'primeng/api';
import { CaptureBusinessLocationComponent } from './serviseAdvisor_module/capture-business-location/capture-business-location.component';
import { LocationDetailsComponent } from './serviseAdvisor_module/location-details/location-details.component';
import { MapLocationComponent } from './serviseAdvisor_module/location-details/map-location/map-location.component';
import { AddressProofComponent } from './serviseAdvisor_module/location-details/address-proof/address-proof.component';
import { TableModule } from 'primeng/table';
import { AddSpComponent } from './serviseAdvisor_module/add-sp-new-component/add-sp/add-sp.component';
import { BusinessDetailsNewComponent } from './serviseAdvisor_module/add-sp-new-component/business-details-new/business-details-new.component';
import { InchargeOwnerDetailsComponent } from './serviseAdvisor_module/add-sp-new-component/incharge-owner-details/incharge-owner-details.component';
import { ServiceOfferedNewComponent } from './serviseAdvisor_module/add-sp-new-component/service-offered/service-offered.component';
import { TechnicianInfoComponent } from './serviseAdvisor_module/add-sp-new-component/technician-info/technician-info.component';
import { AddServiceModalComponent } from './serviseAdvisor_module/add-sp-new-component/service-offered/add-service-modal/add-service-modal.component';
import { DialogModule } from 'primeng/dialog';
import { CustomCheckboxComponent } from './serviseAdvisor_module/add-sp-new-component/custom-checkbox';
import { CustomInputNumberTypeComponent } from './serviseAdvisor_module/add-sp-new-component/custom-input-number';
import { InputNumberModule } from 'primeng/inputnumber';
import { MultiSelectTypeComponent } from './serviseAdvisor_module/add-sp-new-component/multi-select-type';
import { MultiSelectModule } from 'primeng/multiselect';
import { FormlyFieldFile } from './serviseAdvisor_module/add-sp-new-component/file-type';
import { FormlyFieldMultiFile } from './serviseAdvisor_module/add-sp-new-component/multifile-type';
import { FileValueAccessor } from './serviseAdvisor_module/custom Types/file-value-accesor';
import { domainValidatorMessage } from './serviseAdvisor_module/custom Types/validation-message/validate-domain-name-message';
import { maxLengthValidationMessage, minLengthValidationMessage, requireFields } from './serviseAdvisor_module/custom Types/validation-message/validation-messages';
import { fieldMatchValidator } from './serviseAdvisor_module/custom Types/validators/field-matching.validator';
import { noUppercase } from './serviseAdvisor_module/custom Types/validators/noUpperCase.validator';
import { CustomAddBoxComponent } from './serviseAdvisor_module/add-sp-new-component/custom-add-box';
import { OtpPageComponent } from './serviseAdvisor_module/otp-page/otp-page.component';
import { OtpTypeComponent } from './serviseAdvisor_module/otp-page/customType/otp-type.component';
import { InputOtpModule } from 'primeng/inputotp';
import { CustomToastComponent } from './toastr/custom-toast/custom-toast.component';
import { CustomDropdownComponent } from './serviseAdvisor_module/customdropdown.type';
import { ServiceProviderService } from './serviseAdvisor_module/services/serviceProvider/service-provider.service';
import { AuthGuard } from './auth/auth.guard';
import { AddPaymentComponent } from './serviseAdvisor_module/add-sp-new-component/add-payment/add-payment.component';
import { PricingComponent } from './serviseAdvisor_module/add-sp-new-component/add-payment/pricing/pricing.component';
import { PaymentComponent } from './serviseAdvisor_module/add-sp-new-component/add-payment/payment/payment.component';
import { PasswordModule } from 'primeng/password';

@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    GetStartedComponent,
    SignupComponent,
    LoadingScreenComponent,
    WelcomeDashboardComponent,
    LocationComponent,
    AddServiceAdvisorComponent,
    AddServiceAdvisorDetailsComponent,
    ServiceAdvisorCertificatesComponent,
    ServiceAdvisorResumeComponent,
    FormlyFieldFileUploadComponent,
    FieldFileUploadComponent,
    CustomDateTypeComponent,
    CaptureBusinessLocationComponent,
    LocationDetailsComponent,
    MapLocationComponent,
    AddressProofComponent,
    AddSpComponent,
    BusinessDetailsNewComponent,
    InchargeOwnerDetailsComponent,
    ServiceOfferedNewComponent,
    TechnicianInfoComponent,
    AddServiceModalComponent,
    CustomCheckboxComponent,
    CustomInputNumberTypeComponent,
    MultiSelectTypeComponent,
    FormlyFieldFile,
    FormlyFieldMultiFile,
    FileValueAccessor,
    CustomAddBoxComponent,
    OtpPageComponent,
    OtpTypeComponent,
    CustomToastComponent,
    CustomDropdownComponent,
    AddPaymentComponent,
    PricingComponent,
    PaymentComponent,
  ],
  bootstrap: [AppComponent],
  imports: [BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormlyModule.forRoot(),
    FormlyPrimeNGModule,
    DropdownModule,
    ButtonModule,
    ProgressSpinnerModule,
    SidebarModule,
    InputIconModule,
    IconFieldModule,
    InputTextModule,
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    CalendarModule,
    TableModule,
    DialogModule,
    InputNumberModule,
    MultiSelectModule,
    PasswordModule,
    FormsModule,
    ReactiveFormsModule,
    InputOtpModule,
    FormlyModule.forChild({
      types: [
        { name: 'file-upload', component: FormlyFieldFileUploadComponent },
        { name: 'f-file-upload', component: FieldFileUploadComponent },
        { name: 'custom-min-dt', component: CustomDateTypeComponent },
        { name: 'multiselect', component: MultiSelectTypeComponent },
        {
          name: 'multifile',
          component: FormlyFieldMultiFile,
        },
        { name: 'file', component: FormlyFieldFile, wrappers: ['form-field'] },
        { name: 'custom-checkbox', component: CustomCheckboxComponent },
        { name: 'input-number', component: CustomInputNumberTypeComponent },
        { name: 'custom-add-box', component: CustomAddBoxComponent },
        { name: 'otp', component: OtpTypeComponent },
        {
          name: 'custom-dropdown',
          component: CustomDropdownComponent,
        },
        
      ],
      validators: [
        { name: 'fieldMatch', validation: fieldMatchValidator },
        { name: 'noUpperCase', validation: noUppercase },
        { name: 'duplicateTechType', validation: uniqueTechTypeValidator },
        { name: 'nonNegative', validation: nonNegativeNumberValidator },
      ],
      validationMessages: [
        { name: 'required', message: requireFields },
        { name: 'domain', message: domainValidatorMessage },
        { name: 'minLength', message: minLengthValidationMessage },
        {
          name: 'duplicateTechType',
          message: Techmessage,
        },
        { name: 'nonNegative', message: 'Number must be a positive value.' },
        { name: 'maxLength', message: maxLengthValidationMessage },
        { name: 'max', message: maxValidationMessage },
      ],
    })],
  providers: [ServiceProviderService, MessageService, provideHttpClient(withInterceptorsFromDi()), AuthGuard]
})
export class AppModule { }
export function uniqueTechTypeValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.parent || !control.parent.parent) {
      return null;
    }

    const formArray = control.parent.parent;
    const techTypes = (formArray.controls as AbstractControl[]).map(
      (group) => group.get('technicianId')?.value
    );

    const hasDuplicates = techTypes.some(
      (type, index) =>
        techTypes.indexOf(type) !== techTypes.lastIndexOf(type) &&
        techTypes.indexOf(type) !== index
    );

    return hasDuplicates ? { duplicateTechType: true } : null;
  };
}
export function Techmessage(error: any, field: FormlyFieldConfig) {
  return `Duplicate technician types are not allowed.`;
}
export function maxValidationMessage(error: any, field: FormlyFieldConfig) {
  return `This value should be less than ${field.props?.max}`;
}

export function nonNegativeNumberValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (value !== null && value !== undefined && value <= 0) {
      return { nonNegative: true };
    }
    return null;
  };
}
