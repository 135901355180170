import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceProviderService } from '../services/serviceProvider/service-provider.service';
import { environment } from '../../../environments/environment.development';

@Component({
  selector: 'app-capture-business-location',
  templateUrl: './capture-business-location.component.html',
  styleUrls: ['./capture-business-location.component.scss'],
})
export class CaptureBusinessLocationComponent implements OnInit {
  locationAdded = false;
  businessName: string = '';
  location: string = '';
  coordinates: string = '';
  city: string = '';
  state: string = '';
  zip: string = '';
  country: string = '';
  // Modal state
  isModalOpen = false;
  modalView: 'location' | 'business' = 'location';
  isMapOpen = false;
  frontViewImagePath: string = '';
  isPaymentComponentOpen = false;
  showDialog = false


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private spService: ServiceProviderService
  ) { }

  ngOnInit(): void {
    this.fetchLocationData();
    this.fetchBusinessDetails();
  }
  // Method to refresh data
  refreshData(): void {
    this.fetchLocationData();
    this.fetchBusinessDetails();
  }
  fetchLocationData(): void {
    const tempRegId = localStorage.getItem('temporaryRegId') || '';
    const phoneNumber = localStorage.getItem('phoneNumber') || '';

    if (tempRegId && phoneNumber) {
      this.spService.getLocationInfo(tempRegId, phoneNumber).subscribe({
        next: (response) => {
          if (response.isSuccess && response.result) {
            const data = response.result;

            // Check if any of the key fields for location are provided
            this.locationAdded = !!(data.address || data.latitude || data.longitude || data.city || data.state || data.zip);

            // Populate location details if available
            if (this.locationAdded) {
              this.location = data.address || '';
              this.coordinates = `${data.latitude}, ${data.longitude}`;
              this.city = data.city || '';
              this.state = data.state || '';
              this.zip = data.zip || '';
              this.country = data.countryId ? 'India' : ''; // Adjust logic for country if necessary
            }
          }
        },
        error: (err) => {
          console.error('Failed to fetch location data:', err);
        },
      });
    } else {
      console.warn('TemporaryRegId or PhoneNumber is missing.');
    }
  }


  fetchBusinessDetails(): void {
    const tempRegId = localStorage.getItem('temporaryRegId') || '';
    const phoneNumber = localStorage.getItem('phoneNumber') || '';

    if (tempRegId && phoneNumber) {
      this.spService.getBusinessInfo(tempRegId, phoneNumber).subscribe({
        next: (response) => {
          if (response.isSuccess && response.result) {
            this.businessName = response.result.businessName || '';
            const frontView = response.result.frontView;
            if (frontView && frontView.length > 0) {
              this.frontViewImagePath = `${environment.baseURL}/${frontView[0].filePath}`
            }
          }
        },
        error: (err) => {
          console.error('Failed to fetch business details:', err);
        },
      });
    }
  }

  navigateToLocation(): void {
    this.router.navigate(['/map']);
  }

  navigateToOtherDetails(): void {
    this.router.navigate(['/addSPDetails']);
  }

  navigateToPayment(): void {
    this.router.navigate(['/payment']);
  }
  openModal(): void {
    this.isModalOpen = true;
  }

  closeModal(): void {
    this.isModalOpen = false;
  }
  openMapLocation(): void {
    this.isMapOpen = true; // Show the MapLocationComponent
  }

  closeMapLocation(): void {
    this.isMapOpen = false; // Hide the MapLocationComponent
  }
  openPaymentComponent(): void {
    this.isPaymentComponentOpen = true; // Show the app-add-payment component
  }

  closePaymentComponent(): void {
    this.isPaymentComponentOpen = false; // Hide the app-add-payment component
  }

  finalSubmission(): void {
    const temporaryRegId = localStorage.getItem('temporaryRegId') || '';
    const phoneNumber = localStorage.getItem('phoneNumber') || '';

    const providerRequestBody = {
      temporaryRegId,
      phoneNumber,
    };

    this.spService.postProviderInfo(providerRequestBody).subscribe((providerResponse) => {
      console.log('Provider Info submitted successfully:', providerResponse);
      this.showDialog = true

      // Navigate to /otppage after 3 seconds
      // setTimeout(() => {
      // this.showDialog = false; // Close the dialog
      // }, 3000);
    },
    );
  }
  navigateToOTP() {
    this.showDialog = false;
    this.router.navigate(['/landingpage']);

  }
}
