import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormStateService } from '../form-state.service';
import { BusinessDetailsNewComponent } from '../business-details-new/business-details-new.component';
import { InchargeOwnerDetailsComponent } from '../incharge-owner-details/incharge-owner-details.component';
import { ServiceOfferedNewComponent } from '../service-offered/service-offered.component';
import { TechnicianInfoComponent } from '../technician-info/technician-info.component';
import { ServiceProviderService } from '../../services/serviceProvider/service-provider.service';
import { CaptureBusinessLocationComponent } from '../../capture-business-location/capture-business-location.component';


@Component({
  selector: 'app-add-sp',
  templateUrl: './add-sp.component.html',
  styleUrls: ['./add-sp.component.scss'],
})
export class AddSpComponent implements OnInit {
  technicianInfo: any;
  editMode: any;
  @Input() id?: number;
  selectedBusinessType: any;
  selectedServices!: any[];
  isBusinessValid: boolean = false;
  isOwnerValid: boolean = false;
  isServiceValid: boolean = false;
  isTechnicianValid: boolean = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private formstate: FormStateService,
    private api: ServiceProviderService,
  ) { }

  ngOnInit(): void {
    this.editMode = !this.id;
    // if (!this.editMode) {
    //   this.serviceProviderById(<number>this.id);
    // }
    this.cdr.detectChanges();
  }
  // @ViewChild(ServiceProviderComponent)
  // serviceProvider!: ServiceProviderComponent;

  @ViewChild(BusinessDetailsNewComponent)
  businessDetailsFormComponent!: BusinessDetailsNewComponent;

  @ViewChild(InchargeOwnerDetailsComponent)
  ownerDetails!: InchargeOwnerDetailsComponent;

  @ViewChild(ServiceOfferedNewComponent)
  serviceOffered!: ServiceOfferedNewComponent;

  @ViewChild(TechnicianInfoComponent)
  technicianDetails!: TechnicianInfoComponent;

  @Output() updateServiceProviderList = new EventEmitter<void>();
  @ViewChild('captureBusinessLocation', { static: false })
  captureBusinessLocation!: CaptureBusinessLocationComponent;


  isModalOpen: boolean = true; // Controls visibility of AddSpComponent

  activeSection: string = 'business-details';
  selectedPlan: any = null;
  countryId!: any;

  setActiveSection(section: string): void {
    Promise.resolve().then(() => {
      this.activeSection = section;
    })
  }

  validateBusinessDetails(isValid: boolean) {
    this.isBusinessValid = isValid;
  }

  validateOwnerDetails(isValid: boolean) {
    this.isOwnerValid = isValid;
  }

  validateServiceOffered(isValid: boolean) {
    this.isServiceValid = isValid;
  }

  validateTechnicianDetails(isValid: boolean) {
    this.isTechnicianValid = isValid;
  }

  onUpdateServiceProviderList(): void {
    this.updateServiceProviderList.emit();
  }

  onPlanSelected(plan: any): void {
    this.selectedPlan = plan;
  }

  close() {
    if (this.ownerDetails) {
      this.ownerDetails.resetForm();
    }
    if (this.serviceOffered) {
      this.serviceOffered.resetForm();
    }
    if (this.technicianDetails) {
      this.technicianDetails.resetForm();
    }
    // if (this.businessDetailsFormComponent) {
    //   this.businessDetailsFormComponent.resetForm();
    // }
    this.formstate.clearFormData();
  }

  setCountryId(countryId: string) {
    this.countryId = countryId;
  }

  closeDialog(): void {
    this.isModalOpen = false; // Close AddSpComponent
  }
  handleFinish(): void {
    // Ensure CaptureBusinessLocationComponent is initialized before calling refreshData
    if (this.captureBusinessLocation) {
      this.captureBusinessLocation.refreshData();
    }
  }

}