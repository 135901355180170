import { FormlyFieldConfig } from '@ngx-formly/core';

// Require Field message
export function requireFields(error: any, field: FormlyFieldConfig) {
  return `${field.props?.label} is a required field`;
}

// Minimum length validation message
export function minLengthValidationMessage(
  error: any,
  field: FormlyFieldConfig
) {
  return `${field.props?.label} Should be at least of ${field.props?.minLength} characters`;
}

// Maximum length validation message
export function maxLengthValidationMessage(
  error: any,
  field: FormlyFieldConfig
) {
  return `${field.props?.label} Should be at least of ${field.props?.maxLength} characters`;
}
