import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ServiceAdvisorFormStateService } from '../../services/formstate/service-advisor-form-stae-service.service';
import { UserService } from '../../user-service.service';
import { environment } from '../../../../environments/environment.development';

@Component({
  selector: 'app-service-advisor-resume',
  templateUrl: './service-advisor-resume.component.html',
  styleUrls: ['./service-advisor-resume.component.scss'],
})
export class ServiceAdvisorResumeComponent implements OnInit {
  countryId: any;
  form = new FormGroup({});
  model: any = {};
  isNewFileUploaded = false;

  fields: FormlyFieldConfig[] = [];
  @Output() nextSection = new EventEmitter<void>();
  @Output() formValidity = new EventEmitter<boolean>();
  @Input() receivedObject: any;
  @Input() editMode!: boolean;

  constructor(
    private api: UserService,
    private formStateService: ServiceAdvisorFormStateService,
    private fileService: ServiceAdvisorFormStateService,
    private cdr: ChangeDetectorRef  // Inject ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    console.log('Initializing ServiceAdvisorResumeComponent');
    // Fetch and populate form data from backend
    this.loadFormData()
    this.initializeFields();
  }

  ngOnDestroy() {
    this.saveFormData();
  }

  resetForm() {
    this.form.reset();
    this.model = {};
  }

  initializeFields() {
    const fileSourceId = 3;
    this.fields = [
      {
        key: 'file',
        type: 'file-upload',
        templateOptions: {
          label: 'File Upload',
          placeholder: 'Upload a file',
          required: true,
          accept: '.pdf,.jpg,.png',
          fileSourceId,
        },
      },
    ];
  }



  patchFormWithFile(fileName: string, fileUrl: string, fileId: number) {
    if (!fileUrl || !fileId) {
      console.error('Invalid file data provided for patching.');
      return;
    }

    // Patch form with file data
    this.form.patchValue({ file: fileUrl });

    // Update formStateService with file information
    this.fileService.setFormData('resumeFile', {
      fileName: fileName,
      fileUrl: fileUrl,
      fileId: fileId,
    });

    // Store uploaded file ID in fileService
    this.fileService.setUploadedFileId('resumeFile', fileId);

    // Trigger change detection
    this.cdr.detectChanges();
  }

  populateFormWithServiceProviderDetails(res: any) {
    const resumeDocument = res.serviceAdvisorDocuments?.find(
      (doc: any) => doc.documentTypeId === 12
    );

    const uploadedFileId = this.fileService.getUploadedFileId('resumeFile');

    if (!uploadedFileId && resumeDocument) {
      console.log('Patching backend resume data.');

      const { fileName, filePath: fileUrl, fileId } = resumeDocument;

      // Patch the form with file from backend using the new method
      this.patchFormWithFile(fileName, fileUrl, fileId);
    } else {
      console.log('Skipping backend patch, uploaded file ID exists:', uploadedFileId);
    }
  }

  saveFormData() {
    const formData: any = { ...this.form.value };

    const uploadedFileId = this.fileService.getUploadedFileId('resumeFile');
    console.log('Uploaded file id ::', uploadedFileId);

    if (uploadedFileId) {
      const documentTypeId = 12;
      const uploadDocs = {
        documents: [
          {
            documentTypeId,
            fileId: uploadedFileId,
          },
        ],
      };

      this.formStateService.setFormData('upload-documents', formData);
      this.formStateService.setServiceAdvisorResume(uploadDocs);
      console.log('Form data and uploaded document saved successfully');
    } else {
      console.error('No uploaded file ID found. Please ensure the file is uploaded.');
    }
  }

  async loadFormData() {
    const temporaryRegId = localStorage.getItem('temporaryRegId');
    const phoneNumber = localStorage.getItem('phoneNumber');

    if (temporaryRegId && phoneNumber) {
      const response = await this.api.fetchResumeDetails(temporaryRegId, phoneNumber).toPromise();
      if (response.isSuccess && response.result) {
        const { resumeFileId, resumeFilePath } = response.result;

        if (resumeFileId && resumeFilePath) {
          const baseUrl = `${environment.baseURL}/`;
          const { resumeFilePath } = response.result;
          const fullPath = resumeFilePath.startsWith('http') ? resumeFilePath : baseUrl + resumeFilePath;
          console.log(fullPath);
          this.fileService.setFormData('resumeFile', {
            fileName: 'Resume',
            fileUrl: fullPath, // This is used as a relative path
            fileId: resumeFileId,
          });
          this.form.patchValue({ file: resumeFilePath });
        }
      }
    }
  }



  onSubmit() {
    this.saveFormData();

    // Fetch values from local storage
    const temporaryRegId = localStorage.getItem('temporaryRegId');
    const phoneNumber = localStorage.getItem('phoneNumber');
    const resumeFileId = this.fileService.getUploadedFileId('resumeFile'); // Fetch from file service

    // Validation
    if (!resumeFileId || !temporaryRegId || !phoneNumber) {
      console.error('Missing required fields for API call.');
      return;
    }

    // Prepare payload
    const payload = {
      temporaryRegId,
      phoneNumber,
      resumeFileId: Number(resumeFileId), // Ensure it's a number
    };

    // Make API call
    this.api.postResumeDetails(payload).subscribe(
      (response) => {
        console.log('Resume details submitted successfully:', response);
        this.nextSection.emit(); // Emit the event to proceed
      },
      (error) => {
        console.error('Failed to submit resume details:', error);
      }
    );
  }

}
