import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, tap } from 'rxjs';
import { environment } from '../../../../environments/environment.development';

@Injectable({
  providedIn: 'root',
})
export class ServiceProviderService {
  // api: string = 'http://admin.autocare.com:5107';
  api: string = environment.baseURL;

  constructor(private http: HttpClient) { }

  storeToken(tokenValue: string) {
    localStorage.setItem('token', tokenValue);
  }

  storePersonalDetails(tokenDetails: any) {
    localStorage.setItem('tokenDetails', JSON.stringify(tokenDetails));
  }
  newToken(token: string) {
    const data = { token };
    const headers = { 'Content-Type': 'application/json' };
    const options = { headers: new HttpHeaders(headers) };

    return this.http.post(this.api + '/api/token/refresh-token', data, options);
  }
  updateProfile(data: any) {
    return this.http.post<any>(this.api + 'api/Users/profile', data);
  }

  getPersionalDetails() {
    return localStorage.getItem('personalDetails');
  }
  getLoggedInUser() {
    return this.http.get<any>(`${this.api}/api/users/profile`);
  }

  getToken() {
    return localStorage.getItem('token');
  }

  login(data: any) {
    return this.http.post<any>(`${this.api}/api/Token`, data);
  }

  logout() {
    return localStorage.removeItem('token');
  }

  isLoggedIn(): boolean {
    return !!localStorage.getItem('token');
  }

  getCountry(searchQuery: any) {
    return this.http.get<any>(`${this.api}/api/Lookup/countries?searchQuery=${searchQuery}`);
  }

  getStates(id: any) {
    return this.http.get<any>(`${this.api}/api/Lookup/states`);
  }

  getBusinessTypeList(searchQuery: any) {
    return this.http.get<any>(`${this.api}/api/Lookup/businessTypes?searchQuery=${searchQuery}`);
  }

  getBusinessTypeLists() {
    return this.http.get<any>(`${this.api}/api/Lookup/businessTypes`);
  }

  getTechnicianList(searchQuery: any) {
    return this.http.get<any>(`${this.api}/api/Lookup/technicians?searchQuery=${searchQuery}`);
  }

  createFiles(data: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', '*/*');
    return this.http.post<any>(`${this.api}/api/File`, data, { headers });
  }

  getFileById(id: number): Observable<any[]> {
    return this.http.get<any[]>(`${this.api}/${id}`);
  }

  createMultiFiles(data: FormData) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', '*/*');
    return this.http.post<any>(`${this.api}/api/File/multiple`, data, { headers });
  }

  getAllDocumentType() {
    return this.http.get<any>(`${this.api}/api/Lookup/documentTypes`);
  }

  createServiceProvider(data: any) {
    return this.http.post<any>(`${this.api}/api/ServiceProviders`, data);
  }

  getServiceProviderlist(params: any): Observable<any> {
    return this.http.get<any>(`${this.api}/api/ServiceProviders`, { params });
  }

  getServiceproviderById(id: any): Observable<any> {
    return this.http.get<any>(`${this.api}/api/ServiceProviders/${id}`);
  }

  updateServiceProviderInfo(id: any, data: any) {
    return this.http.post<any>(`${this.api}/api/ServiceProviders/${id}`, data);
  }

  createApproval(serviceProviderId: any, data: any): Observable<any> {
    return this.http.post(`${this.api}/api/ServiceProviders/${serviceProviderId}/change-status`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      responseType: 'text',
    });
  }

  getServices(searchQuery: '') {
    return this.http.get<any>(`${this.api}/api/Lookup/services?searchQuery=${searchQuery}`);
  }

  postNewService(data: any) {
    return this.http.post<any>(`${this.api}/api/Services`, data);
  }

  approveServiceProvider(id: any, data: any) {
    return this.http.post<any>(`${this.api}/api/ServiceProviders/change-status/${id}`, data);
  }

  getHistory(id: any) {
    return this.http.get<any>(`${this.api}/api/ServiceProviders/status-histories/${id}`);
  }

  // dialog
  private showDialogSubject = new Subject<boolean>();
  showDialog$ = this.showDialogSubject.asObservable();

  openDialog() {
    this.showDialogSubject.next(true);
  }

  closeDialog() {
    this.showDialogSubject.next(false);
  }

  getServiceAdvisors(id: any) {
    return this.http.get<any>(`${this.api}/api/ServiceProviders/ServiceAdvisor/${id}`);
  }

  assignServiceAdvisor(id: any, data: any) {
    console.log('Assign Service Advisor ID:', id); // Log the id to ensure it's correct
    return this.http.post<any>(`${this.api}/api/ServiceProviders/ServiceAdvisor/${id}`, data);
  }
  verifyPayment(paymentId: string) {
    // Construct the query string URL
    return this.http.post<any>(`${this.api}/api/Payment/verify-payment?paymentId=${paymentId}`, paymentId)
  }


  // signup sp
  signUp(data: any): Observable<any> {

    return this.http.post<any>(`${this.api}/api/SignUp`, data);
  }
  getBasicInfo(temporaryRegId: string, phoneNumber: string) {
    const url = `${this.api}/api/SignUp/basicInfo`;
    const params = { TemporaryRegId: temporaryRegId, PhoneNumber: phoneNumber };

    return this.http.get<any>(url, { params });
  }


  //Location
  postLocation(data: any): Observable<any> {
    return this.http.post<any>(`${this.api}/api/SignUp/location`, data);
  }
  getLocationInfo(temporaryRegId: string, phoneNumber: string) {
    const url = `${this.api}/api/SignUp/location`;
    const params = {
      TemporaryRegId: temporaryRegId,
      PhoneNumber: phoneNumber,
    };

    return this.http.get<any>(url, { params });
  }

  // service provider types
  getServiceProviderTypes(): Observable<any[]> {
    const url = `${this.api}/api/Lookup/serviceproviderTypes`;
    return this.http.get<any[]>(url).pipe(
    );
  }

  // businessInfo
  getBusinessInfo(temporaryRegId: string, phoneNumber: string) {
    const url = `${this.api}/api/SignUp/serviceProvider/businessInfo`;
    const params = { TemporaryRegId: temporaryRegId, PhoneNumber: phoneNumber };
    return this.http.get<any>(url, { params });
  }

  postBusinessInfo(data: any) {
    const url = `${this.api}/api/SignUp/serviceProvider/businessInfo`;
    return this.http.post<any>(url, data);
  }

  //service info
  getServiceInfo(temporaryRegId: string, phoneNumber: string) {
    const url = `${this.api}/api/SignUp/serviceProvider/serviceInfo`;
    const params = { TemporaryRegId: temporaryRegId, PhoneNumber: phoneNumber };
    return this.http.get<any>(url, { params });
  }

  postServiceInfo(data: any) {
    const url = `${this.api}/api/SignUp/serviceProvider/serviceInfo`;
    return this.http.post<any>(url, data);
  }

  // 
  getTechnicianInfo(temporaryRegId: string, phoneNumber: string) {
    const url = `${this.api}/api/SignUp/serviceProvider/technicianInfo`;
    const params = { TemporaryRegId: temporaryRegId, PhoneNumber: phoneNumber };
    return this.http.get<any>(url, { params });
  }

  postTechnicianInfo(data: any) {
    const url = `${this.api}/api/SignUp/serviceProvider/technicianInfo`;
    return this.http.post<any>(url, data);
  }

  // owner/incharge
  getOwnerInfo(temporaryRegId: string, phoneNumber: string) {
    const params = { temporaryRegId, phoneNumber };
    return this.http.get<any>(`${this.api}/api/SignUp/serviceProvider/ownerInfo`, { params });
  }

  postOwnerInfo(data: any) {
    return this.http.post<any>(`${this.api}/api/SignUp/serviceProvider/ownerInfo`, data);
  }

  postProviderInfo(body: any): Observable<any> {
    return this.http.post(this.api + '/api/SignUp/serviceProvider', body); // Endpoint: /api/SignUp/serviceProvider
  }
  postAdvisorInfo(body: any): Observable<any> {
    return this.http.post(this.api + '/api/SignUp/serviceAdvisor', body); // Endpoint: /api/SignUp/serviceProvider
  }

  // sa details
  postServiceAdvisorDetails(payload: any): Observable<any> {
    return this.http.post<any>(`${this.api}/api/SignUp/serviceAdvisor/personalDetails`, payload);
  }

  // FETCH Service Advisor Details
  fetchServiceAdvisorDetails(temporaryRegId: string, phoneNumber: string): Observable<any> {
    const params = { temporaryRegId, phoneNumber };
    return this.http.get<any>(`${this.api}/api/SignUp/serviceAdvisor/personalDetails`, { params });
  }

  postResumeDetails(payload: any): Observable<any> {
    return this.http.post<any>(`${this.api}/api/SignUp/serviceAdvisor/resume`, payload);
  }

  // FETCH Resume Details
  fetchResumeDetails(temporaryRegId: string, phoneNumber: string): Observable<any> {
    const params = { temporaryRegId, phoneNumber };
    return this.http.get<any>(`${this.api}/api/SignUp/serviceAdvisor/resume`, { params });
  }

}


