import { Directive, HostListener, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Directive({
  selector: 'input[type=file]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileValueAccessor),
      multi: true,
    },
  ],
})
export class FileValueAccessor implements ControlValueAccessor {
  private onChange = (value: any) => {};
  private onTouched = () => {};

  @HostListener('onSelect', ['$event'])
  onSelect(event: any) {
    this.onChange(event.files);
  }

  writeValue(value: any): void {
    // handle writing value to the view, if necessary
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // handle setting the disabled state, if necessary
  }
}
