import { Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-custom-dropdown',
  template: `
    <label>{{ to.label }}</label>
    <p-dropdown
      [options]="groupedOptions"
      [placeholder]="to.placeholder || 'Select an option'"
      [group]="true"
      [formControl]="formControl"
      [formlyAttributes]="field"
      (onChange)="onDropdownChange($event)"
    >
      <ng-template let-group pTemplate="group">
        <div class="flex align-items-center">
          <span>{{ group.label }}</span>
        </div>
      </ng-template>
    </p-dropdown>
  `,
  styles: [
    `
      .p-dropdown {
        width: 100%;
      }
    `,
  ],
})
export class CustomDropdownComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit {
  groupedOptions: any[] = [];

  ngOnInit(): void {
    if (this.to['options$']) {
      this.to['options$'].subscribe({
        next: (data: any[]) => {
          if (data && Array.isArray(data)) {
            this.groupedOptions = data;
            console.log('Dropdown Options Loaded:', this.groupedOptions);

            // Patch initial value to dropdown
            const initialValue = this.formControl.value;
            console.log('Initial Value:', initialValue);

            if (initialValue) {
              this.patchInitialValue(initialValue);
            }
          } else {
            console.warn('Invalid dropdown data:', data);
            this.groupedOptions = [];
          }
        },
        error: (err: any) => {
          console.error('Dropdown options error:', err);
        },
      });
    }
  }

  onDropdownChange(event: any): void {
    const selectedValue = event.value;
    this.formControl.setValue(selectedValue);
    if (this.to['onChange']) {
      this.to['onChange'](selectedValue);
    }
  }

  private patchInitialValue(initialValue: any): void {
    const selectedGroup = this.groupedOptions.find((group) =>
      group.items.some(
        (item:any) =>
          item.value.serviceProviderTypeId ===
            initialValue.serviceProviderTypeId &&
          item.value.serviceProviderSubTypeId ===
            initialValue.serviceProviderSubTypeId
      )
    );

    if (selectedGroup) {
      const selectedItem = selectedGroup.items.find(
        (item:any) =>
          item.value.serviceProviderTypeId ===
            initialValue.serviceProviderTypeId &&
          item.value.serviceProviderSubTypeId ===
            initialValue.serviceProviderSubTypeId
      );

      if (selectedItem) {
        console.log('Patching Initial Dropdown Value:', selectedItem.value);
        this.formControl.setValue(selectedItem.value); // Set initial value
      } else {
        console.warn('No matching subtype found for:', initialValue);
      }
    } else {
      console.warn('No matching type found for:', initialValue);
    }
  }
}
