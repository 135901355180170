import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment.development';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  // private location: string = '';
  constructor(private http: HttpClient) { }

  postLocation(data: any) {
    const apiUrl = `${environment.baseURL}/api/SignUp/location`;
    return this.http.post<any>(apiUrl, data);
  }
  private locationData: any = null;

  setLocationData(data: any): void {
    this.locationData = data;
  }

  getLocationData(): any {
    return this.locationData;
  }

  clearLocationData(): void {
    this.locationData = null;
  }
}
// private locationService: LocationService