import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FormStateService } from '../../../services/formstate/form-stae-service.service';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrl: './pricing.component.scss'
})
export class PricingComponent {
  @Output() planSelected = new EventEmitter<any>();
  @Output() nextSection = new EventEmitter<void>();

  selectedPlan: string | null = null;
  subscriptionForm: FormGroup;
  receivedObject: any;

  plans = [
    {
      name: 'Basic Plan',
      price: 2000.0,
      description: 'One time fee, No recurring payments',
      features: ['Access to all basic features'],
      unavailableFeatures: ['Diagnostic tool'],
    },
  ];

  constructor(
    private fb: FormBuilder,
    private formStateService: FormStateService
  ) {
    this.subscriptionForm = this.fb.group({
      selectedPlan: [''],
    });
  }

  ngOnInit(): void {
    const savedPlan = this.formStateService.getFormData('selectedPlan');
    if (savedPlan) {
      this.selectedPlan = savedPlan;
      this.subscriptionForm.get('selectedPlan')?.setValue(savedPlan);
    }
    this.formStateService.spDetails.subscribe((object) => {
      this.receivedObject = object;
      this.populateFormWithServiceProviderDetails(this.receivedObject);
      console.log(this.receivedObject);
    });
  }
  populateFormWithServiceProviderDetails(res: any) { }
  selectPlan(plan: string): void {
    console.log('Selecting Plan:', plan);
    this.selectedPlan = plan;
    this.subscriptionForm.get('selectedPlan')?.setValue(plan);

    const selectedPlanDetails = this.plans.find((p) => p.name === plan);
    this.planSelected.emit(selectedPlanDetails);

    // Save the selected plan to FormStateService
    this.formStateService.setFormData('selectedPlan', plan);
    this.formStateService.setSelectedPlan(selectedPlanDetails);
  }

  resetForm() {
    console.log('Resetting Subscription Plan Form');
    this.selectedPlan = null;
    this.formStateService.setFormData('selectedPlan', '');
    console.log('Form after reset:', this.selectedPlan);
    this.subscriptionForm.reset();
  }

  onSubmit(): void {
    if (this.selectedPlan) {
      console.log('Selected Plan:', this.selectedPlan);
      this.planSelected.emit(
        this.plans.find((p) => p.name === this.selectedPlan)
      );

      // Save plan to FormStateService
      const selectedPlanDetails = this.plans.find((p) => p.name === this.selectedPlan);
      this.formStateService.setSelectedPlan(selectedPlanDetails);

      // Navigate to Payment Section
      this.nextSection.emit();
    } else {
      console.log('No plan selected');
    }
  }

}
