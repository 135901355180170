
<div *ngIf="!isMapOpen && !isPaymentComponentOpen" class="dashboard">
  <h2>Welcome to Auto E Care</h2>
  <h1>Hello {{businessName}}, Let’s get moving!</h1>
  <div class="cards flex flex-column align-items-center">

    <!-- Capture Business Location Section -->
    <div class="card" [ngClass]="{ active: locationAdded }">
      <div>
        <div class="flex justify-content-start flex-wrap">
          <div class="flex">
            <div class="iconn">
              <i class="pi pi-file" style="font-size: 16px; text-align: center;"></i>
            </div>
            <div style="margin-left: 12px;">
              <h3 class="main-text">Business</h3>
              <p class="sub-text">Enter your business details</p>
            </div>
             
          </div>
          
          <div class="ml-2">
            <!-- <h3 class="main-text">Business</h3>
            <p class="sub-text">Enter your business details</p> -->
            <div class="location-container">
              <div class="details-container">
                <div *ngIf="!locationAdded" class="number-circle">
                  <p>1</p>
                </div>
                <div *ngIf="locationAdded" class="inner-bg">
                  <i class="pi pi-check" style="font-size: 14px; color: green; width: 40px; text-align: center;"></i> 
                </div>
  
                <div class="details-text mt-1">
                  <span style="font-size: 18px; ">Capture Business Location</span>
                  <button *ngIf="!locationAdded" pButton label="Proceed" class="btn-proceed mt-4"
                    (click)="openMapLocation()"></button>
                  <!-- <div *ngIf="locationAdded" class="location-display-row full-width">
                    
                    <div class="location-map-container">
                      <img class="location-map-preview"
                        src="https://maps.googleapis.com/maps/api/staticmap?center={{ coordinates }}&zoom=15&size=300x150&markers=color:red|{{ coordinates }}&key=AIzaSyD3XLFD-Pi85sV0s2EkDtBPQcOr1U6PMso"
                        alt="Location Preview" (click)="openMapLocation()" />
                    </div> 
                    <div class="location-text-container">
                      <p class="location-title"><strong>Location</strong></p>
                      <p class="location-text">{{ location }}</p>
                    </div>
                  </div> -->
  
                </div>
              </div>
              <hr *ngIf="locationAdded" class="hr">
              <div *ngIf="locationAdded" class="location-display-row full-width">
                    
                <div class="location-map-container">
                  <img class="location-map-preview"
                    src="https://maps.googleapis.com/maps/api/staticmap?center={{ coordinates }}&zoom=15&size=300x150&markers=color:red|{{ coordinates }}&key=AIzaSyD3XLFD-Pi85sV0s2EkDtBPQcOr1U6PMso"
                    alt="Location Preview" (click)="openMapLocation()" />
                </div> 
                <div class="location-text-container">
                  <p class="location-title"><strong>Location</strong></p>
                  <p class="location-text">{{ location }}</p>
                </div>
              </div>
            </div>
            
            <div class="cardd" [ngClass]="{ active: businessName, disabled: !locationAdded}">
              <div class="flex-initial flex">
                <div class="px-0 py-2"></div>
                <!-- <i class="pi pi-briefcase icon px-2 py-2"
                  style="font-size: 1rem; color: #777675; background-color: #ebe8e8; border-radius: 50%; height: 32px;">
                </i> -->
                <div>
                  <div class="details-container" *ngIf="!businessName">
                    <div *ngIf="!locationAdded" class="iconn">
                      <p>2</p>
                    </div>
                    <div *ngIf="locationAdded" class="icon-circle">
                      <span>2</span>
                    </div>
                    
                    <div>
                      <span style="font-size: 18px; margin-left: 12px;" class="details-text mt-1">Other Business Details</span>
                      <button *ngIf="locationAdded" pButton label="Proceed" class="btn-proceed "
                        (click)="openModal()"></button>
                    </div>
                  </div>
                </div>
              </div>
        
              <div class="flex">
                <div class="px-0 py-0"></div>
                <div *ngIf="businessName" class="details-container-new">
                  <!-- Icon and Title -->
                  <div class="icon-title-container">
                    <div class="icon-circle" style="margin-left: 12px;">
                      <i class="pi pi-info" ></i>
                    </div>
                    <h3 class="details-title">Other business details</h3>
                  </div>
        
                  <!-- Divider -->
                  <hr class="horizontal-border" />
        
                  <!-- Business Information -->
                  <div class="business-info-container">
                    <!-- Business Front View Image -->
                    <div *ngIf="frontViewImagePath" class="front-view-tile-new">
                      <img [src]="frontViewImagePath" alt="Business Front View" class="front-view-image-new" />
                    </div>
        
                    <!-- Business Name and Address -->
                    <div class="business-text ml-2 mt-3">
                      <h3 class="business-name-new">{{ businessName }}</h3>
        
                      <button *ngIf="businessName" pButton label="Edit" class="btn-proceed mt-2 mb-2"
                        (click)="openModal()"></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    
    <button label="Register" pButton class="btn-proceed" (click)="finalSubmission()"></button>
    <!-- Payment Section -->
    <!-- <div class="card" [ngClass]="{ active: locationAdded && businessName }">
      <div class="flex-initial flex">
        <i class="pi pi-credit-card px-2 py-2"
          style="font-size: 1rem; color: #777675; background-color: #ebe8e8; border-radius: 50%; height: 32px;"></i>
        <div class="card-text px-3 py-2">
          <h3>Payment</h3>
          <p>Subscribe to Auto E Care to communicate with customers at ease</p>
          <button *ngIf="locationAdded && businessName" pButton label="Proceed" class="btn-proceed mt-2"
            (click)="openPaymentComponent()"></button>
        </div>
      </div>
    </div> -->
  </div>
</div>

<!-- Confirmation Dialog -->
<p-dialog [(visible)]="showDialog" header="Application Submitted" [modal]="true" [closable]="false"
  [style]="{ width: '30rem' }" [draggable]="false">
  <p class="dialog-message">
    Thank you for signing up with AutoEcare <br>
    Your application for Service-Advisor has been submitted.
    <br>
    You will be provided the login credentials in your
    <br>
    registered email once the application is approved by the Admin.
  </p>
  <div class="dialog-actions">
    <button pButton label="OK" class="btn-primary" (click)="showDialog = false" (click)="navigateToOTP()"></button>
  </div>
</p-dialog>


<div *ngIf="isModalOpen">
  <app-add-sp></app-add-sp>
</div>

<!-- Display MapLocationComponent -->
<div *ngIf="isMapOpen" class="map-location-container">
  <app-location-details></app-location-details>
</div>

<!-- Conditionally Render app-add-payment -->
<div *ngIf="isPaymentComponentOpen">
  <app-add-payment></app-add-payment>
</div>