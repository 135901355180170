// 1st child component
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Subscription } from 'rxjs';
import { UserService } from '../../user-service.service';
import { ServiceProviderService } from '../../services/serviceProvider/service-provider.service';
import { ServiceAdvisorFormStateService } from '../../services/formstate/service-advisor-form-stae-service.service';

@Component({
  selector: 'app-add-service-advisor-details',
  templateUrl: './add-service-advisor-details.component.html',
  styleUrl: './add-service-advisor-details.component.scss',
})
export class AddServiceAdvisorDetailsComponent implements OnInit {
  serviceAdvisorId: any;
  form = new FormGroup({});
  // model: any = {};
  model: any = {
    serviceAdvisorId: '',
    name: '',
    phone: '',
    bio: '',
  };
  fields: FormlyFieldConfig[] = [];

  @Output() nextSection = new EventEmitter<void>();
  @Output() formValidity = new EventEmitter<boolean>();
  private subscription: Subscription | undefined;
  @Input() receivedObject: any;
  @Input() editMode!: boolean;
  constructor(
    private api: ServiceProviderService,
    private userService: UserService,
    private formStateService: ServiceAdvisorFormStateService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.loadFormData();
    const phoneNumber = localStorage.getItem('phoneNumber');
    if (phoneNumber) {
      this.model.phone = phoneNumber;
      this.form.patchValue({ phone: phoneNumber });
      this.disablePhoneField();
    }
    this.form.valueChanges.subscribe((formData) => {
      this.saveFormData();
    });

    // Initialize form fields
    this.initializeField();
  }
  disablePhoneField(): void {
    const phoneField = this.form.get('phone');
    if (phoneField) {
      phoneField.disable(); 
    }
  }


  resetForm() {
    this.form.reset();
    this.model = {};
  }

  initializeField() {
    this.fields = [
      {
        template: '<div class="text-base font-semibold mb-4">Basics</div>',
      },
      {
        fieldGroupClassName: 'field grid p-fluid',
        fieldGroup: [
          {
            className: 'field col-5',
            key: 'name',
            type: 'input',
            templateOptions: {
              label: 'Name of the Service advisor',
              placeholder: 'Enter name',
              required: true,
              hideRequiredMarker: true,
            },
            validators: {
              validation: [Validators.required, Validators.minLength(3)],
            },
            validation: {
              messages: {
                required: 'Name is required',
                minlength: 'Name should be at least 3 characters long',
              },
            },
          },
          {
            className: 'field col-5',
            key: 'phone',
            type: 'input',
            templateOptions: {
              label: 'Phone number',
              placeholder: '+91 1234567890',
              required: true,
              hideRequiredMarker: true,
            },
            validators: {
              validation: [Validators.required, Validators.pattern(/^\d{10}$/)],
            },
            validation: {
              messages: {
                required: 'Phone number is required',
                pattern: 'Phone number must be exactly 10 digits',
              },
            },
            expressionProperties: {
              'templateOptions.disabled': '!!model.phone', // Disable if phone exists
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'field grid p-fluid',
        fieldGroup: [
          {
            className: 'field col-10',
            key: 'bio',
            type: 'textarea',
            templateOptions: {
              label: 'Bio',
              placeholder: 'Enter a description',
              required: true,
              hideRequiredMarker: true,
            },
            validators: {
              validation: [Validators.required, Validators.minLength(10)],
            },
            validation: {
              messages: {
                required: 'Please mention something in your bio',
                minlength: 'Name should be at least 10 characters long',
              },
            },
          },
        ],
      },
    ];
  }

  saveFormData() {
    const formData = { ...this.form.value };
    this.formStateService.setFormData('service-advisor-details', formData);
    console.log(formData);
  }

  loadFormData() {
    const temporaryRegId = localStorage.getItem('temporaryRegId');
    const phoneNumber = localStorage.getItem('phoneNumber');
  
    if (temporaryRegId && phoneNumber) {
      this.api.fetchServiceAdvisorDetails(temporaryRegId, phoneNumber).subscribe({
        next: (response) => {
          if (response.isSuccess && response.result) {
            const advisorData = response.result;
            console.log('Fetched Data (Backend):', advisorData);
  
            // Safeguard the phone field
            this.model = {
              name: advisorData.serviceAdvisorName || '',
              phone: phoneNumber, // Always use localStorage phoneNumber
              bio: advisorData.bio || '',
            };
  
            // Patch the form with the fetched data
            this.form.patchValue(this.model);
          } else {
            console.error('Error fetching Service Advisor details:', response.errors);
          }
        },
        error: (err) => {
          console.error('Failed to fetch Service Advisor details:', err);
        },
      });
    } else {
      console.warn('TemporaryRegId or PhoneNumber is missing in local storage.');
    }
  }
  

  onSubmit() {
    if (this.form.valid) {
      const transformedData = this.transformFormData(this.form.value); // Transform form data
      console.log('Payload:', transformedData); // Debug log for payload

      this.api.postServiceAdvisorDetails(transformedData).subscribe({
        next: (response) => {
          if (response.isSuccess) {
            console.log('Service Advisor details saved successfully:', response);
            this.nextSection.emit(); // Proceed to the next section
          } else {
            console.error('Error saving details:', response.errors);
          }
        },
        error: (err) => {
          console.error('Failed to save Service Advisor details:', err);
        },
      });
    } else {
      console.warn('Form is invalid');
    }
  }


  transformFormData(formData: any): any {
    const phoneNumber = localStorage.getItem('phoneNumber');
    const temporaryRegId = localStorage.getItem('temporaryRegId');
    const transformedData: any = {
      serviceAdvisorName: formData.name,
      phoneNumber: phoneNumber,
      serviceAdvisorPhoneNumber: phoneNumber,
      bio: formData.bio,
      temporaryRegId: temporaryRegId
    };

    // will be included if the serviceAdvisorId exists
    if (this.serviceAdvisorId) {
      transformedData.serviceAdvisorId = this.serviceAdvisorId;
    }

    return transformedData;
  }
  populateFormWithServiceProviderDetails(res: any) {
    const phoneNumber = localStorage.getItem('phoneNumber'); // Get phone from localStorage
  
    this.serviceAdvisorId = res.serviceAdvisorId;
    console.log('Advisor id', this.serviceAdvisorId);
  
    // Safeguard phone field
    this.model = {
      name: res.name || '',
      phone: phoneNumber, // Always use localStorage phoneNumber
      bio: res.bio || '',
    };
  
    this.form.patchValue(this.model);
    this.cdr.detectChanges();
  }
  
}
