import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loading-screen',
  templateUrl: './loading-screen.component.html',
  styleUrl: './loading-screen.component.scss'
})
export class LoadingScreenComponent {

  @Input() isLoading: boolean = false;
  @Input() loadingMessage: string = 'Logging you in';
}
