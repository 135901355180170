import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  FormlyFieldConfig,
  FieldType,
  FieldTypeConfig,
} from '@ngx-formly/core';
import { ServiceProviderService } from '../services/serviceProvider/service-provider.service';

@Component({
  selector: 'formly-field-multiselect',
  template: `
    <label [for]="id">{{ to.label }}*</label>

    <p-multiSelect
      [options]="$any(to.options)"
      [formControl]="formControl"
      [placeholder]="to.placeholder"
      [optionLabel]="to['optionLabel']"
      [display]="to['display']"
      [showHeader]="to['showHeader']"
      [formlyAttributes]="field"
      display="chip"
      [filter]="true"
      [showClear]="true"
    ></p-multiSelect>
    <div *ngIf="showError" class="p-error">
      {{ errorMessage }}
    </div>
  `,
})
export class MultiSelectTypeComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  serviceList: any[] = [];

  constructor(private api: ServiceProviderService) {
    super();
  }
  ngOnInit() {
    // this.api.getServiceList('').subscribe((data) => {
    //   this.serviceList = data.map((item: any) => ({
    //     value: item.serviceId,
    //     label: item.name,
    //   }));
    // });
  }
  override get showError(): boolean {
    return (
      this.formControl.invalid &&
      (this.formControl.dirty || this.formControl.touched)
    );
  }

  get errorMessage(): string {
    const { errors } = this.formControl;
    if (errors) {
      const errorKey = Object.keys(errors)[0];
      return (
        this.to['validation']?.messages?.[errorKey] ||
        'Service Offered field is required'
      );
    }
    return '';
  }
}
