import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ServiceAdvisorFormStateService {
  private serviceAdvisorDetails = new BehaviorSubject<any>({});
  private serviceAdvisorResume = new BehaviorSubject<any>({});
  private serviceAdvisorCertificates = new BehaviorSubject<any>({});
  private formData = new Map<string, any>();
  private uploadedFileIds: { [key: string]: number } = {}; // Map file IDs to keys for each document type
  private uploadedFilesMap: { [key: string]: boolean } = {};
  private stateUpdatedSubject = new BehaviorSubject<void>(undefined); // Notify whenever state changes
  stateUpdated = this.stateUpdatedSubject.asObservable(); // Expose as observable


  constructor() { }

  setFormData(key: string, value: any): void {
    this.formData.set(key, value);
    this.stateUpdatedSubject.next(); // Notify all subscribers of the change
  }

  getFormData(key: string): any {
    return this.formData.get(key);
  }

  setUploadedFileId(key: string, fileId: number): void {
    const data = this.formData.get(key) || {};
    this.formData.set(key, { ...data, fileId });
    this.stateUpdatedSubject.next(); // Notify all subscribers of the change
  }

  getUploadedFileId(key: string): number | undefined {
    const data = this.formData.get(key);
    return data?.fileId;
  }
  setProfileData(key: string, data: any): void {
    this.formData.set(key, data);
    console.log('profilePicture', data);
  }

  clearFormData(): void {
    this.serviceAdvisorDetails.next({});
    this.serviceAdvisorResume.next({});
    this.serviceAdvisorCertificates.next({});
    this.serviceAdvisorDetailsSubject.next({});
    this.uploadedFileIds = {};
    this.formData.clear();
    this.setFormData('sa-details', null);
    this.setFormData('resumeFile', {});
    this.setFormData('otherCertificates', {});
    this.setFormData('experienceCertificates', {});
    this.setFormData('qualificationCertificates', {});
    this.setFormData('identityCard', {});
  }

  setServiceAdvisorDetails(details: any): void {
    console.log('Setting service advisor details:', details);
    this.serviceAdvisorDetails.next(details)
  }
  getServiceAdvisorDetails(): any {
    return this.serviceAdvisorDetailsSubject.value;
  }
  setServiceAdvisorResume(details: any): void {
    this.serviceAdvisorResume.next(details);
  }

  setServiceAdvisorCertificates(details: any): void {
    this.serviceAdvisorCertificates.next(details);
  }

  getServiceAdvisorCombinedData() {
    return {
      ...this.serviceAdvisorDetails.value,
      serviceAdvisorDocuments: [
        ...(this.serviceAdvisorResume.value.documents || []),
        ...(this.serviceAdvisorCertificates.value.documents || []),
      ],
    };
  }

  //for SA Details
  private serviceAdvisorDetailsSubject = new BehaviorSubject<any>({});
  saDetails = this.serviceAdvisorDetailsSubject.asObservable();

  updateObject(newObject: any) {
    this.serviceAdvisorDetailsSubject.next(newObject);
  }

  // methods for profile picture handling
  setProfilePicture(data: any): void {
    this.setFormData('profilePicture', data);
    console.log('Set Profile Picture:', data); // Log to confirm data
  }

  getProfilePicture(): any {
    return this.getFormData('profilePicture');
  }

  setProfilePictureFileId(fileId: number): void {
    this.setUploadedFileId('profilePicture', fileId);
  }

  getProfilePictureFileId(): number | undefined {
    return this.getUploadedFileId('profilePicture');
  }

  // marking that a file has been uploaded
  markFileAsUploaded(key: string): void {
    this.uploadedFilesMap[key] = true;
  }

  //checking if a new file has been uploaded
  isFileUploaded(key: string): boolean {
    return this.uploadedFilesMap[key] === true;
  }

  //reseting the uploaded flag
  resetUploadedFlag(key: string): void {
    this.uploadedFilesMap[key] = false;
  }
  public stateUpdate: EventEmitter<void> = new EventEmitter();
  notifyStateUpdate(): void {
    this.stateUpdate.emit();
  }
}
