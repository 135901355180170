<div *ngIf="activeSection === 'dashboard'" class="dashboard">
  <h2>Welcome to Auto E Care</h2>
  <h1>Hello {{ advisorName || 'User' }}, Let's get moving!</h1>

  <div class="cards flex flex-column align-items-center">
    <!-- Capture Location Card -->
    <div class="card" [ngClass]="{ active: locationAdded }">
      <div class="flex-initial flex align-items-start px-4 py-3">
        <i *ngIf="!locationAdded" class="pi pi-map-marker icon-circle"></i>
        <div *ngIf="locationAdded" class="outer-bg">
          <div class="inner-bg">
            <i class="pi pi-check icon" style="font-size: 12px;font-weight: 500;"></i>
          </div>
        </div>

        <div class="card-text">
          <h3>Capture location</h3>
          <p>Add your location</p>

          <!-- Button to add location -->
          <button *ngIf="!locationAdded" pButton label="Proceed" class="btn-proceed mt-4"
            (click)="openLocationDetails()"></button>
        </div>
      </div>

      <!-- Display location details when added -->
      <hr class="horizontal-border" *ngIf="locationAdded" />
      <div *ngIf="locationAdded" class="location-display flex-initial flex px-4 py-2">
        <img class="location-map-preview"
          src="https://maps.googleapis.com/maps/api/staticmap?center={{ coordinates }}&zoom=15&size=300x150&markers=color:red|{{ coordinates }}&key=AIzaSyD3XLFD-Pi85sV0s2EkDtBPQcOr1U6PMso"
          alt="Location Preview" (click)="openLocationDetails()" style="cursor: pointer;" />
        <p class="location-text">
          <strong>Location</strong> <br />
          {{ location }}
        </p>
      </div>
    </div>

    <!-- Expert Details Card -->
    <div class="card" [ngClass]="{ disabled: !locationAdded }">
      <div class="flex-initial flex p-4" *ngIf="!advisorName">
        <i class="pi pi-file icon-cir" *ngIf="!locationAdded" style="background-color: #d8d7d7; border-radius: 50%; width: 34px; height: 32px; text-align: center; margin-top: 6px;"></i>
        <i class="pi pi-file icon-circle" *ngIf="locationAdded"></i>
        <div class="card-text">
          <h3>Expert details</h3>
          <p *ngIf="!locationAdded"> Submit your all the information for proceed further</p>
          <!-- Buttons -->
          <button *ngIf="locationAdded" pButton label="Proceed" class="btn-proceed" [disabled]="!locationAdded"
            (click)="openServiceAdvisorComponent()"></button>
        </div>
      </div>

      <div class="flex-initial flex mt-2 ml-4 mb-3" *ngIf="advisorName">
        <!-- Icon and Text -->
        <div class="outer-bg">
          <div class="inner-bg">
            <i class="pi pi-check icon" style="font-size: 12px; font-weight: 500;"></i>
          </div>
        </div>
        <div class="card-text mt-1">
          <h3>Expert details</h3>
        </div>
      </div>

      <!-- Divider -->
      <hr class="horizontal-divider" *ngIf="advisorName" />

      <!-- Advisor Details -->
      <div class="advisor-details ml-4" *ngIf="advisorName">
        <div>
          <span class="font-bold">Name:</span> <span class="ml-1">{{ advisorName }}</span>
        </div>
        <div class="mt-2">
          <span class="font-bold">Phone:</span> <span class="ml-1">{{ advisorPhoneNumber }}</span>
        </div>

        <!-- Edit Button -->
        <button pButton label="Edit" class="btn-proceed mt-3 mb-4" [disabled]="!locationAdded" *ngIf="advisorName"
          (click)="openServiceAdvisorComponent()"></button>
      </div>


    </div>
    <button class="btn-proceed" label="Register" pButton (click)="finalSubmission()"></button>
  </div>
</div>

<!-- Confirmation Dialog -->
<p-dialog [(visible)]="showDialog" header="Application Submitted" [modal]="true" [closable]="false"
  [style]="{ width: '30rem' }" [draggable]="false">
  <p class="dialog-message">
    Thank you for signing up with AutoEcare <br>
    Your application for Service-Advisor has been submitted.
    <br>
    You will be provided the login credentials in your
    <br>
    registered email once the application is approved by the Admin.
  </p>
  <div class="dialog-actions">
    <button pButton label="OK" class="btn-primary" (click)="showDialog = false" (click)="navigateToOTP()"></button>
  </div>
</p-dialog>



<!-- Conditionally Render LocationDetailsComponent -->
<div *ngIf="activeSection === 'location-details'" class="location-details-container">
  <app-location-details></app-location-details>
</div>
<!-- Conditionally Render AddServiceAdvisorComponent -->
<div *ngIf="activeSection === 'add-service-advisor'" class="add-service-advisor-container">
  <app-add-service-advisor></app-add-service-advisor>
</div>