import { Component, OnInit, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { LocationService } from '../../service/location.service';
import { ServiceProviderService } from '../../services/serviceProvider/service-provider.service';
import { ToastService } from '../../../toastr/toastr_service/service.service';

@Component({
  selector: 'app-map-location',
  templateUrl: './map-location.component.html',
  styleUrls: ['./map-location.component.scss'],
})
export class MapLocationComponent implements OnInit {
  map!: google.maps.Map;
  searchBox!: google.maps.places.SearchBox;
  marker!: google.maps.Marker;
  address: string = '';
  coordinates: string = '';
  lat!: number;
  lng!: number;
  city: string = '';
  state: string = '';
  zip: string = '';
  country: string = '';
  @Output() changeSection = new EventEmitter<string>();

  constructor(
    private router: Router,
    private locationDataService: LocationService,
    private spService: ServiceProviderService,
    private cdr: ChangeDetectorRef,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
    this.initMap();
    this.fetchLocation();
  }

  initMap(): void {
    const mapOptions: google.maps.MapOptions = {
      center: { lat: 20.76696, lng: 86.152857 },
      zoom: 15,
    };

    this.map = new google.maps.Map(
      document.getElementById('map') as HTMLElement,
      mapOptions
    );

    const input = document.getElementById('search-box') as HTMLInputElement;
    this.searchBox = new google.maps.places.SearchBox(input);

    this.map.addListener('bounds_changed', () => {
      this.searchBox.setBounds(this.map.getBounds() as google.maps.LatLngBounds);
    });

    this.searchBox.addListener('places_changed', () => {
      const places = this.searchBox.getPlaces();
      if (!places || places.length === 0) {
        alert('No places found');
        return;
      }

      const place = places[0];
      if (place.geometry && place.geometry.location) {
        this.addMarker(place.geometry.location);
        this.fetchAddress(place.geometry.location);
      }
    });

    this.map.addListener('click', (event: google.maps.MapMouseEvent) => {
      if (event.latLng) {
        this.addMarker(event.latLng);
        this.fetchAddress(event.latLng);
      }
    });
  }

  fetchLocation(): void {
    const temporaryRegId = localStorage.getItem('temporaryRegId') || '';
    const phoneNumber = localStorage.getItem('phoneNumber') || '';

    if (!temporaryRegId || !phoneNumber) {
      console.error('TemporaryRegId or PhoneNumber missing in local storage.');
      return;
    }

    this.spService.getLocationInfo(temporaryRegId, phoneNumber).subscribe({
      next: (response) => {
        if (response.isSuccess && response.result) {
          const { latitude, longitude, address, city, state, zip, countryId } = response.result;
          if (latitude && longitude) {
            const position = new google.maps.LatLng(parseFloat(latitude), parseFloat(longitude));
            this.addMarker(position);
            this.map.setCenter(position);
          }
          this.address = address || '';
          this.city = city || '';
          this.state = state || '';
          this.zip = zip || '';
          this.country = countryId ? 'India' : '';

          this.cdr.detectChanges();
        } else {
          console.warn('No location data found:', response.errors);
        }
      },
      error: (err) => {
        console.error('Error fetching location info:', err);
      },
    });
  }

  addMarker(position: google.maps.LatLng): void {
    if (this.marker) {
      this.marker.setPosition(position);
    } else {
      this.marker = new google.maps.Marker({
        position,
        map: this.map,
        title: 'Selected Location',
      });
    }
    this.lat = position.lat();
    this.lng = position.lng();
    this.coordinates = `${this.lat}, ${this.lng}`;
    this.map.setCenter(position);
  }

  fetchAddress(latLng: google.maps.LatLng): void {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === 'OK' && results && results[0]) {
        this.address = results[0].formatted_address || 'Address not found';
        const addressComponents = results[0].address_components;

        this.city = addressComponents.find((c) =>
          c.types.includes('locality')
        )?.long_name || '';
        this.state = addressComponents.find((c) =>
          c.types.includes('administrative_area_level_1')
        )?.long_name || '';
        this.zip = addressComponents.find((c) =>
          c.types.includes('postal_code')
        )?.long_name || '';
        this.country = addressComponents.find((c) =>
          c.types.includes('country')
        )?.long_name || '';

        this.cdr.detectChanges();
      } else {
        console.error('Failed to fetch address:', status);
      }
    });
  }

  saveLocation(): void {
    if (!this.lat || !this.lng || !this.address) {
      alert('Please select a valid location.');
      return;
    }

    const locationData = {
      latitude: this.lat.toString(),
      longitude: this.lng.toString(),
      address: this.address,
      city: this.city,
      state: this.state,
      zip: this.zip,
      temporaryRegId: localStorage.getItem('temporaryRegId') || '',
      phoneNumber: localStorage.getItem('phoneNumber') || '',
    };

    this.locationDataService.setLocationData(locationData);

    console.log('Location Data Stored:', locationData);
    // Show success toast
    this.toastService.showToast('Location Details saved Successfully', 'success');

    // Inform parent to switch to address-proof section
    this.changeSection.emit('address-proof');
  }
  getCurrentLocation(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          this.addMarker(new google.maps.LatLng(pos.lat, pos.lng)); // Add marker at the user's current location
          this.fetchAddress(new google.maps.LatLng(pos.lat, pos.lng)); // Fetch and update the address for the location
        },
        (error) => {
          console.error('Error fetching current location:', error);
        }
      );
    } else {
      alert('Geolocation is not supported by this browser.');
    }
  }
  close(){}
}
