import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormStateService } from '../form-state.service';
import { ChangeDetectorRef } from '@angular/core';
import { ServiceProviderService } from '../../services/serviceProvider/service-provider.service';
import { ToastService } from '../../../toastr/toastr_service/service.service';

@Component({
  selector: 'app-incharge-owner-details',
  templateUrl: './incharge-owner-details.component.html',
  styleUrls: ['./incharge-owner-details.component.scss'],
})
export class InchargeOwnerDetailsComponent implements OnInit {
  form = new FormGroup({});
  // model: any = {};
  model: any = {
    ownerName: '',
    ownerPhone: '',
    ownerEmail: '',
    ownerAdhaar: '',
    inchargeName: '',
    inchargePhone: '',
    inchargeEmail: '',
    inchargeAdhaar: '',
    sponsorName: '',
    sponsorPhone: '',
    sponsorEmail: '',
    sponsorId: '',
    inchargeIdProof: '',
  };
  fields: FormlyFieldConfig[] = [];
  @Output() nextSection = new EventEmitter<void>();
  @Output() isValid = new EventEmitter<boolean>();
  receivedObject: any;
  isFormPopulated = false;

  constructor(
    private formStateService: FormStateService,
    private api: ServiceProviderService,
    private cdr: ChangeDetectorRef,
    private formStateService1: FormStateService,
    private toast: ToastService
  ) { }

  @Input() countryId!: string;

  ngOnInit() {
    // Subscribe to the form's validity changes
    this.form.statusChanges.subscribe(() => {
      this.isValid.emit(this.form.valid);
    });


    this.loadFormData();
    const tokenDetailsString = localStorage.getItem('tokenDetails');
    if (tokenDetailsString) {
      const tokenDetails = JSON.parse(tokenDetailsString);
      this.countryId = tokenDetails.country?.countryId;
      this.model.countryId = this.countryId;
    }
    this.initializeFields();
  }

  ngOnDestroy() {
    this.saveFormData();
  }
  resetForm() {
    this.form.reset();
    this.model = {};
  }

  initializeFields() {
    this.fields = [
      {
        className: 'field col-12',
        template: '<h3>Business owner</h3>',
      },
      {
        fieldGroupClassName: 'p-fluid p-formgrid p-grid',
        fieldGroup: [
          {
            fieldGroupClassName: 'field grid p-fluid',
            fieldGroup: [
              {
                className: 'field col-6',
                key: 'ownerName',
                type: 'input',
                templateOptions: {
                  label: 'Name of the Owner',
                  placeholder: 'Enter name',
                  required: true,
                },
              },
              {
                className: 'field col-6 ',
                key: 'ownerPhone',
                type: 'input',
                templateOptions: {
                  label: 'Phone number',
                  placeholder: '+91 1234567890',
                  required: true,
                  hideRequiredMarker: true,
                },
                validators: {
                  validation: [
                    Validators.required,
                    Validators.pattern(/^\d{10}$/),
                  ],
                },
                validation: {
                  messages: {
                    required: 'Phone number is required',
                    pattern: 'Phone number must be exactly 10 digits',
                  },
                },
              },
            ],
          },
          {
            fieldGroupClassName: 'field grid p-fluid',
            fieldGroup: [
              {
                className: 'field col-6',
                key: 'ownerEmail',
                type: 'input',
                templateOptions: {
                  label: 'Email',
                  placeholder: 'Enter email id',
                  required: true,
                  type: 'email',
                },
                validators: {
                  email: {
                    expression: (c: AbstractControl) =>
                      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
                        c.value
                      ),
                    message: (error: any, field: FormlyFieldConfig) =>
                      `${field.formControl?.value} is not a valid email address`,
                  },
                },
              },
            ],
          },
          {
            className: 'field col-12',
            key: 'ownerAdhaar',
            type: 'file',
            templateOptions: {
              placeholder: 'Upload Adhaar',
              required: true,
              accept: '.pdf,.jpg,.png',
              description: 'Supported file formats: pdf, jpg, png',
            },
          },
          {
            className: 'field col-12',
            template: '<h3>Garage Incharge</h3>',
          },
          {
            fieldGroupClassName: 'field grid p-fluid',
            fieldGroup: [
              {
                className: 'field col-6',
                key: 'inchargeName',
                type: 'input',
                templateOptions: {
                  label: 'Name of the Garage incharge',
                  placeholder: 'Enter name',
                  required: true,
                },
              },
              {
                className: 'field col-6',
                key: 'inchargePhone',
                type: 'input',
                templateOptions: {
                  label: 'Phone number',
                  placeholder: 'Enter phone number',
                  required: true,
                  type: 'tel',
                },
                validators: {
                  validation: [
                    Validators.required,
                    Validators.pattern(/^\d{10}$/),
                  ],
                },
                validation: {
                  messages: {
                    required: 'Phone number is required',
                    pattern: 'Phone number must be exactly 10 digits',
                  },
                },
              },
            ],
          },
          {
            fieldGroupClassName: 'field grid p-fluid',
            fieldGroup: [
              {
                className: 'field col-6',
                key: 'inchargeEmail',
                type: 'input',
                templateOptions: {
                  label: 'Email',
                  placeholder: 'Enter email id',
                  required: true,
                  type: 'email',
                },
                validators: {
                  email: {
                    expression: (c: AbstractControl) =>
                      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
                        c.value
                      ),
                    message: (error: any, field: FormlyFieldConfig) =>
                      `${field.formControl?.value} is not a valid email address`,
                  },
                },
              },
            ],
          },
          {
            className: 'field col-12',
            key: 'inchargeAdhaar',
            type: 'file',
            templateOptions: {
              placeholder: 'Upload Adhaar',
              required: true,
              accept: '.pdf,.jpg,.png',
              description: 'Supported file formats: pdf, jpg, png',
            },
          },
        ],
      },
    ];
  }

  populateFormWithServiceProviderDetails(res: any) {
    if (!this.isFormPopulated) {
      this.model.ownerName = res.ownerName;
      this.model.ownerPhone = res.ownerPhone;
      this.model.ownerEmail = res.ownerEmail;
      this.model.ownerAdhaar = res.ownerAdhaar || '';

      this.model.inchargeName = res.inchargeName;
      this.model.inchargePhone = res.inchargePhone;
      this.model.inchargeEmail = res.inchargeEmail;
      this.model.inchargeAdhaar = res.inchargeAdhaar || '';

      this.model.sponsorName = res.sponsorName || '';
      this.model.sponsorPhone = res.sponsorPhone || '';
      this.model.sponsorEmail = res.sponsorEmail || '';

      this.form.patchValue(this.model);
      this.isFormPopulated = true;
      this.formStateService1.setFormData('owner-details', this.model);
    }
  }



  loadFormData() {
    const temporaryRegId = localStorage.getItem('temporaryRegId') || '';
    const phoneNumber = localStorage.getItem('phoneNumber') || '';

    if (!temporaryRegId || !phoneNumber) {
      console.error('TemporaryRegId or PhoneNumber missing in local storage.');
      return;
    }

    // Fetch data from the API
    this.api.getOwnerInfo(temporaryRegId, phoneNumber).subscribe({
      next: (response) => {
        if (response.isSuccess && response.result) {
          console.log('Fetched Owner Info:', response.result);

          // Map the response to the model
          this.model = {
            ownerName: response.result.ownerName || '',
            ownerPhone: response.result.ownerPhoneNumber || '',
            ownerEmail: response.result.ownerEmailAddress || '',
            ownerAdhaar: {
              id: response.result.ownerIdProofFileId,
              path: response.result.ownerIdProofFilePath,
            },
            inchargeName: response.result.garageInChargeName || '',
            inchargePhone: response.result.garageInChargePhoneNumber || '',
            inchargeEmail: response.result.garageInChargeEmailAddress || '',
            inchargeAdhaar: {
              id: response.result.garageIdProofFileId,
              path: response.result.garageIdProofFileIdFilePath,
            },
            sponsorName: response.result.sponsorName || '',
            sponsorPhone: response.result.sponsorPhoneNumber || '',
            sponsorEmail: response.result.sponsorEmailAddress || '',
            sponsorId: {
              id: response.result.sponsorIdProofFileId,
              path: response.result.sponsorIdProofFilePath,
            },
          };

          // Patch the data to the form
          this.form.patchValue(this.model);
          this.isFormPopulated = true;
          console.log('Patched Form Model:', this.model);
          this.cdr.detectChanges();
        } else {
          console.warn('Failed to fetch owner info or invalid response:', response.errors);
        }
      },
      error: (err) => {
        console.error('Error fetching owner info:', err);
      },
    });
  }


  saveFormData() {
    const formData = { ...this.model };
    this.formStateService.setFormData('owner-details', formData);
  }

  submit() {
    if (this.form.valid) {
      const temporaryRegId = localStorage.getItem('temporaryRegId') || '';
      const phoneNumber = localStorage.getItem('phoneNumber') || '';

      if (!temporaryRegId || !phoneNumber) {
        console.error('TemporaryRegId or PhoneNumber missing in local storage.');
        return;
      }

      const transformedData = this.createFinalObject(this.form.value);
      const requestData = {
        ...transformedData,
        temporaryRegId,
        phoneNumber,
      };

      // Make the POST API call
      this.api.postOwnerInfo(requestData).subscribe({
        next: (response) => {
          if (response.isSuccess) {
            console.log('Owner Info successfully saved:', response);
            this.toast.showToast('Owner Info saved successfully!', 'success')
            this.nextSection.emit();
          } else {
            this.toast.showToast('Failed to save owner info!', 'error');
            console.error('Failed to save owner info:', response.errors);
          }
        },
        error: (err) => {
          console.error('Error saving owner info:', err);
        },
      });
    } else {
      console.warn('Form is invalid. Please check all required fields.');
    }
  }

  createFinalObject(ownerInchargeData: any): any {
    return {
      temporaryRegId: localStorage.getItem('temporaryRegId') || '',
      phoneNumber: localStorage.getItem('phoneNumber') || '',
      sponsorName: ownerInchargeData.sponsorName || null,
      ownerName: ownerInchargeData.ownerName || null,
      ownerPhoneNumber: ownerInchargeData.ownerPhone || null,
      sponsorPhoneNumber: ownerInchargeData.sponsorPhone || null,
      sponsorEmailAddress: ownerInchargeData.sponsorEmail || null,
      ownerEmailAddress: ownerInchargeData.ownerEmail || null,
      garageInChargeName: ownerInchargeData.inchargeName,
      garageInChargePhoneNumber: ownerInchargeData.inchargePhone,
      garageInChargeEmailAddress: ownerInchargeData.inchargeEmail,
      ownerIdProofFileId: ownerInchargeData.ownerAdhaar?.id || null,
      sponsorIdProofFileId: ownerInchargeData.sponsorId?.id || null,
      garageIdProofFileId: ownerInchargeData.inchargeAdhaar?.id || null,
    };
  }


}
