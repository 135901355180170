<!-- <app-location-details></app-location-details> -->
<div class="address-container">
  <h2>Address</h2>

  <div class="upload-section">
    <!-- <label for="fileInput" class="upload-btn">
      <i class="pi pi-cloud-upload"></i>
      Upload address proof
    </label>
    <input
      id="fileInput"
      type="file"
      style="display: none"
      (change)="onFileSelected($event)"
    /> -->
    <form [formGroup]="form" >
      <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
    </form>
  </div>
  <button pButton type="button" class="finish-btn" (click)="onFinish()">Finish</button>
  <!-- <button pButton type="button" class="finish-btn" (click)="navigateTo()">Finish</button> -->
</div>

  