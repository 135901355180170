// another child component
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ServiceAdvisorFormStateService } from '../../services/formstate/service-advisor-form-stae-service.service';
import { UserService } from '../../user-service.service';
import { FormStateService } from '../../services/formstate/form-stae-service.service';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { environment } from '../../../../environments/environment.development';


@Component({
  selector: 'app-service-advisor-certificates',
  templateUrl: './service-advisor-certificates.component.html',
  styleUrls: ['./service-advisor-certificates.component.scss'],
})
export class ServiceAdvisorCertificatesComponent implements OnInit {
  form = new FormGroup({});
  model: any = {
    identityCard: '',
    qualificationCertificates: '',
    experienceCertificates: '',
    otherCertificates: '',
  };
  fields: FormlyFieldConfig[] = [];
  isEditMode: boolean = false;
  fileId: any;
  @Output() nextSection = new EventEmitter<void>();
  @Output() formValidity = new EventEmitter<boolean>();
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() updateList: EventEmitter<void> = new EventEmitter<void>();
  countryId!: any;
  isLoading: boolean = false;
  receivedObject: any;
  checkServiceAdvisorId: any

  constructor(
    private formStateService: ServiceAdvisorFormStateService,
    private api: UserService,
    private cdr: ChangeDetectorRef,
    private getId: FormStateService,
    private router: Router
  ) { }

  async ngOnInit(): Promise<void> {
    this.isLoading = true;

    // Wait for the data to load
    await this.loadFormData();

    // Initialize Formly fields only after data is loaded
    this.initializeFields();

    this.isLoading = false;
    this.cdr.detectChanges();
  }
  ngOnDestroy() {
    this.saveFormData();
  }
  resetForm() {
    this.form.reset();
    this.model = {};
  }

  initializeFields() {
    this.fields = [
      {
        key: 'identityCard',
        type: 'f-file-upload',
        templateOptions: {
          label: 'Upload Identity card',
          placeholder: 'NID/Labour card/ Aadhar',
          required: true,
          accept: '.pdf,.jpg,.png',
        },
      },
      {
        key: 'qualificationCertificates',
        type: 'f-file-upload',
        templateOptions: {
          label: 'Upload qualification certificates',
          placeholder: 'Share your qualification certificates',
          required: true,
        },
      },
      {
        key: 'experienceCertificates',
        type: 'f-file-upload',
        templateOptions: {
          label: 'Upload experience certificates',
          placeholder: 'Share your experience certificates',
          required: true,
        },
      },
      {
        key: 'otherCertificates',
        type: 'f-file-upload',
        templateOptions: {
          label: 'Any other',
          placeholder: 'Share your experience certificates',
          required: false,
        },
      },
    ];
  }

  saveFormData() {
    const formData = { ...this.form.value };
    const documentMappings: { [key: string]: number } = {
      identityCard: 7,
      qualificationCertificates: 13,
      experienceCertificates: 14,
      otherCertificates: 15,
    };

    const uploadDocs = Object.keys(documentMappings).map((key) => {
      const documentTypeId =
        documentMappings[key as keyof typeof documentMappings];
      const fileId = this.formStateService.getUploadedFileId(key);
      return {
        documentTypeId,
        fileId,
      };
    });

    this.formStateService.setFormData('upload-certificates', formData);
    this.formStateService.setServiceAdvisorCertificates({
      documents: uploadDocs,
    });
  }

  async loadFormData(): Promise<void> {
    const temporaryRegId = localStorage.getItem('temporaryRegId');
    const phoneNumber = localStorage.getItem('phoneNumber');
    const baseUrl = `${environment.baseURL}/`;

    if (!temporaryRegId || !phoneNumber) {
      console.error('Missing required details from local storage.');
      return;
    }

    try {
      const response = await this.api.fetchCertificates(temporaryRegId, phoneNumber).toPromise();
      if (response.isSuccess && response.result) {
        const result = response.result;

        // Set data for each certificate
        this.formStateService.setFormData('identityCard', {
          fileName: 'Identity Card',
          fileUrl: baseUrl + result.serviceAdvisorIdCardFilePath,
          fileId: result.serviceAdvisorIdCardFileId,
        });
        this.formStateService.setUploadedFileId('identityCard', result.serviceAdvisorIdCardFileId);

        this.formStateService.setFormData('qualificationCertificates', {
          fileName: 'Qualification Certificate',
          fileUrl: baseUrl + result.qualificationCertificatFilePath,
          fileId: result.qualificationCertificatFileId,
        });
        this.formStateService.setUploadedFileId('qualificationCertificates', result.qualificationCertificatFileId);

        this.formStateService.setFormData('experienceCertificates', {
          fileName: 'Experience Certificate',
          fileUrl: baseUrl + result.experienceCertificatFilePath,
          fileId: result.experienceCertificatFileId,
        });
        this.formStateService.setUploadedFileId('experienceCertificates', result.experienceCertificatFileId);

        this.formStateService.setFormData('otherCertificates', {
          fileName: 'Other Certificates',
          fileUrl: baseUrl + result.anyOtherCertificatFilePath,
          fileId: result.anyOtherCertificatFileId,
        });
        this.formStateService.setUploadedFileId('otherCertificates', result.anyOtherCertificatFileId);

        // Notify child components that data has been updated
        this.formStateService.notifyStateUpdate();

        // Patch model for the form
        this.model = {
          identityCard: result.serviceAdvisorIdCardFileId || '',
          qualificationCertificates: result.qualificationCertificatFileId || '',
          experienceCertificates: result.experienceCertificatFileId || '',
          otherCertificates: result.anyOtherCertificatFileId || '',
        };
        this.form.patchValue(this.model);

        this.cdr.detectChanges(); // Ensure UI reflects updated data
      } else {
        console.error('Failed to fetch certificates:', response.errors);
      }
    } catch (error) {
      console.error('Error during fetchCertificates API call:', error);
    }
  }


  clearForm(): void {
    this.formStateService.clearFormData();
  }

  submit() {
    if (this.isLoading) return; // Prevent multiple submissions
    this.isLoading = true;

    const temporaryRegId = localStorage.getItem('temporaryRegId');
    const phoneNumber = localStorage.getItem('phoneNumber');

    if (!temporaryRegId || !phoneNumber) {
      this.handleError('Missing required details from local storage.');
      this.isLoading = false;
      return;
    }

    const payload = {
      temporaryRegId,
      phoneNumber,
      serviceAdvisorIdCardFileId: this.formStateService.getUploadedFileId('identityCard') || 0,
      qualificationCertificatFileId: this.formStateService.getUploadedFileId('qualificationCertificates') || 0,
      experienceCertificatFileId: this.formStateService.getUploadedFileId('experienceCertificates') || 0,
      anyOtherCertificatFileId: this.formStateService.getUploadedFileId('otherCertificates') || 0,
    };

    console.log('Payload for uploadCertificates API:', payload);

    this.api.uploadCertificates(payload).subscribe(
      (response) => {
        if (response.isSuccess) {
          this.handleSuccess('Certificates uploaded successfully');
          this.nextSection.emit();

        } else {
          this.handleError('Failed to upload certificates. Please try again.');
        }
      },
      (error) => {
        console.error('Error during uploadCertificates API call:', error);
        this.handleError('An error occurred during the upload.');
      }
    );
  }

  handleSuccess(message: string): void {
    this.isLoading = false;
    // this.toastService.showToast(message, 'success');
    setTimeout(() => {
      this.updateList.emit();
      this.close.emit();
    }, 500);
  }

  handleError(message: string): void {
    this.isLoading = false;
    // this.toastService.showToast(message, 'error');
  }
  add() {
    this.saveFormData();
    const data = this.formStateService.getServiceAdvisorCombinedData();
    console.log(data);
    this.isLoading = true;
    this.api.createNewServiceAdvisor(data).subscribe(
      (response) => {
        if (response.isSuccess) {
          // this.toastService.showToast(
          //   'Service Advisor Added Successfully',
          //   'success'
          // );
          setTimeout(() => {
            this.clearForm();
            this.isLoading = false;
            this.close.emit();
            this.updateList.emit();
          }, 1500);
          console.log('Response after post API call:', response);
        } else {
          this.handleError(response.errors);
        }
      },
      (error) => {
        this.isLoading = false;
        this.handleError(error?.error?.errors);
      }
    );
  }
}
