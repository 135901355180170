// Parent component
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

import { ServiceAdvisorResumeComponent } from './service-advisor-resume/service-advisor-resume.component';
import { ServiceAdvisorCertificatesComponent } from './service-advisor-certificates/service-advisor-certificates.component';
import { AddServiceAdvisorDetailsComponent } from './add-service-advisor-details/add-service-advisor-details.component';
import { UserService } from '../user-service.service';
import { ServiceAdvisorFormStateService } from '../services/formstate/service-advisor-form-stae-service.service';
import { UserFormStateService } from '../services/formstate/user-form-state-service.service';

@Component({
  selector: 'app-add-service-advisor',
  templateUrl: './add-service-advisor.component.html',
  styleUrl: './add-service-advisor.component.scss',
})
export class AddServiceAdvisorComponent {
  @Input() id?: number;
  @Output() updateUserList = new EventEmitter<void>();
  @Output() closeDialog = new EventEmitter<void>();

  @ViewChild(AddServiceAdvisorDetailsComponent) serviceAdvisorDetails!: AddServiceAdvisorDetailsComponent;
  @ViewChild(ServiceAdvisorResumeComponent) serviceAdvisorResume!: ServiceAdvisorResumeComponent;
  @ViewChild(ServiceAdvisorCertificatesComponent) serviceAdvisorCertificates!: ServiceAdvisorCertificatesComponent;

  editMode: boolean = false;
  activeSection: string = 'service-advisor-details';
  countryId!: any;
  isServiceAdvisorDetailsValid: boolean = false;
  isResumeValid: boolean = false;

  sectionValidity: { [key: string]: boolean } = {
    'service-advisor-details': false,
    resume: false,
    'service-advisor-certificates': false,
  };

  constructor(
    private cdr: ChangeDetectorRef,
    private formState: UserFormStateService,
    private api: UserService,
    private formStateService: ServiceAdvisorFormStateService,
  ) { }

  ngOnInit(): void {
    this.initializeForm();
    this.editMode = !!this.id;
    // If in edit mode (updating), fetch the service advisor by ID
    if (this.editMode && this.id) {
      this.serviceAdvisorById(this.id);
    }
  }

  initializeForm(): void {
    const tokenDetailsString = localStorage.getItem('tokenDetails');
    if (tokenDetailsString) {
      const tokenDetails = JSON.parse(tokenDetailsString);
      this.countryId = tokenDetails.country?.countryId;
    }
  }

  serviceAdvisorById(id: number): void {
    this.api.getServiceAdvisorById(id).subscribe((res: any) => {
      // Store the retrieved object in form state
      this.formStateService.updateObject(res);

      const uploadedFileId = this.formStateService.getUploadedFileId('resumeFile');
      const resumeDocument = res.serviceAdvisorDocuments?.find(
        (doc: any) => doc.documentTypeId === 12
      );

      if (!uploadedFileId && resumeDocument) {
        this.formStateService.setFormData('resumeFile', {
          fileName: resumeDocument.fileName,
          fileUrl: resumeDocument.filePath,
          file: resumeDocument.fileId,
        }
        );
      }

      if (this.serviceAdvisorDetails) {
        console.log('Populating Service Advisor Details');
        this.serviceAdvisorDetails.populateFormWithServiceProviderDetails(res);
      }

      // Certificates section: only set data if the file IDs are not already uploaded
      const identityCardFileId = this.formStateService.getUploadedFileId('identityCard');
      const qualificationCertificatesFileId = this.formStateService.getUploadedFileId('qualificationCertificates');
      const experienceCertificatesFileId = this.formStateService.getUploadedFileId('experienceCertificates');
      const otherCertificatesFileId = this.formStateService.getUploadedFileId('otherCertificates');

      // Extract file data for certificates
      const identityCard = res.serviceAdvisorDocuments?.find((doc: any) => doc.documentTypeId === 7);
      const qualificationCertificates = res.serviceAdvisorDocuments?.find((doc: any) => doc.documentTypeId === 13);
      const experienceCertificates = res.serviceAdvisorDocuments?.find((doc: any) => doc.documentTypeId === 14);
      const otherCertificates = res.serviceAdvisorDocuments?.find((doc: any) => doc.documentTypeId === 15);

      // Setting the certificate data in the formStateService if not already uploaded
      if (!identityCardFileId && identityCard) {
        this.formStateService.setFormData('identityCard', {
          fileName: identityCard.fileName,
          fileUrl: identityCard.filePath,
          file: identityCard.fileId,
        });
      }

      if (!qualificationCertificatesFileId && qualificationCertificates) {
        this.formStateService.setFormData('qualificationCertificates', {
          fileName: qualificationCertificates.fileName,
          fileUrl: qualificationCertificates.filePath,
          file: qualificationCertificates.fileId,
        });
      }

      if (!experienceCertificatesFileId && experienceCertificates) {
        this.formStateService.setFormData('experienceCertificates', {
          fileName: experienceCertificates.fileName,
          fileUrl: experienceCertificates.filePath,
          file: experienceCertificates.fileId,
        });
      }

      if (!otherCertificatesFileId && otherCertificates) {
        this.formStateService.setFormData('otherCertificates', {
          fileName: otherCertificates.fileName,
          fileUrl: otherCertificates.filePath,
          file: otherCertificates.fileId,
        });
      }

      // Patch data into the certificates component if loaded
      if (this.serviceAdvisorCertificates) {
        console.log('Populating Service Advisor Certificates');
        // this.serviceAdvisorCertificates.populateFormWithServiceProviderDetails(res);
        this.cdr.detectChanges(); // Trigger UI refresh
      }
    });
  }

  setActiveSection(section: string): void {

    this.activeSection = section;
    this.cdr.detectChanges();
    if (section === 'resume' && this.editMode && this.id) {
      this.serviceAdvisorById(this.id);
    }
    if (section === 'service-advisor-certificates' && this.editMode && this.id) {
      this.serviceAdvisorById(this.id);
    }
  }
  
  

  close(): void {
    if (this.serviceAdvisorDetails) {
      this.serviceAdvisorDetails.resetForm();
      this.cdr.detectChanges();
    }

    if (this.serviceAdvisorResume) {
      this.serviceAdvisorResume.resetForm();
      this.cdr.detectChanges();
    }

    if (this.serviceAdvisorCertificates) {
      this.serviceAdvisorCertificates.resetForm();
      this.cdr.detectChanges();
    }

    this.formStateService.clearFormData();
    this.formState.clearFormData();
    this.closeDialog.emit();
  }

  onUpdateUserList(): void {
    this.updateUserList.emit();
  }

  handleFinish(): void {
    this.setActiveSection('welcome-dashboard'); // Switch to WelcomeDashboard
  }
}
