<div class="overlay">
    <div class="modal" style="width: 100%; height: 100%">
        <div class="modal-content">
            <div class="flex justify-content-between flex-wrap" style="
      padding-top: 10px;
      padding-right: 2px;
      padding-left: 82px;
      border-bottom: 1px solid #f2f4f7;
    ">
                <div class="img">
                    <div class="flex justify-content-start flex-wrap">
                        <div class="flex align-items-center justify-content-center mr-1">
                            <img src="assets/image/Logomark.png" alt="Logo" class="svg-black" />
                        </div>
                        <div class="flex align-items-center justify-content-center">
                            <p style="color: #1d2939; font-weight: 700; font-size: 18px">
                                Auto E Care
                            </p>
                        </div>
                    </div>
                </div>
                <div class="flex align-items-center justify-content-center">
                </div>
                <div class="align-items-center justify-content-center m-3">
                </div>
            </div>

            <div class="flex" style="height: calc(100vh - 70px); overflow-y: auto">
                <div class="sidebar" style="margin-top: 68px">
                    <ul>
                        <li>
                            <a (click)="setActiveSection('pricing')">
                                <i class="pi pi-box"></i> Pricing
                            </a>
                        </li>
                        <li>
                            <a (click)="setActiveSection('payment')">
                                <i class="pi pi-credit-card"></i> Payment
                            </a>
                        </li>
                    </ul>

                </div>
                <div class="content" style="flex-grow: 1; padding: 20px">
                    <app-pricing *ngIf="activeSection==='pricing'"
                        (nextSection)="setActiveSection('payment')"></app-pricing>
                    <app-payment *ngIf="activeSection==='payment'" (nextSection)="setActiveSection('')"></app-payment>
                </div>
            </div>
        </div>
    </div>
</div>