import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserService } from '../../user-service.service';
import { ServiceAdvisorFormStateService } from './service-advisor-form-stae-service.service';

@Injectable({
  providedIn: 'root',
})
export class UserFormStateService {
  private personalDetails = new BehaviorSubject<any>({});
  private vehicleDetails = new BehaviorSubject<any>({});
  private additionalDetails = new BehaviorSubject<any>({});
  private uploadDocs = new BehaviorSubject<any>({});
  private tyrebattery = new BehaviorSubject<any>({});
  private insuranceDetails = new BehaviorSubject<any>({});
  private modelYear: number | null = null;
  private formData = new Map<string, any>();

  constructor(
    private serviceAdvisorFormStateService: ServiceAdvisorFormStateService,
    private userService: UserService
  ) { }

  setModelYear(year: number) {
    this.modelYear = year;
  }
  setUserId(id: any) {
    this.setFormData('CustomerId', id);
  }
  getUserId() {
    return this.getFormData('CustomerId');
  }
  getModelYear(): number | null {
    return this.modelYear;
  }

  getFormData(key: string): any {
    const savedData = this.formData.get(key);
    return savedData;
  }

  setFormData(key: string, data: any) {
    this.formData.set(key, data);
    console.log(`${key}`, data);
  }

  setPersonalDetails(details: any): void {
    this.personalDetails.next(details);
  }

  setUploadDocs(details: any) {
    this.uploadDocs.next(details);
  }

  setVehicleDetails(details: any): void {
    this.vehicleDetails.next(details);
    console.log('SetVehicleData', details);
  }

  clearFormData(): void {
    // Clear all BehaviorSubject values
    this.personalDetails.next({});
    this.vehicleDetails.next({});
    this.additionalDetails.next({});
    this.uploadDocs.next({});
    this.tyrebattery.next({});
    this.userService.clearInsuranceDetails();
    this.modelYear = null;
    this.formData.clear();
    this.data = {};
    this.serviceAdvisorFormStateService.setFormData('resumeFile', null);
    this.serviceAdvisorFormStateService.clearFormData();
    this.serviceSeekersDetailsSubject.next({});
    this.setFormData('tyre-battery', null);
    this.setFormData('personal-details', null);
    this.setFormData('vehicle-details', null);
    this.setFormData('insurance-details', null);
    this.setFormData('upload-documents', null);

    console.log(
      'UserFormStateService: All form data cleared, including ServiceAdvisorFormStateService file data.'
    );
  }

  setTyreBatteryDetails(details: any): void {
    this.tyrebattery.next(details);
  }
  setInsuranceDetails(details: any): void {
    this.insuranceDetails.next(details);
  }

  getUserCombinedData() {
    const combined = {
      ...this.personalDetails.value,
      vehicleDetails: [
        {
          ...this.uploadDocs.value,
          ...this.vehicleDetails.value,
          ...this.tyrebattery.value,
          ...this.insuranceDetails.value,
        },
      ],
    };
    return combined;
  }

  private data: { [key: string]: any } = {};

  setdata(key: string, data: any): void {
    this.data[key] = data;
  }

  getdata(key: string): any {
    return this.data[key];
  }

  private selectedFile: File | null = null;

  getSelectedFile(): File | null {
    return this.selectedFile;
  }

  setSelectedFile(file: File | null): void {
    this.selectedFile = file;
  }

  private uploadedFileId: number | null = null;
  private uploadedFileIds: { [key: string]: number } = {};

  setUploadedFileId(key: string, fileId: number): void {
    this.uploadedFileIds[key] = fileId;
  }

  getUploadedFileId(key: string): number | undefined {
    return this.uploadedFileIds[key];
  }
  // for service seekers
  private serviceSeekersDetailsSubject = new BehaviorSubject<any>({});
  ssDetails = this.serviceSeekersDetailsSubject.asObservable();

  updateObject(newObject: any) {
    this.serviceSeekersDetailsSubject.next(newObject);
  }
}
