import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LandingPageComponent } from './serviseAdvisor_module/landing-page/landing-page.component';
import { GetStartedComponent } from './serviseAdvisor_module/get-started/get-started.component';
import { SignupComponent } from './serviseAdvisor_module/signup/signup.component';
import { WelcomeDashboardComponent } from './serviseAdvisor_module/welcome-dashboard/welcome-dashboard.component';
import { LocationComponent } from './serviseAdvisor_module/location/location.component';
import { AddServiceAdvisorComponent } from './serviseAdvisor_module/add-service-advisor/add-service-advisor.component';
import { CaptureBusinessLocationComponent } from './serviseAdvisor_module/capture-business-location/capture-business-location.component';
import { LocationDetailsComponent } from './serviseAdvisor_module/location-details/location-details.component';
import { MapLocationComponent } from './serviseAdvisor_module/location-details/map-location/map-location.component';
import { AddressProofComponent } from './serviseAdvisor_module/location-details/address-proof/address-proof.component';
import { AddSpComponent } from './serviseAdvisor_module/add-sp-new-component/add-sp/add-sp.component';
import { OtpPageComponent } from './serviseAdvisor_module/otp-page/otp-page.component';
//import { ServiceProviderComponent } from './serviseAdvisor_module/service-provider/service-provider.component';

const routes: Routes = [
  { path: '', redirectTo: 'landingpage', pathMatch: 'full' },
  { path: 'landingpage', component: LandingPageComponent },
  { path: 'get-started', component: GetStartedComponent },
  { path: 'signup', component: SignupComponent },
  // {path: 'welcome', component: WelcomeDashboardComponent},
  { path: 'location', component: LocationComponent },
  { path: 'expert-details', component: AddServiceAdvisorComponent },
  // {path: 'captureBusinessLocation', component: CaptureBusinessLocationComponent},
  { path: 'locationdetails', component: LocationDetailsComponent },
  { path: 'map', component: MapLocationComponent },
  { path: 'address', component: AddressProofComponent },
  //{path: 'otherdetails', component: ServiceProviderComponent}
  { path: 'addSPDetails', component: AddSpComponent },
  { path: 'otp', component: OtpPageComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
