import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { InputNumber } from 'primeng/inputnumber';

@Component({
  selector: 'formly-field-custom-input-number',
  template: `
    <div class="custom-input-number flex justify-content-between flex-wrap">
      <label>{{ to.label }}</label>
      <div>
        <p-inputNumber #inputNumber
          [formControl]="formControl"
          [showButtons]="true"
          buttonLayout="horizontal"
          [step]="1"
          [min]="0"
          decrementButtonClass="p-button-danger"
          incrementButtonClass="p-button-success"
          incrementButtonIcon="pi pi-plus"
          decrementButtonIcon="pi pi-minus"
          mode="decimal">
        </p-inputNumber>
      </div>
    </div>
  `,
  styles: [`
    .custom-input-number {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      border: 1px solid #dfe1e5;
      margin-bottom: 10px;
      background-color: #fff;
    }

    .custom-input-number label {
      flex: 1;
      margin-right: 10px;
    }

    .custom-input-number ::ng-deep p-inputNumber {
      flex: 2;
    }

    .custom-input-number ::ng-deep p-inputNumber input {
      width: 75px !important; /* Adjusted width for 4 digits */
      height: 40px !important;
      background-color: #FCE9CF;
      color: #FD7809;
    }

    .custom-input-number ::ng-deep .p-inputnumber-button {
      width: 30px !important;
      height: 40px !important;
    }

    .custom-input-number ::ng-deep .p-inputnumber-button .pi {
      font-size: 1.5rem;
    }

    ::ng-deep .p-button.p-button-success {
      color: #FEBA6D;
      background: #FCE9CF;
      border: 1px solid #FEBA6D;
    }

    ::ng-deep .p-button.p-button-danger {
      color: #FEBA6D;
      background: #FCE9CF;
      border: 1px solid #FEBA6D;
    }
  `]
})
export class CustomInputNumberTypeComponent extends FieldType<FieldTypeConfig> implements OnInit {
  ngOnInit(): void {

  }
  @ViewChild('inputNumber') inputNumber!: InputNumber;

}

