import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceProviderService } from '../services/serviceProvider/service-provider.service';

@Component({
  selector: 'app-welcome-dashboard',
  templateUrl: './welcome-dashboard.component.html',
  styleUrls: ['./welcome-dashboard.component.scss'],
})
export class WelcomeDashboardComponent implements OnInit {
  locationAdded = false;
  location: string = '';
  coordinates: string = '';

  advisorName: string = '';
  advisorPhoneNumber: string = '';
  advisorDetailsFetched: boolean = false;
  activeSection: string = 'dashboard'; // Default section
  // Dialog visibility state
  showDialog: boolean = false;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private spService: ServiceProviderService
  ) { }

  ngOnInit(): void {
    this.fetchLocationData();
    this.fetchAdvisorDetails()
  }
  openLocationDetails(): void {
    this.activeSection = 'location-details'; // Switch to LocationDetailsComponent
  }
  openServiceAdvisorComponent(): void {
    this.activeSection = 'add-service-advisor'; // Switch to AddServiceAdvisorComponent
  }


  fetchLocationData(): void {
    const tempRegId = localStorage.getItem('temporaryRegId') || '';
    const phoneNumber = localStorage.getItem('phoneNumber') || '';

    if (tempRegId && phoneNumber) {
      this.spService.getLocationInfo(tempRegId, phoneNumber).subscribe({
        next: (response) => {
          if (response.isSuccess && response.result) {
            const data = response.result;

            // Check if location details are available
            this.locationAdded = !!(data.address || data.latitude || data.longitude);

            if (this.locationAdded) {
              this.location = data.address || '';
              this.coordinates = `${data.latitude}, ${data.longitude}`;
            }
          }
        },
        error: (err) => {
          console.error('Failed to fetch location data:', err);
        },
      });
    } else {
      console.warn('TemporaryRegId or PhoneNumber is missing.');
    }
  }

  fetchAdvisorDetails(): void {
    const tempRegId = localStorage.getItem('temporaryRegId') || '';
    const phoneNumber = localStorage.getItem('phoneNumber') || '';

    if (tempRegId && phoneNumber) {
      this.spService.fetchServiceAdvisorDetails(tempRegId, phoneNumber).subscribe({
        next: (response) => {
          if (response.isSuccess && response.result) {
            this.advisorName = response.result.serviceAdvisorName;
            this.advisorPhoneNumber = response.result.serviceAdvisorPhoneNumber;
            this.advisorDetailsFetched = true; // Advisor details fetched successfully
          }
        },
        error: (err) => {
          console.error('Failed to fetch service advisor details:', err);
        },
      });
    } else {
      console.warn('TemporaryRegId or PhoneNumber is missing.');
    }
  }
  navigateToLocation(): void {
    this.router.navigate(['/map']);
  }

  navigateTo(): void {
    this.router.navigate(['/expert-details']);
  }

  finalSubmission(): void {
    const temporaryRegId = localStorage.getItem('temporaryRegId') || '';
    const phoneNumber = localStorage.getItem('phoneNumber') || '';

    const providerRequestBody = {
      temporaryRegId,
      phoneNumber,
    };

    this.spService.postAdvisorInfo(providerRequestBody).subscribe({
      next: (providerResponse) => {
        if (providerResponse.isSuccess) {
          console.log('Provider Info submitted successfully:', providerResponse);
          // Show confirmation dialog
          this.showDialog = true;

        } else {
          console.error('Failed to submit provider info:', providerResponse.errors);
        }
      },
      error: (err) => {
        console.error('Error submitting provider info:', err);
      },
    });
  }
  navigateToOTP() {
    this.showDialog = false; // Close the dialog
    this.router.navigate(['/landingpage']);
  }
}
