import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { ServiceProviderService } from '../services/serviceProvider/service-provider.service';
import { FormStateService } from './form-state.service';
import { environment } from '../../../environments/environment.development';

interface CustomFile {
  id: any;
  name: string;
  path: string;
}

@Component({
  selector: 'formly-field-multi-file',
  template: `
    <div class="custom-file-upload mt-2">
      <label
        for="file-upload"
        class="upload-label"
        [class.disabled]="selectedFiles.length >= 5"
      >
        <div class="flex justify-content-between flex-wrap">
          <div class="flex align-items-center justify-content-center mr-3">          
            <i class="pi pi-cloud-upload"></i>
          </div>
          <div class="flex align-items-center justify-content-center">Upload</div>
        </div>
      </label>
      <input 
        id="file-upload" 
        type="file" 
        (change)="onFileSelect($event)" 
        [disabled]="selectedFiles.length >= 5" 
        multiple 
      />
    </div>

    <div *ngIf="selectedFiles.length > 0" class="file-list">
      <div *ngFor="let file of selectedFiles; let i = index" class="file-item">
        <span 
          (click)="previewFile(file)" 
          class="file-name" 
          [class.hovered]="hoverIndex === i"
          (mouseover)="hoverIndex = i" 
          (mouseout)="hoverIndex = -1"
        >{{ file.name }}</span>
        <button type="button" (click)="removeFile(i)" class="remove-button">Remove</button>
      </div>
    </div>

    <!-- PrimeNG Dialog for image preview -->
    <p-dialog header="Image Preview" [(visible)]="previewVisible" [modal]="true" [closable]="true" (onHide)="closePreview()" [style]="{width: '50vw'}">
      <img *ngIf="isImageFile()" [src]="previewSrc" alt="Image Preview" class="modal-image" />
    </p-dialog>
  `,
  styles: [`
    .custom-file-upload {
      display: inline-block;
      position: relative;
    }

    .upload-label {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;
      border: 1px solid #d0d5dd;
      border-radius: 8px;
      padding: 10px 20px;
      font-size: 16px;
      font-weight: 600;
      color: #344054;
      cursor: pointer;
      transition: background-color 0.3s ease, border-color 0.3s ease;
    }

    .upload-label.disabled {
      cursor: not-allowed;
      opacity: 0.6;
    }

    .upload-label:hover:not(.disabled) {
      background-color: #f3f4f6;
      border-color: #9ca3af;
    }

    input[type="file"] {
      display: none;
    }

    .file-list {
      margin-top: 10px;
    }

    .file-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #f9fafb;
      border: 1px solid #d0d5dd;
      border-radius: 4px;
      padding: 8px 12px;
      margin-bottom: 8px;
    }

    .file-name {
      color: #344054;
      cursor: pointer;
      flex-grow: 1;
      transition: font-weight 0.3s ease, text-decoration 0.3s ease;
    }

    .file-name.hovered {
      font-weight: bold;
      text-decoration: underline;
    }

    .remove-button {
      background: none;
      border: none;
      color: #d32f2f;
      cursor: pointer;
    }

    .modal-image {
      max-width: 100%;
      max-height: 60vh;
      display: block;
      margin: 0 auto;
    }
  `]
})
export class FormlyFieldMultiFile extends FieldType<FieldTypeConfig> implements OnInit {
  selectedFiles: (File | CustomFile)[] = [];
  previewSrc: string | null = null;
  hoverIndex: number = -1;
  previewVisible: boolean = false;

  constructor(
    private api: ServiceProviderService,
    private cdr: ChangeDetectorRef,
    private fileService: FormStateService
  ) {
    super();
  }

  ngOnInit() {
    if (this.formControl.value && Array.isArray(this.formControl.value)) {
      this.selectedFiles = this.formControl.value.map((file: any) => ({
        id: file.fileId,
        name: file.fileName,
        path: file.filePath,
      }));

      this.cdr.detectChanges();
    }
  }

  onFileSelect(event: any) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const newFiles = Array.from(input.files);

      if (this.selectedFiles.length + newFiles.length > 5) {
        alert('You can only select up to 5 files.');
        return;
      }

      newFiles.forEach((file) => {
        const alreadyExists = this.selectedFiles.some((f) =>
          this.isBrowserFile(f) ? f.name === file.name && f.lastModified === file.lastModified : f.name === file.name
        );

        if (!alreadyExists) {
          console.log('New file selected:', file);
          this.selectedFiles.push(file);

          const formData = new FormData();
          formData.append('file', file);
          formData.append('description', 'File upload description');
          formData.append('fileSourceId', '1');

          this.api.createFiles(formData).subscribe((res: any) => {
            const fileId = res.fileId;
            const currentValues = this.formControl.value || [];
            this.formControl.setValue([...currentValues, { fileId, fileName: file.name, filePath: res.filePath }]);
            console.log('File uploaded. Received fileId:', fileId);
            this.cdr.detectChanges();
          });
        }
      });
    }
  }



  removeFile(index: number) {
    const fileToRemove = this.selectedFiles[index];

    // Remove the file from selectedFiles
    this.selectedFiles.splice(index, 1);

    // Update formControl value
    const updatedFiles = this.selectedFiles.map((file: any) => ({
      fileId: file.id || file.fileId, // Use existing ID for previously uploaded files
      fileName: file.name,
      filePath: file.path || file.filePath,
    }));
    this.formControl.setValue(updatedFiles);

    this.cdr.detectChanges();
  }



  previewFile(file: any) {
    if (file.path) {
      this.previewSrc = `${environment.baseURL}/${file.path}`;
      this.previewVisible = true;
    }
  }

  closePreview() {
    this.previewVisible = false;
    this.previewSrc = null;
  }

  isImageFile(file: any = this.selectedFiles[0]): boolean {
    if (!file) return false;
    return file.name?.toLowerCase().endsWith('.png') ||
      file.name?.toLowerCase().endsWith('.jpg') ||
      file.name?.toLowerCase().endsWith('.jpeg') ||
      file.path?.toLowerCase().endsWith('.png') ||
      file.path?.toLowerCase().endsWith('.jpg') ||
      file.path?.toLowerCase().endsWith('.jpeg');
  }

  // Helper method to distinguish between File and CustomFile
  private isBrowserFile(file: File | CustomFile): file is File {
    return (file as File).lastModified !== undefined;
  }



}
