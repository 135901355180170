import { Component } from '@angular/core';

@Component({
  selector: 'app-add-payment',
  templateUrl: './add-payment.component.html',
  styleUrl: './add-payment.component.scss'
})
export class AddPaymentComponent {
  activeSection = 'pricing'

  setActiveSection(section: string): void {
    Promise.resolve().then(() => {
      this.activeSection = section;
    })
  }
}
