<div class="overlay" *ngIf="isModalOpen">
  <div class="modal" style="width: 100%; height: 100%">
    <div class="modal-content">
      <div class="flex justify-content-between flex-wrap" style="
        padding-top: 10px;
        padding-right: 2px;
        padding-left: 82px;
        border-bottom: 1px solid #f2f4f7;
      ">
        <div class="img">
          <div class="flex justify-content-start flex-wrap">
            <div class="flex align-items-center justify-content-center mr-1">
              <img src="assets/image/Logomark.png" alt="Logo" class="svg-black" />
            </div>
            <div class="flex align-items-center justify-content-center">
              <p style="color: #1d2939; font-weight: 700; font-size: 14px">
                Auto E Care
              </p>
            </div>
          </div>
        </div>
        <!-- <div class="flex align-items-center justify-content-center">
          <p style="font-weight: 700; color: #333; font-size: 24px;">
            {{ editMode ? 'Add Service Provider Form' : 'Update Service Provider Form' }}
          </p>
        </div> -->
        <div class="align-items-center justify-content-center m-3">
          <i class="pi pi-times" style="cursor: pointer; font-size: 1em" (click)="close()"></i>
        </div>
      </div>

      <div class="flex" style="height: calc(100vh - 70px); overflow-y: auto">
        <div class="sidebar" style="margin-top: 62px">
          <ul>
            <li>
              <a (click)="setActiveSection('business-details')"
                [ngClass]="{ active: activeSection === 'business-details' }">
                <i class="pi pi-bars"></i> Business Details
              </a>
            </li>
            <li>
              <a (click)="setActiveSection('owner-details')"
                [ngClass]="{ active: activeSection === 'owner-details', disabled: !isBusinessValid || !isOwnerValid }">
                <i class="pi pi-user"></i> Owner/Incharge Details
              </a>
            </li>
            <li>
              <a (click)="setActiveSection('service-offered')"
                [ngClass]="{ active: activeSection === 'service-offered', disabled: !isBusinessValid || !isOwnerValid || !isServiceValid }">
                <i class="pi pi-wrench"></i> Service Offered
              </a>
            </li>
            <li>
              <a (click)="setActiveSection('technician-details')"
                [ngClass]="{ active: activeSection === 'technician-details', disabled: !isBusinessValid || !isOwnerValid || !isServiceValid || !isTechnicianValid }">
                <i class="pi pi-users"></i> Technician Details
              </a>
            </li>
          </ul>
        </div>
        <div class="content" style="flex-grow: 1; padding: 20px">
          <app-business-details-new *ngIf="activeSection === 'business-details'"
            (nextSection)="setActiveSection('owner-details')"
            (isValid)="validateBusinessDetails($event)"></app-business-details-new>

          <app-incharge-owner-details *ngIf="activeSection === 'owner-details'" [countryId]="countryId"
            (nextSection)="setActiveSection('service-offered')"
            (isValid)="validateOwnerDetails($event)"></app-incharge-owner-details>

          <app-service-offered-new *ngIf="activeSection === 'service-offered'"
            (nextSection)="setActiveSection('technician-details')"
            (isValid)="validateServiceOffered($event)"></app-service-offered-new>

          <app-technician-info *ngIf="activeSection === 'technician-details'" (closeDialog)="closeDialog()"
            (nextSection)="handleFinish()"></app-technician-info>
        </div>
      </div>
    </div>
  </div>

  <!-- Always include Capture Business Location -->
  <app-capture-business-location
    [hidden]="activeSection !== 'capture-location'"
    #captureBusinessLocation>
  </app-capture-business-location>
</div>
