<div class="popup-container">
  <div class="popup-content">
    <h2>Mobile Phone Verification</h2>
    <p class="description">
      Select your country code and enter your 10-digit phone number to receive a verification code.
    </p>

    <!-- Phone Number Input -->
    <div class="input-group">
      <label for="phoneNumber">Phone Number</label>
      <div class="phone-input-group">
        <!-- Country Code Dropdown -->
        <select id="countryCode" [(ngModel)]="model.countryCode" (change)="updateCountryId()"
          class="country-code-dropdown">
          <option *ngFor="let code of countryCodes" [value]="code.value">
            {{ code.label }}
          </option>
        </select>

        <!-- Phone Number Input -->
        <input pInputText id="phoneNumber" type="tel" maxlength="10" [(ngModel)]="model.phoneNumber"
          (input)="handlePhoneNumberChange()" placeholder="Enter phone number" />
      </div>
    </div>

    <!-- Send OTP Button -->
    <div class="button-group">
      <button *ngIf="!otpSent" type="button" class="btn-send-otp" (click)="sendOtp()"
        [disabled]="!model.phoneNumber || model.phoneNumber.length !== 10 || !model.countryCode">
        Send OTP
      </button>
    </div>

    <!-- OTP Input -->
    <div *ngIf="otpSent && model.phoneNumber.length === 10" class="otp-input-container">
      <label for="otp">Enter OTP</label>
      <div class="otp-box-container">
        <p-inputOtp [length]="6" [disabled]="!otpSent" [(ngModel)]="model.otp"
          (ngModelChange)="onOtpChange($event)"></p-inputOtp>
      </div>
    </div>

    <!-- Verify Button -->
    <div *ngIf="otpSent && model.phoneNumber.length === 10" class="button-group">
      <button type="button" class="btn-verify" [disabled]="!otpSent || !isOtpValid()" (click)="onSubmit()">
        Verify Account
      </button>
    </div>

    <!-- Resend Link -->
    <p *ngIf="otpSent" class="resend">
      <span *ngIf="resendTimer > 0">Resend OTP in {{ resendTimer }} secs</span>
      <a *ngIf="resendTimer === 0" (click)="resendOtp()">Resend</a>
    </p>
  </div>
</div>
<app-custom-toast></app-custom-toast>