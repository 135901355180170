<div *ngIf="isLoading">
  <app-loading-screen [isLoading]="isLoading" [loadingMessage]="'Signing up...'"></app-loading-screen>
</div>

<div class="form-container" *ngIf="!isLoading && currentView === 'signup'">
  <h2 class="form-heading">Sign Up</h2>
  <p class="form-subtitle">Please enter the following details</p>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <formly-form [form]="form" [fields]="fields" [model]="model" [options]="options"></formly-form>
    <p class="terms">
      By continuing, you agree to our
      <a href="#">Terms of Service</a> and
      <a href="#">Privacy Policy</a>.
    </p>
    <button pButton type="submit" label="Sign up" class="p-mt-3  submit-btn"></button>
  </form>
</div>
<app-welcome-dashboard *ngIf="currentView === 'welcome'"></app-welcome-dashboard>
<app-capture-business-location *ngIf="currentView === 'captureBusinessLocation'"></app-capture-business-location>