import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, map, throwError } from 'rxjs';
import { environment } from '../../environments/environment.development';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  // api: string = 'http://admin.autocare.com:5107';
  api: string = environment.baseURL;

  private vehicleDetailsSubject = new BehaviorSubject<any>(null);
  private insuranceDetailsSubject = new BehaviorSubject<any>(null);

  vehicleDetails$ = this.vehicleDetailsSubject.asObservable();
  insuranceDetails$ = this.insuranceDetailsSubject.asObservable();

  constructor(private http: HttpClient) { }

  renderUserList(params: any) {
    return this.http.get<any>(`${this.api}/api/Customers`, { params });
  }

  renderServiceAdvisorList(params: any) {
    return this.http.get<any>(`${this.api}/api/ServiceAdvisors`, { params });
  }

  getVehicleMakers(searchQuery: any) {
    let url = `${this.api}/api/Lookup/vehiclemakers`;

    if (searchQuery) {
      url += `?searchQuery=${searchQuery}`;
    }

    return this.http.get<any>(url).pipe(
      catchError((error) => {
        console.error('Error fetching vehicle models:', error);
        return throwError(error);
      })
    );
  }

  getVehicleModels(searchQuery: string): Observable<any> {
    let url = `${this.api}/api/Lookup/vehiclemodels`;
    if (searchQuery) {
      url += `?searchQuery=${searchQuery}`;
    }

    return this.http.get<any>(url).pipe(
      catchError((error) => {
        console.error('Error fetching vehicle models:', error);
        return throwError(error);
      })
    );
  }

  getVehicleModelCodes(search: any) {
    return this.http.get<any>(
      `${this.api}/api/Lookup/modelcodes?searchQuery=${search}`
    );
  }

  getVehicleVehicleSellers() {
    return this.http.get<any>(`${this.api}/api/Lookup/vehiclesellers`);
  }

  getTyreTypes(): Observable<{ tyreTypeId: number; name: string }[]> {
    return this.http.get<{ tyreTypeId: number; name: string }[]>(
      `${this.api}/api/Lookup/tyretypes`
    );
  }

  getInsuranceCompanyNames() {
    return this.http.get<any>(`${this.api}/api/Lookup/insurancecompany`);
  }

  createNewUser(data: any) {
    return this.http.post<any>(`${this.api}/api/Customers`, data);
  }

  createNewServiceAdvisor(data: any) {
    return this.http.post<any>(`${this.api}/api/ServiceAdvisors`, data);
  }

  getUserById(id: any) {
    return this.http.get<any>(`${this.api}/api/Customers/${id}`);
  }

  getUserStatusHistory(id: any) {
    return this.http.get<any>(
      `${this.api}/api/Customers/status-histories/${id}`
    );
  }

  getAdvisorStatusHistory(id: any) {
    return this.http.get<any>(
      `${this.api}/api/ServiceAdvisors/status-histories/${id}`
    );
  }

  getBatteryMakers() {
    return this.http.get<any>(`${this.api}/api/Lookup/batterymakers`);
  }

  getOilGrade() {
    return this.http.get<any>(`${this.api}/api/Lookup/engineoilgrades`);
  }

  getPaintingTypes() {
    return this.http.get<any>(`${this.api}/api/Lookup/paintingtypes`);
  }

  getInsuranceTypes() {
    return this.http.get<any>(`${this.api}/api/Lookup/insurancetypes`);
  }

  getVehicleOrigin(search: any) {
    return this.http.get<any>(`${this.api}/api/Lookup/vehicleorigin${search}`);
  }

  uploadFile(data: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', '*/*');
    return this.http.post<any>(`${this.api}/api/File`, data, {
      headers,
      reportProgress: true,
      observe: 'events',
    });
  }

  checkPhoneNumberExists(phone: string): Observable<boolean> {
    return this.http
      .get<any[]>(`${this.api}/api/customers`)
      .pipe(
        map((customers: any) =>
          customers.some((customer: any) => customer.phoneNumber === phone)
        )
      );
  }

  approveUser(id: any, data: any): Observable<any> {
    return this.http.post(
      `${this.api}/api/Customers/change-status/${id}`,
      data
    );
  }

  approveServiceAdvisor(id: any, data: any): Observable<any> {
    return this.http.post(
      `${this.api}/api/ServiceAdvisors/change-status/${id}`,
      data
    );
  }

  rejectUser(id: any, data: any): Observable<any> {
    return this.http.post(
      `${this.api}/api/Customers/change-status/${id}`,
      data
    );
  }

  rejectServiceAdvisor(id: any, data: any): Observable<any> {
    return this.http.post(
      `${this.api}/api/ServiceAdvisors/change-status/${id}`,
      data
    );
  }

  getUsersByStatus(status: number): Observable<any[]> {
    return this.http.get<any[]>(`${this.api}/api/Customers?status=${status}`);
  }

  getRegistrationDetails(registrationNumber: any): Observable<any> {
    const requestBody = { vehicleRegistrationNumber: registrationNumber };
    return this.http.post(`${this.api}/api/Vehicles/verification`, requestBody);
  }

  setVehicleDetails(details: any): void {
    this.vehicleDetailsSubject.next(details);
  }

  setInsuranceDetails(details: any): void {
    this.insuranceDetailsSubject.next(details);
  }

  clearInsuranceDetails(): void {
    this.insuranceDetailsSubject.next(null);
  }

  getServiceAdvisorById(id: any) {
    return this.http.get<any>(`${this.api}/api/ServiceAdvisors/${id}`);
  }

  getCustommerById(id: any) {
    return this.http.get<any>(`${this.api}/api/Customers/${id}`);
  }

  updateServiceAdvisorInfo(id: any, data: any) {
    return this.http.post<any>(`${this.api}/api/ServiceAdvisors/${id}`, data);
  }

  /// Token Details
  storeToken(tokenValue: string) {
    localStorage.setItem('token', tokenValue);
  }

  getToken() {
    return localStorage.getItem('token');
  }

  logout() {
    localStorage.clear();
  }

  getLogiProfile() {
    return this.http.get(`${this.api}/api/Users/profile`);
  }
  updateProfile(data: any) {
    return this.http.post<any>(this.api + '/api/Users/profile', data);
  }

  updatePassword(data: any) {
    return this.http.post<any>(this.api + 'api/Users/change-password', data);
  }

  ///
  private profileDetails = new BehaviorSubject<any>({});
  private formData = new Map<string, any>();
  private uploadedFileIds: { [key: string]: number } = {}; // Map file IDs to keys for each document type

  getFormData(key: string): any {
    return this.formData.get(key);
  }

  setFormData(key: string, data: any): void {
    this.formData.set(key, data);
  }

  clearFormData(): void {
    this.formData.clear();
  }

  setUploadedFileId(key: string, fileId: number): void {
    this.uploadedFileIds[key] = fileId;
    console.log(this.uploadedFileIds);
  }

  getUploadedFileId(key: string): any {
    return this.uploadedFileIds[key];
  }

  setProfileDetails(details: any): void {
    this.profileDetails.next(details);
  }

  private profileSubject = new BehaviorSubject<any>(null);
  profile$ = this.profileSubject.asObservable();

  updateProfileData(profileData: any) {
    this.profileSubject.next(profileData);
    localStorage.setItem('profileData', JSON.stringify(profileData));
  }

  getProfileData() {
    const storedProfileData = localStorage.getItem('profileData');
    if (storedProfileData) {
      this.profileSubject.next(JSON.parse(storedProfileData));
    }
  }

  updateServiceSeekersInfo(id: any, data: any) {
    return this.http.post<any>(`${this.api}/api/customers/${id}`, data);
  }
  postResumeDetails(payload: any): Observable<any> {
    return this.http.post<any>(`${this.api}/api/SignUp/serviceAdvisor/resume`, payload);
  }

  // FETCH Resume Details
  fetchResumeDetails(temporaryRegId: string, phoneNumber: string): Observable<any> {
    const params = { temporaryRegId, phoneNumber };
    return this.http.get<any>(`${this.api}/api/SignUp/serviceAdvisor/resume`, { params });
  }

  // upload certificates
  uploadCertificates(payload: any): Observable<any> {
    const apiUrl = `${this.api}/api/SignUp/serviceAdvisor/uploadCertificacte`;
    return this.http.post<any>(apiUrl, payload);
  }
  fetchCertificates(temporaryRegId: string, phoneNumber: string): Observable<any> {
    const apiUrl = `${this.api}/api/SignUp/serviceAdvisor/uploadCertificacte`;
    const params = { temporaryRegId, phoneNumber };
    return this.http.get<any>(apiUrl, { params });
  }


}
