import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { FormStateService } from '../form-state.service';
import { AddServiceModalComponent } from './add-service-modal/add-service-modal.component';
import { debounceTime } from 'rxjs/operators';
import { ServiceProviderService } from '../../services/serviceProvider/service-provider.service';
import { ToastService } from '../../../toastr/toastr_service/service.service';
//import { ToastService } from '../../../../Service/ToastService/toast.service';

@Component({
  selector: 'app-service-offered-new',
  templateUrl: './service-offered-new.component.html',
  styleUrls: ['./service-offered-new.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceOfferedNewComponent implements OnInit {
  @ViewChild(AddServiceModalComponent)
  addServiceModal!: AddServiceModalComponent;
  form: FormGroup;
  // model: any = {};
  model: any = {
    service_: '',
  };
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[] = [];
  serviceLists: any[] = [];
  filteredServices: any[] = [];
  showModal: boolean = false;
  serviceNameToAdd: string = '';
  selectedBusinessType: any;
  @Output() nextSection = new EventEmitter<void>();
  @Output() isValid = new EventEmitter<boolean>();
  searchQuery = '';
  private isUpdatingFields = false;
  receivedObject: any;

  constructor(
    private formStateService: FormStateService,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private api: ServiceProviderService,
    private toastService: ToastService
  ) {
    this.form = this.formBuilder.group({
      selectedCategory: [''],
    });
  }

  ngOnInit(): void {
    const temporaryRegId = localStorage.getItem('temporaryRegId') || '';
    const phoneNumber = localStorage.getItem('phoneNumber') || '';

    if (!temporaryRegId || !phoneNumber) {
      console.error('TemporaryRegId or PhoneNumber missing in local storage.');
      return;
    }

    // Fetch business types
    this.api.getBusinessTypeList('').subscribe({
      next: (businessTypes: any[]) => {
        this.serviceLists = businessTypes.map((type: any) => ({
          label: type.name,
          value: type.businessTypeId,
          services: type.services || [],
        }));
        this.cdr.detectChanges();

        // Fetch selected business type
        this.formStateService.selectedBusinessType$.subscribe((businessType) => {
          if (businessType) {
            this.selectedBusinessType = businessType;
            this.patchFormValue({
              selectedCategory: {
                label: businessType.name,
                value: businessType.businessTypeId,
              },
            });
            this.updateServicesByCategory(businessType.services);
          } else {
            // Fallback: Fetch and set business type from API
            this.fetchAndSetBusinessType(temporaryRegId, phoneNumber);
          }
        });
      },
      error: (err) => {
        console.error('Error fetching business types:', err);
      },
    });

    this.loadFormData(); // Load additional data for services
  }


  fetchAndSetBusinessType(temporaryRegId: string, phoneNumber: string): void {
    this.api.getBusinessInfo(temporaryRegId, phoneNumber).subscribe({
      next: (response) => {
        if (response.isSuccess && response.result) {
          const businessType = {
            label: response.result.businessName || 'Default Name',
            value: response.result.businessTypeId,
            services: response.result.services || [],
          };

          // Set business type in FormStateService
          this.formStateService.setSelectedBusinessType(businessType);

          // Patch form and update services
          this.patchFormValue({
            selectedCategory: {
              label: businessType.label,
              value: businessType.value,
            },
          });
          this.updateServicesByCategory(businessType.services);

          console.log('Business Type Fetched and Patched:', businessType);
        } else {
          console.warn('Failed to fetch business info or invalid response.');
        }
      },
      error: (err) => {
        console.error('Error fetching business info:', err);
      },
    });
  }

  private patchFormValue(value: any): void {
    this.isUpdatingFields = true;
    this.form.patchValue(value);
    this.isUpdatingFields = false;
  }

  updateServicesByCategory(services: any[]): void {
    this.filteredServices = services.map((service) => ({
      id: service.serviceId || service.id,
      label: service.name || service.label,
    }));
    this.updateFields();
  }

  updateFields(): void {
    if (this.isUpdatingFields) return;
    this.isUpdatingFields = true;

    // Maintain the current state of selected checkboxes
    const selectedServices = Object.keys(this.model)
      .filter((key) => key.startsWith('service_') && this.model[key])
      .map((key) => Number(key.replace('service_', ''))); // Convert to number

    if (this.filteredServices.length === 0 && this.searchQuery) {
      this.fields = [
        {
          key: 'add_new',
          type: 'custom-add-box',
          templateOptions: {
            label: `Add "${this.searchQuery}"`,
            customClass: 'add-new-service',
            onClick: () => this.onAddNewService(this.searchQuery),
          },
        },
      ];
    } else {
      this.fields = this.filteredServices.map((service) => {
        const serviceId = service.id || service.serviceId;
        return {
          key: 'service_' + serviceId,
          type: 'custom-checkbox',
          templateOptions: {
            label: service.label || service.name,
          },
          defaultValue: this.model['service_' + service.id] || false, // Retrieve saved checkbox state
        };
      });

      // Patch the form values to maintain checkbox states
      const patchValues: any = {};
      this.filteredServices.forEach((service) => {
        const serviceId = service.id || service.serviceId;
        patchValues['service_' + serviceId] =
          selectedServices.includes(serviceId);
      });

      this.form.patchValue(patchValues, { emitEvent: false });
    }

    setTimeout(() => {
      this.cdr.detectChanges();
      this.isUpdatingFields = false;
    });
  }

  onSubmit() {
    if (this.form.valid) {
      const temporaryRegId = localStorage.getItem('temporaryRegId') || '';
      const phoneNumber = localStorage.getItem('phoneNumber') || '';

      if (!temporaryRegId || !phoneNumber) {
        console.error('TemporaryRegId or PhoneNumber missing in local storage.');
        return;
      }

      // Extract selected service IDs
      const selectedServiceIds = Object.keys(this.form.value)
        .filter((key) => key.startsWith('service_') && this.form.value[key])
        .map((key) => Number(key.replace('service_', ''))); // Convert to number

      // Extract the selected businessTypeId
      const selectedCategory = this.form.get('selectedCategory')?.value;
      const businessTypeId = selectedCategory?.value || this.selectedBusinessType?.businessTypeId;

      if (!businessTypeId) {
        console.error('BusinessTypeId is missing.');
        return;
      }

      const requestData = {
        temporaryRegId,
        phoneNumber,
        serviceOfferedIds: selectedServiceIds,
        businessTypeId, // Include businessTypeId
      };

      console.log('Request Data:', requestData);

      this.api.postServiceInfo(requestData).subscribe({
        next: (response) => {
          if (response.isSuccess) {
            console.log('Service Info successfully saved:', response);
            this.toastService.showToast('Service Info saved successfully!', 'success')
            this.nextSection.emit();
          } else {
            this.toastService.showToast('Failed to save service info!', 'error')
            console.error('Failed to save service info:', response.errors);
          }
        },
        error: (err) => {
          console.error('Error posting service info:', err);
        },
      });
    } else {
      console.warn('Form is invalid. Please check all required fields.');
    }
  }



  resetForm() {
    this.form.reset();
    this.model = {};
  }

  onSearchChange(event: Event): void {
    this.searchQuery = (event.target as HTMLInputElement)?.value.toLowerCase();

    if (this.searchQuery) {
      const selectedCategory = this.form.get('selectedCategory')?.value;
      if (selectedCategory && selectedCategory.value) {
        const businessType = this.serviceLists.find(
          (bt) => bt.value === selectedCategory.value
        );
        if (businessType) {
          this.filteredServices = businessType.services.filter((service: any) =>
            service.name.toLowerCase().includes(this.searchQuery)
          );
        }
      } else {
        this.filteredServices = [];
      }
    } else {
      const selectedCategory = this.form.get('selectedCategory')?.value;
      if (selectedCategory && selectedCategory.value) {
        const businessType = this.serviceLists.find(
          (bt) => bt.value === selectedCategory.value
        );
        if (businessType) {
          this.filteredServices = [...businessType.services];
        }
      } else {
        this.filteredServices = [];
      }
    }
    this.updateFields();
  }

  onAddNewService(serviceName: string): void {
    this.serviceNameToAdd = serviceName;
    this.showModal = true;
  }

  onConfirmAddService(confirmed: boolean): void {
    this.showModal = false;
    if (confirmed) {
      const serviceName =
        this.serviceNameToAdd.charAt(0).toUpperCase() +
        this.serviceNameToAdd.slice(1);

      const data = {
        name: serviceName,
        businessTypeId: this.selectedBusinessType.businessTypeId,
      };
      console.log(data);
      this.api.postNewService(data).subscribe(
        (response) => {
          //this.toastService.showToast('Service added successfully!', 'success');
          const newService = {
            serviceId: response.serviceId,
            name: serviceName,
          };
          this.selectedBusinessType.services.push(newService);

          const businessTypeIndex = this.serviceLists.findIndex(
            (bt) => bt.value === this.selectedBusinessType.businessTypeId
          );
          if (businessTypeIndex !== -1) {
            this.serviceLists[businessTypeIndex].services =
              this.selectedBusinessType.services;
          }

          this.searchQuery = '';
          this.filteredServices = [...this.selectedBusinessType.services];
          this.updateFields();
        },
        (error) => {
          // this.toastService.showToast(
          //   'Failed to add service. Please try again.',
          //   'error'
          // );
        }
      );
    }
  }

  loadFormData() {
    const temporaryRegId = localStorage.getItem('temporaryRegId') || '';
    const phoneNumber = localStorage.getItem('phoneNumber') || '';

    if (!temporaryRegId || !phoneNumber) {
      console.error('TemporaryRegId or PhoneNumber missing in local storage.');
      return;
    }

    this.api.getServiceInfo(temporaryRegId, phoneNumber).subscribe({
      next: (response) => {
        if (response.isSuccess && response.result) {
          console.log('Fetched Data:', response.result);

          // Parse the serviceOfferedIds and create patchable values
          const patchValues: any = {};
          const serviceIds = response.result.serviceOfferedIds?.split(',');
          if (serviceIds) {
            serviceIds.forEach((id: string) => {
              patchValues[`service_${id.trim()}`] = true;
            });
          }

          console.log('Patch Values:', patchValues);

          // Patch the form values
          this.form.patchValue(patchValues);

          // Update the model
          this.model = { ...this.model, ...patchValues };

          // Ensure change detection
          this.cdr.detectChanges();
        } else {
          console.warn('API response is unsuccessful or contains errors:', response.errors);
        }
      },
      error: (err) => {
        console.error('Error fetching service info:', err);
      },
    });
  }


  // Clear the selected services from the model
  private resetSelectedServices(): void {
    Object.keys(this.model)
      .filter((key) => key.startsWith('service_'))
      .forEach((key) => {
        this.model[key] = false; // Resest selected service to false
      });
    this.form.patchValue(this.model, { emitEvent: false });

    this.cdr.detectChanges();
  }

  populateFormWithServiceProviderDetails(res: any) {
    //  Patching the selected business type in (selectedCategory)
    if (res.businessType) {
      this.form.patchValue({
        selectedCategory: {
          label: res.businessType.name,
          value: res.businessType.businessTypeId,
        },
      });

      //Update services for the selected business type
      const businessType = this.serviceLists.find(
        (bt) => bt.value === res.businessType.businessTypeId
      );

      if (businessType) {
        this.updateServicesByCategory(businessType.services);
      }
    }

    //Patch the preselected services in the model
    if (res.serviceOffers && res.serviceOffers.length > 0) {
      res.serviceOffers.forEach((offer: any) => {
        // Set the checkbox for the service as true for (preselected)
        this.model[`service_${offer.serviceId}`] = true;
      });
    }

    //Patching the form with the updated model
    this.form.patchValue(this.model);
    this.cdr.detectChanges();

    //Setting the updated form data to the formStateService
    this.formStateService.setFormData('service-offered', this.model);
  }

}
