import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
    selector: 'app-custom-checkbox',
    template: `
    <div class="custom-checkbox" (click)="toggleCheckbox()" style='padding:8px'>
      <div class="checkbox-container" [ngClass]="{ 'checked': formControl.value }">
        <div class="checkbox-box">
          <div class="checkmark" *ngIf="formControl.value">
            <div class="checkmark-stem"></div>
            <div class="checkmark-kick"></div>
          </div>
        </div>
        <label>{{ to.label }}</label>
      </div>
    </div>
  `,
    styles: [`
    .custom-checkbox {
      cursor: pointer;
    }

    .checkbox-container {
      display: flex;
      align-items: center;
      border: 1px solid #d0d5dd;
      border-radius: 8px;
      padding: 16px; /* Adjust padding as needed */
      transition: border-color 0.3s ease, background-color 0.3s ease;
      height: 52px;
      width: 100%; 
    }

    .checkbox-container.checked {
      background-color: #FFF1E0; /* Background color when checked */
      border-color: #FFF1E0; /* Border color when checked */
    }

    .checkbox-box {
      width: 20px;
      height: 20px;
      border: 2px solid #d0d5dd;
      border-radius: 4px;
      background-color: #fff;
      position: relative;
      flex-shrink: 0;
      margin-right: 10px; /* Adjust margin between box and label */
    }

    .checkbox-box .checkmark {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 10px;
      height: 10px;
      border-bottom: 2px solid #fff;
      border-right: 2px solid #fff;
      opacity: 0;
      transition: opacity 0.3s ease;
    }

    .checkbox-container.checked .checkbox-box {
      background-color: #FFC680; /* Checkbox background color when checked */
      border-color: #FFC680; /* Checkbox border color when checked */
    }

    .checkbox-container.checked label {
      color: #AA5D00; /* Label text color when checked */
    }
    
  `]
})
export class CustomCheckboxComponent extends FieldType {
    toggleCheckbox() {
        const newValue = !this.formControl.value;
        this.formControl.patchValue(newValue);
    }
}
