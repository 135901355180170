import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SharedService } from '../../shared/shared.service';
import { environment } from '../../../../environments/environment.development';

@Injectable({
  providedIn: 'root'
})
export class OtpService {

  // api: string = 'http://admin.autocare.com:5107';
  api: string = environment.baseURL;

  constructor(private http: HttpClient, private sharedService: SharedService) { }

  sendOtp(phoneNumber: string, countryId: any): Observable<any> {
    return this.http.post(`${this.api}/api/OTP/request-otp`, { phoneNumber, countryId });
  }

  verifyOtp(phoneNumber: string, otp: string): Observable<any> {
    return this.http.post(`${this.api}/api/OTP/verify-otp`, { phoneNumber, otp });
  }

  resendOtp(phoneNumber: string): Observable<any> {
    return this.http.post(`${this.api}/api/OTP/resend-otp`, { phoneNumber });
  }
  private phoneNumber: string = '';

  setPhoneNumber(phone: string) {
    this.phoneNumber = phone;
  }

  getPhoneNumber(): string {
    return this.phoneNumber;
  }

  private otpVerificationData: { phoneNumber: string; tempRegId: string } | null = null;

  setOtpVerificationData(data: { phoneNumber: string; tempRegId: string }): void {
    this.otpVerificationData = data;
  }

  getOtpVerificationData(): { phoneNumber: string; tempRegId: string } | null {
    return this.otpVerificationData;
  }

  clearOtpVerificationData(): void {
    this.otpVerificationData = null;
  }

}
