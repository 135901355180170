<div class="p-col-3"
     style="text-align:right; margin-right: 10px; cursor: pointer;"
     (click)="navigateToSignUp()">
  <button type="button"
          class="add-button"
          pRipple>
    <!-- <i class="pi pi-user-plus" style="vertical-align: middle;"></i> -->
    <span style="margin-left: 8px;">Sign Up</span>
  </button>
</div>
<div id="comingsoon-heading">
  <div class="container">
    <div class="heading">COMING SOON</div>
    <div class="subheading">This website is under construction. Reach us <a
         href="mailto:info@autoecare.com">info&#64;autoecare.com</a></div>
  </div>
</div>