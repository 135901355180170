import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  //tempRegId: string='';

  constructor() { }
  private temporaryRegIdSubject = new BehaviorSubject<string>('');
  private phoneNumberSubject = new BehaviorSubject<string>('');

  // temporaryRegId$ = this.temporaryRegIdSubject.asObservable();
  // phoneNumber$ = this.phoneNumberSubject.asObservable();
  // Observables for components to subscribe to
  temporaryRegId$: Observable<string | null> = this.temporaryRegIdSubject.asObservable();
  phoneNumber$: Observable<string | null> = this.phoneNumberSubject.asObservable();

  setTemporaryRegId(id: string): void {
    this.temporaryRegIdSubject.next(id);
  }

  setPhoneNumber(phone: string): void {
    this.phoneNumberSubject.next(phone);
  }

  getTemporaryRegId(): string {
    return this.temporaryRegIdSubject.value;
  }

  getPhoneNumber(): string {
    return this.phoneNumberSubject.value;
  }


}
