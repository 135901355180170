import {
    Component,
    ChangeDetectionStrategy,
    ViewChild,
    ElementRef,
    OnInit,
  } from '@angular/core';
  import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
  import { ChangeDetectorRef } from '@angular/core';
  import { HttpEventType } from '@angular/common/http';
  import { MessageService } from 'primeng/api';
import { ServiceAdvisorFormStateService } from '../../services/formstate/service-advisor-form-stae-service.service';
import { UserService } from '../../user-service.service';
  
  
  @Component({
    selector: 'field-file-upload',
    template: `
      <!-- <p-toast position="bottom-center"></p-toast> -->  
      <div class="overflow-hidden mt-5">
        <div class="flex">
          <div class="col-4">
            <div style="font-size: 14px; font-weight: 700">{{ to.label }}</div>
            <div style="font-size: 14px; font-weight: 400">
              {{ to.placeholder }}
            </div>
          </div>
          <div class="flex col-8">
            <div
              class="file-upload"
              [ngClass]="{
                'file-upload-hover': isHovering,
                'file-selected': fileName
              }"
              (dragover)="onDragOver($event)"
              (dragleave)="onDragLeave($event)"
              (drop)="onDrop($event)"
              (click)="fileInput.click()"
            >
              <input
                type="file"
                #fileInput
                (change)="onFileSelect($event)"
                style="display: none;"
              />
              <ng-container *ngIf="!fileName && !isUploading">
                <i class="pi pi-cloud-upload" style="font-size: 2.5rem;"></i>
                <p class="top">
                  <span id="click">Click to upload</span> or drag and drop
                </p>
                <p class="svg">SVG, PNG, JPG (max. 800x400px)</p>
              </ng-container>
              <ng-container *ngIf="fileName">
                <div class="file-details">
                  <i
                    class="pi pi-file"
                    style="font-size: 1.5rem; margin-right: 10px; color: #ffa500;"
                  ></i>
                  <div class="file-info">
                    <span class="file-name">{{ fileName }}</span>
                    <span class="file-size">{{ fileSize }}</span>
                  </div>
                  <button (click)="removeFile($event)" class="remove-button">
                    <i
                      class="pi pi-trash"
                      style="font-size: 1rem; color: #b42318;"
                    ></i>
                  </button>
                </div>
                <div *ngIf="isUploading" class="progress-container">
                  <div
                    class="progress-bar"
                    [style.width.%]="uploadProgress"
                  ></div>
                  <span class="progress-text">{{ uploadProgress }}%</span>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    `,
    styles: [
      `
        .top {
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
        }
        #click {
          color: var(--Brand-700, #d47500);
        }
        .svg {
          color: var(--Gray-600, #475467);
          text-align: center;
          font-family: Inter;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
        }
        .file-upload {
          border: 2px dashed #e4e7ec;
          padding: 20px, 28px, 20px, 28px;
          text-align: center;
          cursor: pointer;
          transition: border-color 0.3s ease, height 0.3s ease;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          width: 464px;
          min-height: 120px; /* Initial minimum height */
          height: auto;
  
          &.file-selected {
            min-height: 100px;
            padding: 20px;
          }
        }
        .file-upload-hover {
          border-color: #ff9f2a;
        }
        .file-details {
          display: flex;
          align-items: center;
          width: 100%;
          justify-content: space-between;
        }
        .file-info {
          flex-grow: 1;
          margin-left: 10px;
        }
        .file-name {
          font-size: 14px;
          font-weight: 600;
          color: #344054;
        }
        .file-size {
          display: block;
          font-size: 12px;
          color: #666;
        }
        .remove-button {
          background: none;
          border: none;
          cursor: pointer;
        }
        .progress-container {
          width: 100%;
          background-color: #f1f1f1;
          border-radius: 8px;
          overflow: hidden;
          position: relative;
          height: 10px;
          margin-top: 10px;
        }
        .progress-bar {
          background-color: #ff8c00;
          height: 100%;
          transition: width 0.3s ease;
        }
        .progress-text {
          position: absolute;
          right: 10px;
          top: -20px;
          font-size: 12px;
          color: #344054;
        }
      `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
  })
  export class FieldFileUploadComponent
    extends FieldType<FieldTypeConfig>
    implements OnInit {
    @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;
    isHovering = false;
    fileName: string | undefined;
    fileUrl: string | undefined;
    isUploading = false;
    uploadProgress = 0;
    fileSize: string = '';
  
    constructor(
      private cdr: ChangeDetectorRef,
      private fileService: ServiceAdvisorFormStateService,
      private api: UserService,
      private messageService: MessageService
    ) {
      super();
    }
  
    ngOnInit() {
      const key = this.field.key as string;
      const savedData = this.fileService.getFormData(key);
    
      console.log('Custom type initialized with saved data:', savedData);
    
      // Handle the case where the backend response has null values
      if (savedData && savedData.fileUrl && savedData.fileId) {
        // If valid file data exists
        this.fileName = savedData.fileName || `File #${savedData.fileId}`;
        this.fileUrl = savedData.fileUrl;
        this.formControl.setValue(savedData.fileId);
      } else {
        // No valid file data from the backend
        this.fileName = undefined;
        this.fileUrl = undefined;
        this.formControl.setValue(null);
      }
    
      this.cdr.detectChanges(); // Ensure the UI updates correctly
    }
    
    
  
    onFileSelect(event: Event) {
      const input = event.target as HTMLInputElement;
      if (input.files && input.files.length > 0) {
        const file = input.files[0];
        const key = this.field.key as string; // Use formly field key
        this.fileName = file.name;
        this.fileSize = (file.size / 1024).toFixed(2) + ' KB';
        this.fileUrl = URL.createObjectURL(file);
        this.formControl.setValue(file);
        this.fileService.setFormData(key, {
          file,
          fileName: this.fileName,
          fileUrl: this.fileUrl,
        });
        // Marking the file as uploaded to prevent backend overwriting
        this.fileService.markFileAsUploaded(key);
        // Calling the upload method for actual file upload
        this.uploadFile(file, key);
      }
    }
    
  
    onDragOver(event: DragEvent) {
      event.preventDefault();
      this.isHovering = true;
    }
  
    onDragLeave(event: DragEvent) {
      event.preventDefault();
      this.isHovering = false;
    }
  
    onDrop(event: DragEvent) {
      event.preventDefault();
      this.isHovering = false;
      const files = event.dataTransfer!.files;
      if (files.length > 0) {
        const file = files[0];
        const key = this.field.key as string; // Get the formly field key
        this.fileName = file.name;
        this.fileSize = (file.size / 1024).toFixed(2) + ' KB';
        this.fileUrl = URL.createObjectURL(file);
        this.formControl.setValue(file);
        this.fileService.setFormData(key, {
          file,
          fileName: this.fileName,
          fileUrl: this.fileUrl,
        });
        this.uploadFile(file, key);
      }
    }
  
    removeFile(event: Event) {
      event.stopPropagation();
      this.fileName = undefined;
      this.fileUrl = undefined;
      this.formControl.setValue(null);
      const key = this.field.key as string; // Use the formly field key
      this.fileService.setFormData(key, null);
      this.fileInput.nativeElement.value = '';
      this.fileService.setUploadedFileId(key, 0);
      this.cdr.detectChanges();
    }
    uploadFile(file: File, key: string) {
      const formData = new FormData();
      formData.append('File', file);
      formData.append('FileSourceId', '3');
  
      this.isUploading = true;
      this.uploadProgress = 0;
  
      this.api.uploadFile(formData).subscribe(
        (event) => {
          if (event.type === HttpEventType.UploadProgress && event.total) {
            this.uploadProgress = Math.round((100 * event.loaded) / event.total);
            this.cdr.detectChanges();
          } else if (event.type === HttpEventType.Response) {
            const fileId = event.body.fileId;
            if (fileId) {
              this.fileService.setUploadedFileId(key, fileId); // Store fileId using the specific key
            } else {
              console.error('File ID is missing in response');
            }
            this.isUploading = false;
            this.cdr.detectChanges();
          }
        },
        (error) => {
          console.error('Error uploading file', error);
          this.isUploading = false;
          this.cdr.detectChanges();
        }
      );
    }
    showCustomToast() {
      this.messageService.add({
        key: 'customToast',
        severity: 'success',
        summary: 'Service Seeker application was approved successfully',
        detail: 'Close',
      });
    }
  
    onCloseToast() {
      this.messageService.clear('customToast');
    }
  }
  