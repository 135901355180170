<!-- <div class="flex-container">
    <div class="sidebar">
      <div class="menu-item">
        <i class="pi pi-map-marker icon"></i>
        <span class="menu-text">Location</span>
      </div>
    </div>
</div> -->

<div class="overlay">
 <!-- Only show LocationDetailsComponent for map-location or address-proof -->
<div *ngIf="shouldShowLocationDetails()" class="overlay">
  <div class="modal" style="width: 100%; height: 100%; overflow-y: auto" >
    <div class="flex-container">
      <div class="sidebar">
        <ul>
          <li>
            <a (click)="switchView('map-location')" [ngClass]="{ active: currentView === 'map-location' }">
              <i class="pi pi-map-marker"></i> Location
            </a>
          </li>
          <li>
            <a (click)="switchView('address-proof')" [ngClass]="{ active: currentView === 'address-proof' }">
              <i class="pi pi-file-plus"></i> Address Proof
            </a>
          </li>
        </ul>
      </div>

      <div class="main-content">
        <app-map-location
          *ngIf="currentView === 'map-location'"
          (changeSection)="switchView($event)"
        ></app-map-location>

        <app-address-proof
          *ngIf="currentView === 'address-proof'"
          (hideLocationDetails)="switchView($event)"
        ></app-address-proof>
      </div>
    </div>
  </div>
</div>

<!-- Render WelcomeDashboard -->
<app-welcome-dashboard *ngIf="currentView === 'welcome'"></app-welcome-dashboard>

<!-- Render CaptureBusinessLocation -->
<app-capture-business-location
  *ngIf="currentView === 'capture-business-location'"
></app-capture-business-location>
