<!-- <div class="flex-container">
    <div class="sidebar">
      <div class="menu-item">
        <i class="pi pi-map-marker icon"></i>
        <span class="menu-text">Location</span>
      </div>
    </div>
</div> -->

<div class="overlay">
  <div class="modal" style="width: 100%; height: 100%; overflow-y: auto">
      <div class="flex" style=" overflow-y: auto">
          <div class="sidebar">
            <ul>
              <li>
                <a (click)="setActiveSection('map-location')" [ngClass]="{
                    active: activeSection === 'map-location'
                  }">
                  <i class="pi pi-map-marker"></i>Location
                </a>
              </li>
              <li>
                <a (click)="setActiveSection('address-proof')" [ngClass]="{ active: activeSection === 'address-proof' }">
                  <i class="pi pi-file-plus"></i>address-proof
                </a>
              </li>
              
            </ul>
          </div>
      </div>
  </div>
</div>