import {
  Component,
  EventEmitter,
  OnInit,
  OnDestroy,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormStateService } from '../form-state.service';
import { ServiceProviderService } from '../../services/serviceProvider/service-provider.service';
import { Router } from '@angular/router';
import { ToastService } from '../../../toastr/toastr_service/service.service';
//import { ToastService } from '../../../../Service/ToastService/toast.service';

@Component({
  selector: 'app-technician-info',
  templateUrl: './technician-info.component.html',
  styleUrls: ['./technician-info.component.scss'],
})
export class TechnicianInfoComponent implements OnInit {
  form = new FormGroup({});
  @Output() nextSection = new EventEmitter<void>();
  @Output() closeDialog = new EventEmitter<void>(); // New event for closing AddSpComponent

  model: { [key: string]: any } = {};
  fields: FormlyFieldConfig[] = [];
  isFormPopulated = false;
  serviceProviderId: any; // New property to hold serviceProviderId
  isLoading!: boolean;
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() updateServiceProviderList: EventEmitter<void> = new EventEmitter<void>();
  @Output() isValid = new EventEmitter<boolean>();
  @Output() onFinish = new EventEmitter<void>();


  constructor(
    private api: ServiceProviderService,
    private formStateService: FormStateService,
    private router: Router,
    private toastService: ToastService,
  ) { }

  ngOnInit() {
    // Add the statusChanges subscription here
    this.form.statusChanges.subscribe(() => {
      this.isValid.emit(this.form.valid);
    });
    this.loadTechnicians();
    this.initializeFormData();
    // Get the serviceProviderId and store it locally
    this.serviceProviderId = this.formStateService.getServiceProviderId();
  }


  populateFormWithServiceProviderDetails(res: any) {
    if (!this.isFormPopulated) {
      this.model = {};

      res.serviceProviderTechnicians?.forEach((technician: any) => {
        this.model[technician.technicianId.toString()] = technician.noOfTechnician;
      });

      this.form.patchValue(this.model);
      this.isFormPopulated = true;
      this.formStateService.setFormData('Technician-info', this.model);
    }
  }

  loadTechnicians() {
    this.api.getTechnicianList('').subscribe(
      (response) => {
        this.fields = response.map((technician: any) => ({
          key: technician.technicianId.toString(),
          type: 'input-number',
          templateOptions: {
            label: technician.name,
            type: 'input-number',
            defaultValue: 0,
          },
        }));
        this.updateFormlyFields();
      },
      (error) => {
        console.error('Error fetching technicians', error);
      }
    );
  }

  resetForm() {
    this.form.reset();
    this.model = {};
  }

  initializeFormData() {
    const temporaryRegId = localStorage.getItem('temporaryRegId') || '';
    const phoneNumber = localStorage.getItem('phoneNumber') || '';

    if (!temporaryRegId || !phoneNumber) {
      console.error('TemporaryRegId or PhoneNumber missing in local storage.');
      return;
    }

    this.api.getTechnicianInfo(temporaryRegId, phoneNumber).subscribe({
      next: (response) => {
        if (response.isSuccess && response.result) {
          console.log('Fetched Technician Info:', response.result);

          // Map API response to the model
          response.result.forEach((technician: any) => {
            this.model[technician.technicianId.toString()] = technician.noOfTechnician;
          });

          // Patch the model to the form
          this.form.patchValue(this.model);

          console.log('Patched Model Data:', this.model);
          this.isFormPopulated = true;
        } else {
          console.warn('API response is unsuccessful or contains errors:', response.errors);
        }
      },
      error: (err) => {
        console.error('Error fetching technician info:', err);
      },
    });
  }


  updateFormlyFields() {
    this.fields.forEach((field) => {
      const key = field.key!.toString();
      field.defaultValue = this.model[key] || 0;
    });
  }

  get totalTechnicians(): number {
    let total = 0;
    for (const field of this.fields) {
      const key = field.key!.toString();
      if (this.model[key]) {
        total += +this.model[key];
      }
    }
    return total;
  }

  onSubmit() {
    if (this.form.valid) {
      const temporaryRegId = localStorage.getItem('temporaryRegId') || '';
      const phoneNumber = localStorage.getItem('phoneNumber') || '';

      if (!temporaryRegId || !phoneNumber) {
        console.error('TemporaryRegId or PhoneNumber missing in local storage.');
        return;
      }

      // Prepare the request body for the first API call
      const technicianRequestBody = {
        temporaryRegId,
        phoneNumber,
        technicians: Object.keys(this.model).map((key) => ({
          technicianId: Number(key),
          noOfTechnician: this.model[key] || 0,
        })),
      };

      console.log('Submitting Technician Info:', technicianRequestBody);

      // Call the first API
      this.api.postTechnicianInfo(technicianRequestBody).subscribe({
        next: (response) => {
          if (response.isSuccess) {
            console.log('Technician Info submitted successfully:', response);

            // Prepare the request body for the second API call
            const providerRequestBody = {
              temporaryRegId,
              phoneNumber,
            };

            console.log('Submitting Provider Info:', providerRequestBody);
            this.toastService.showToast('Technicia details saved successfully!', 'success')
            // Emit event to close the dialog
            this.closeDialog.emit();
            this.onFinish.emit();

            // Call the second API
            // this.api.postProviderInfo(providerRequestBody).subscribe({
            //   next: (providerResponse) => {
            //     if (providerResponse.isSuccess) {
            //       console.log('Provider Info submitted successfully:', providerResponse);

            //       // Remove temporaryRegId and phoneNumber from localStorage
            //       localStorage.removeItem('temporaryRegId');
            //       localStorage.removeItem('phoneNumber');

            //       // Navigate to the next section
            //     } else {
            //       console.error('Failed to submit provider info:', providerResponse.errors);
            //     }
            //   },
            //   error: (err) => {
            //     console.error('Error submitting provider info:', err);
            //   },
            // });
          } else {
            this.toastService.showToast('Failed to save technician details', 'error')
            console.error('Failed to submit technician info:', response.errors);
          }
        },
        error: (err) => {
          console.error('Error submitting technician info:', err);
        },
      });
    } else {
      console.warn('Form is invalid. Please check all required fields.');
    }
  }



  saveFormData() {
    const formData = { ...this.model };
    this.formStateService.setFormData('Technician-info', formData);
    console.log('Form data saved:', formData);
  }

  update() {
    if (this.form.valid) {
      this.formStateService.setTechnicianCount(this.model);

      // Get the combined data after setting the technician count
      const combinedData = this.formStateService.getCombinedData();
      console.log('Combined Data:', combinedData);
      this.api.updateServiceProviderInfo(this.serviceProviderId, combinedData).subscribe(
        (response) => {
          this.isLoading = false;
          //this.toastService.showToast('Service Provider updated successfully', 'success');
          this.close.emit();
          this.updateServiceProviderList.emit();
        },
        (error) => {
          this.isLoading = false;
          //this.toastService.showToast('An error occurred while updating. Please try again.', 'error');
        }
      );
    }
  }

}

