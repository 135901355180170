import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { OtpService } from '../services/otpservice/otp.service';
import { ServiceProviderService } from '../services/serviceProvider/service-provider.service';
import { ToastService } from '../../toastr/toastr_service/service.service';
import { catchError, map, of, Subscription } from 'rxjs';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  form = new FormGroup({});
  model: any = {};
  options: FormlyFormOptions = {};
  isLoading = false;
  currentView: 'signup' | 'welcome' | 'captureBusinessLocation' = 'signup';

  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef,
    private userDataService: OtpService,
    private spServices: ServiceProviderService,
    private toast: ToastService
  ) { }

  ngOnInit() {
    // Attempt to retrieve OTP data from the service
    let otpData = this.userDataService.getOtpVerificationData();

    if (!otpData) {
      // Fallback to localStorage if otpData is not available
      const phoneNumber = localStorage.getItem('phoneNumber');
      const tempRegId = localStorage.getItem('temporaryRegId');

      if (phoneNumber && tempRegId) {
        otpData = { phoneNumber, tempRegId };
      }
    }

    if (otpData) {
      this.model.phone = otpData.phoneNumber;
      this.model.tempRegId = otpData.tempRegId;

      // Fetch basic info from the API and patch the form
      this.fetchBasicInfo(otpData.tempRegId, otpData.phoneNumber);

      // Fetch service provider types
      this.fetchServiceProviderTypes();
    } else {
      // Show error message but do not redirect
      const message = 'Please verify your phone number first.';
      this.toast.showToast(message, 'error');
      console.warn(message);
    }
  }

  // Fetch basic info from the backend
  fetchBasicInfo(tempRegId: string, phoneNumber: string) {
    this.isLoading = true;

    this.spServices.getBasicInfo(tempRegId, phoneNumber).subscribe({
      next: (response) => {
        this.isLoading = false;

        if (response.isSuccess && response.result) {
          const data = response.result;
          console.log('Fetched data from backend:', data);

          // Save serviceProviderTypeId and serviceProviderSubTypeId
          const serviceProviderTypeId = data.serviceProviderTypeId;
          const serviceProviderSubTypeId = data.serviceProviderSubTypeId;

          // Patch the model
          this.model = {
            ...this.model,
            name: data.name || '',
            email: data.emailAddress || '',
            password: data.password || '',
            confirmPassword: data.password || '', // Patch confirmPassword with password
            couponCode: data.couponCode || '',
            serviceProviderTypeAndSubType: {
              serviceProviderTypeId,
              serviceProviderSubTypeId,
            },
          };

          // Ensure dropdown is updated
          this.patchServiceProviderDropdown(serviceProviderTypeId, serviceProviderSubTypeId);

          // Save the phoneNumber and tempRegId to localStorage (if updated)
          localStorage.setItem('phoneNumber', this.model.phone);
          localStorage.setItem('temporaryRegId', this.model.tempRegId);

          this.cdr.detectChanges();
        } else {
          console.warn('No data found for the given TemporaryRegId and PhoneNumber.');
        }
      },
      error: (err) => {
        this.isLoading = false;
        console.error('Failed to fetch basic info:', err);
      },
    });
  }

  patchServiceProviderDropdown(serviceProviderTypeId: number, serviceProviderSubTypeId: number) {
    this.spServices.getServiceProviderTypes().subscribe({
      next: (serviceProviderTypes) => {
        const selectedType = serviceProviderTypes.find(
          (type) => type.serviceProviderTypeId === serviceProviderTypeId
        );

        if (selectedType) {
          const selectedSubType = selectedType.serviceProviderSubType.find(
            (subType: any) => subType.serviceProviderSubTypeId === serviceProviderSubTypeId
          );

          if (selectedSubType) {
            // Patch the dropdown value with the correct selection
            this.model.serviceProviderTypeAndSubType = {
              serviceProviderTypeId,
              serviceProviderSubTypeId,
            };
            this.cdr.detectChanges();
            console.log('Patched Dropdown Value:', this.model.serviceProviderTypeAndSubType);
            this.cdr.detectChanges();
          } else {
            console.warn('SubType not found for ID:', serviceProviderSubTypeId);
          }
        } else {
          console.warn('Type not found for ID:', serviceProviderTypeId);
        }
      },
      error: (err) => {
        console.error('Error fetching service provider types:', err);
      },
    });
  }


  onSubmit() {
    if (this.form.valid) {
      console.log('Form Submitted:', this.model);
      this.isLoading = true;

      const selected = this.model.serviceProviderTypeAndSubType;

      const payload = {
        temporaryRegId: this.model.tempRegId,
        name: this.model.name,
        emailAddress: this.model.email,
        phoneNumber: this.model.phone,
        password: this.model.password,
        serviceProviderTypeId: selected.serviceProviderTypeId,
        serviceProviderSubTypeId: selected.serviceProviderSubTypeId,
        couponCode: this.model.couponCode || '',
      };

      console.log('Payload:', payload);

      this.spServices.signUp(payload).subscribe({
        next: (response) => {
          this.isLoading = false;
          if (response.isSuccess) {
            console.log('Signup Successful:', response);

            // Store selected service provider type and subtype in local storage
            localStorage.setItem('serviceProviderTypeId', String(selected.serviceProviderTypeId));
            localStorage.setItem('serviceProviderSubTypeId', String(selected.serviceProviderSubTypeId));

            // Update the view based on Sub-Type ID
            if (payload.serviceProviderSubTypeId === 11) {
              this.currentView = 'welcome';
            } else {
              this.currentView = 'captureBusinessLocation';
            }
          } else {
            console.error('Signup Failed:', response.errors);
          }
        },
        error: (err) => {
          this.isLoading = false;
          console.error('API Error:', err);
        },
      });
    } else {
      console.log('Form is invalid');
    }
  }


  fetchServiceProviderTypes() {
    this.spServices.getServiceProviderTypes().subscribe({
      next: (response) => {
        this.updateServiceProviderDropdown(response);
      },
      error: (err) => {
        console.error('Error fetching service provider types:', err);
      },
    });
  }
  updateServiceProviderDropdown(data: any[]) {
    const options = data.map((group) => ({
      label: group.name,
      items: group.serviceProviderSubType.map((subType: any) => ({
        label: subType.name,
        value: subType.serviceProviderSubTypeId,
      })),
    }));

    const serviceProviderField = this.fields.find((field) => field.key === 'serviceProviderType');
    if (serviceProviderField) {
      serviceProviderField.templateOptions = {
        ...serviceProviderField.templateOptions,
        options$: this.spServices.getServiceProviderTypes(),
      };
    }

    this.cdr.detectChanges();
  }

  fields: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'p-fluid p-formgrid p-grid',
      fieldGroup: [
        {
          key: 'name',
          type: 'input',
          templateOptions: {
            label: 'Name',
            placeholder: 'Enter name',
            required: true,
            hideRequiredMarker: true,
          },
        },
        {
          template: `<div class="mt-3"></div>`,
        },
        {
          key: 'phone',
          type: 'input',
          templateOptions: {
            label: 'Phone number',
            placeholder: 'Enter number',
            type: 'tel',
            disabled: true,
          },
        },
        {
          template: `<div class="mt-3"></div>`,
        },
        {
          key: 'email',
          type: 'input',
          templateOptions: {
            type: 'email',
            label: 'Email',
            placeholder: 'Enter email',
            required: true,
            hideRequiredMarker: true,
          },
          validators: {
            customEmail: {
              expression: (control: any) =>
                /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(control.value),
              message: 'Please enter a valid email address.',
            },

          }
        },
        {
          template: `<div class="mt-3"></div>`,
        },
        {
          key: 'password',
          type: 'input',
          templateOptions: {
            type: 'password',
            label: 'Password',
            placeholder: 'Enter password',
            required: true,
            minLength: 6,
            hideRequiredMarker: true,
          },
        },
        {
          template: `<div class="mt-3"></div>`,
        },
        {
          key: 'confirmPassword',
          type: 'input',
          templateOptions: {
            type: 'password',
            label: 'Confirm password',
            placeholder: 'Enter password again',
            required: true,
            hideRequiredMarker: true,
          },
          validators: {
            fieldMatch: {
              expression: (control: any) =>
                !control.parent || control.value === control.parent.get('password')?.value,
              message: 'Passwords do not match.',
            },
          },
        },
        {
          template: `<div class="mt-3"></div>`,
        },
        {
          key: 'serviceProviderTypeAndSubType',
          type: 'custom-dropdown',
          templateOptions: {
            label: 'Service Provider',
            placeholder: 'Select a service provider',
            required: true,
            options$: this.spServices.getServiceProviderTypes().pipe(
              map((response) => {
                return response.map((group) => ({
                  label: group.name,
                  items: group.serviceProviderSubType.map((subType: any) => ({
                    label: subType.name,
                    value: {
                      serviceProviderTypeId: group.serviceProviderTypeId,
                      serviceProviderSubTypeId: subType.serviceProviderSubTypeId,
                    },
                  })),
                }));
              }),
              catchError((error) => {
                console.error('Error fetching service provider types:', error);
                return of([]); // Return an empty array on error
              })
            ),
          },
          defaultValue: { serviceProviderTypeId: null, serviceProviderSubTypeId: null },
        },
        {
          template: `<div class="mt-3"></div>`,
        },
        {
          key: 'couponCode',
          type: 'input',
          templateOptions: {
            label: 'Coupon code (Optional)',
            placeholder: 'Enter coupon code',
            required: false,
          },
        },
      ],
    },
  ];
}
