import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormStateService } from '../../../services/formstate/form-stae-service.service';
import { ServiceProviderService } from '../../../services/serviceProvider/service-provider.service';
import { ToastService } from '../../../../toastr/toastr_service/service.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment.development';
declare var Razorpay: any;


@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrl: './payment.component.scss'
})
export class PaymentComponent {
  @Input() selectedPlan: any;
  @Input() form!: FormGroup;
  @Output() updateServiceProviderList: EventEmitter<void> = new EventEmitter<void>();
  @Output() close: EventEmitter<any> = new EventEmitter();

  taxableAmount: number = 0;
  totalCost: number = 0;
  paymentMethod: string = '';
  taxRate: number = 0.05;
  isLoading = false;
  showPaymentOptions: boolean = false;
  paymentSuccessful: boolean = false;
  ownerName: string = '';
  ownerEmail: string = '';
  ownerPhone: string = '';

  constructor(
    private formStateService: FormStateService,
    private api: ServiceProviderService,
    private toastService: ToastService,
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    this.calculateTotal();

    const savedPlan = this.formStateService.getSelectedPlan();
    if (savedPlan) {
      this.selectedPlan = savedPlan;
      this.calculateTotal();
    }
  }

  // Calculate the total cost (plan price + tax)
  calculateTotal(): void {
    if (this.selectedPlan) {
      this.taxableAmount = this.selectedPlan.price;
      this.totalCost = this.taxableAmount + this.taxableAmount * this.taxRate;
    }
  }

  // Trigger when the user selects a payment method
  selectPaymentMethod(method: string): void {
    this.paymentMethod = method;
    if (method === 'offline') {
      this.completePayment(); // Complete payment immediately for offline
    } else if (method === 'online') {
      this.showPaymentOptions = true; // Show Razorpay modal for online payment
    }
  }

  // Initiate Razorpay payment for online payment method
  initiateRazorpayPayment(): void {
    this.isLoading = true; // Disable the Pay Now button

    // Retrieve the phone number from local storage
    const phoneNumber = localStorage.getItem('phoneNumber') || '';

    // API request payload
    const payload = {
      paymentAmount: this.totalCost, // Pass the total cost
      phoneNumber: phoneNumber,      // Phone number from local storage
      isOnlinePayment: true,         // Indicating online payment
    };
  
    this.http.post(`${environment.baseURL}/api/Payment/create-order`, payload)
      .subscribe(
        (order: any) => {
          const razorpayOptions = {
            key: 'rzp_test_aLY35P49OeTQNl', // Razorpay test key
            amount: this.totalCost * 100,   // Amount in paise
            currency: 'INR',
            name: 'AutoCare',
            description: 'Payment for selected plan',
            order_id: order.id,
            handler: (response: any) => {
              console.log('Payment successful', response);
              this.verifyPayment(response.razorpay_payment_id); // Verify payment after success
            },
            prefill: {
              name: this.ownerName,
              email: this.ownerEmail,
              contact: this.ownerPhone,
            },
            theme: {
              color: '#3399cc',
            },
          };
          const rzp = new Razorpay(razorpayOptions);
          rzp.open();
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
          this.toastService.showToast('Payment initiation failed. Please try again.', 'error');
          this.showPaymentOptions = false;
        }
      );
  }


  // Verify payment using backend API
  verifyPayment(paymentId: string): void {
    this.api.verifyPayment(paymentId).subscribe(
      (verifyResponse: any) => {
        if (verifyResponse.isSuccess && verifyResponse.result.status === 'captured') {
          this.toastService.showToast('Payment verified successfully', 'success');
          this.paymentSuccessful = true;
          this.showPaymentOptions = false;

          // Complete the payment after successful verification
          this.completePayment();
        } else {
          this.toastService.showToast('Payment verification failed', 'error');
        }
      },
      (error) => {
        this.toastService.showToast('Payment verification failed. Please try again.', 'error');
      }
    );
  }

  // Complete payment (for both offline and verified online payments)
  completePayment(): void {
    this.isLoading = true;
    const combinedData = this.formStateService.getCombinedData();

    this.api.createServiceProvider(combinedData).subscribe(
      (response) => {
        this.isLoading = false;
        this.toastService.showToast('Service Provider added successfully', 'success');
        this.finalizePayment();
      },
      (error) => {
        this.isLoading = false;
        this.toastService.showToast('An error occurred. Please try again.', 'error');
      }
    );
  }

  // Finalize payment and close the modal
  finalizePayment(): void {
    this.paymentSuccessful = true;
    this.close.emit(); // Close the payment modal
    this.updateServiceProviderList.emit(); // Refresh the service provider list
  }

  // Handle payment failure and retry option
  handlePaymentFailure(): void {
    this.toastService.showToast('Payment failed. Please try again.', 'error');
    this.isLoading = false;
    this.showPaymentOptions = true; // Allow retry
  }
}
