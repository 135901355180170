import { AbstractControl } from '@angular/forms';

export function noUppercase(control: AbstractControl) {
    const value: string = control.value;

    if (/[A-Z]/.test(value)) {
        return { noUppercase: true };
    }

    return { noUppercase: { message: 'Uppercase not allowed' } };

}
