import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormStateService } from '../form-state.service';
import { ServiceProviderService } from '../../services/serviceProvider/service-provider.service';
import { ActivatedRoute } from '@angular/router';
import { ToastService } from '../../../toastr/toastr_service/service.service';

@Component({
  selector: 'app-business-details-new',
  templateUrl: './business-details-new.component.html',
  styleUrls: ['./business-details-new.component.scss'],
})
export class BusinessDetailsNewComponent
  implements OnInit {
  form = new FormGroup({});
  // model: any = {};
  model: any = {
    name: '',
    applicantName: '',
    applicantEmailAddress: '',
    address: '',
    stateId: '',
    countryId: '',
    yearOfEstablishment: '',
    gstNumber: '',
    vatNumber: '',
    city: '',
    zip: '',
    addressProof: '',
    certificateOfIncorporation: '',
    galleryIds: '',
    businessTypeId: '',
    averageCustomer: '',
  };
  fields: FormlyFieldConfig[] = [];
  selectedBusinessType!: string;
  @Output() isValid = new EventEmitter<boolean>();
  @Output() nextSection = new EventEmitter<void>();
  @Input() countryId!: string;
  @Output() clearForm = new EventEmitter<void>();
  @Output() editServiceProviderList: EventEmitter<void> =
    new EventEmitter<void>();
  businessTypes = [];
  receivedObject: any;
  @Output() onFinish = new EventEmitter<void>();

  constructor(
    private fb: FormBuilder,
    private formStateService1: FormStateService,
    private api: ServiceProviderService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private toastService: ToastService
  ) { }
  countryName!: string;

  ngOnInit() {
    console.log('BusinessDetailsNewComponent initialized');
    this.route.params.subscribe(() => {
      this.loadFormData(); // Ensure API call is triggered when params change
      this.cdr.detectChanges();
    });

    // Subscribe to the form's validity changes
    this.form.statusChanges.subscribe(() => {
      this.isValid.emit(this.form.valid);
    });
    // Subscribe to the selected business type from the FormStateService
    this.formStateService1.selectedBusinessType$.subscribe(
      (businessType: any) => {
        if (businessType) {
          this.selectedBusinessType = businessType;
          this.form.patchValue({
            businessTypeId: businessType.businessTypeId,
          });

          this.model.businessTypeId = businessType.businessTypeId;
        }
      }
    );
    this.api.getBusinessTypeList('').subscribe((businessTypes: any) => {
      this.businessTypes = businessTypes || [];
      this.formStateService1.setBusinessTypes(businessTypes);
      this.initializeFields();
    });
  }

  initializeFields() {
    const businessTypeOptions = (this.businessTypes || []).map((x: any) => ({
      value: x.businessTypeId,
      label: x.name,
    }
    )
    );
    this.fields = [
      {
        className: 'field col-12',
        template: '<h3 style="mt-0">Basics</h3>',
      },
      {
        fieldGroupClassName: 'p-fluid p-formgrid p-grid',
        fieldGroup: [
          {
            fieldGroupClassName: 'field grid p-fluid',
            fieldGroup: [
              {
                className: 'field col',
                type: 'input',
                key: 'name', // Matches `businessName` in API
                templateOptions: {
                  label: 'Name of the Business',
                  placeholder: 'Enter Name',
                  required: true,
                },
              },
              {
                className: 'field col',
                key: 'yearOfEstablishment', // Matches `yearOfCommencement` in API
                type: 'select',
                templateOptions: {
                  placeholder: 'Select',
                  label: 'Years Of Commencement',
                  options: [
                    { label: '2024', value: '2024' },
                    { label: '2023', value: '2023' },
                    { label: '2022', value: '2022' },
                  ],
                },
                defaultValue: '',
              },
            ],
          },
          {
            template: `<div class="mt-1"></div>`,
          },
          {
            fieldGroupClassName: 'field grid p-fluid',
            fieldGroup: [
              {
                className: 'field col-6',
                key: 'businessTypeId',
                type: 'select',
                templateOptions: {
                  label: 'Business Type',
                  placeholder: 'Select business type',
                  options: businessTypeOptions,
                  required: true,
                },
                hooks: {
                  onInit: (field) => {
                    field.formControl?.valueChanges.subscribe((value: any) => {
                      this.onBusinessTypeChange(value);
                    });
                  },
                },
              },
              {
                className: 'field col',
                key: 'averageCustomer',
                type: 'select',
                props: {
                  placeholder: 'Select',
                  label: 'Average Customers',
                  options: [
                    { label: '0-100', value: '0-100' },
                    { label: '100-500', value: '100-500' },
                    { label: '500-1000', value: '500-1000' },
                    { label: 'More than 1000', value: 'More than 1000' },
                  ],
                },
              },
            ],
          },
          {
            template: `<div class="mt-0"></div>`,
          },
          // {
          //   template: `<div class="mt-3"></div>`,
          // },
          {
            className: 'field col-12',
            template: '<h3>Others</h3>',
          },
          {
            type: 'input',
            key: 'gstNumber',
            templateOptions: {
              label: 'GST Number',
              placeholder: 'Enter GST Number',
              required: true,
            },
            validators: {
              gst: {
                expression: (c: AbstractControl) =>
                  /^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[Z]{1}[0-9a-zA-Z]{1}$/.test(
                    c.value
                  ),
                message: (error: any, field: FormlyFieldConfig) =>
                  `${field.formControl?.value} is not a valid GST Number`,
              },
            },
          },
          {
            type: 'input',
            key: 'vatNumber',
            templateOptions: {
              label: 'VAT Number',
              placeholder: 'Enter VAT Number',
              required: true,
            },
            expressions: {
              hide: 'model.countryId!==2',
            },
            validators: {
              vat: {
                expression: (c: AbstractControl) => /^\d{15}$/.test(c.value),
                message: (error: any, field: FormlyFieldConfig) =>
                  `${field.formControl?.value} is not a valid VAT Number`,
              },
            },
          },
          {
            template: `<div class="mt-2"></div>`,
          },
          {
            template: `<div class="mt-3 mb-3"><strong>Front view photo of business/shop</strong></div>`,
          },
          {
            key: 'galleryIds',
            type: 'multifile',
            templateOptions: {
              placeholder: 'Upload',
              required: true,
            },
            defaultValue: [],
          },
          {
            template: `<div class="mt-3"><strong>Certificate of incorporation</strong></div>`,
          },
          {
            template: `<div class="mt-3"></div>`,
          },
          {
            key: 'commercialRegistrationNumber',
            type: 'input',
            templateOptions: {
              placeholder: 'Enter Commercial Registration Number',
              required: true,
            },
            expressions: {
              hide: 'model.countryId!==2',
            },
          },
          {
            template: `<div class="mt-3"></div>`,
          },
          {
            // dType = certificateofincorporation
            key: 'certificateOfIncorporation',
            type: 'file',
            templateOptions: {
              placeholder: 'Upload',
              required: true,
            },
          },
          {
            template: `<div class="mt-3 mb-3"><strong>Application submitted by</strong></div>`,
          },
          {
            fieldGroupClassName: 'field grid p-fluid',
            fieldGroup: [
              {
                className: 'field col mt-2',
                key: 'applicantName',
                type: 'input',
                templateOptions: {
                  label: 'Name',
                  placeholder: 'Enter Name',
                  required: true,
                },
              },
              {
                className: 'field col mt-2',
                key: 'applicantEmailAddress',
                type: 'input',
                templateOptions: {
                  label: 'Email',
                  placeholder: 'Enter Email',
                  required: true,
                },
                validators: {
                  email: {
                    expression: (c: AbstractControl) =>
                      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
                        c.value
                      ),
                    message: (error: any, field: FormlyFieldConfig) =>
                      `${field.formControl?.value} is not a valid Email Address`,
                  },
                },
              },
            ],
          },
        ],
      },
    ];
  }

  findFieldByKey(
    fields: FormlyFieldConfig[],
    key: string
  ): FormlyFieldConfig | null {
    for (const field of fields) {
      if (field.key === key) {
        return field;
      }
      if (field.fieldGroup && field.fieldGroup.length > 0) {
        const nestedField = this.findFieldByKey(
          field.fieldGroup as FormlyFieldConfig[],
          key
        );
        if (nestedField) {
          return nestedField;
        }
      }
    }
    return null;
  }

  loadFormData() {
    const temporaryRegId = localStorage.getItem('temporaryRegId') || '';
    const phoneNumber = localStorage.getItem('phoneNumber') || '';

    if (!temporaryRegId || !phoneNumber) {
      console.error('TemporaryRegId or PhoneNumber missing in local storage.');
      return;
    }

    this.api.getBusinessInfo(temporaryRegId, phoneNumber).subscribe({
      next: (response) => {
        if (response.isSuccess && response.result) {
          const result = response.result;

          this.model = {
            ...this.model,
            name: result.businessName || '',
            yearOfEstablishment: result.yearOfCommencement?.toString() || '',
            applicantName: result.applicantName || '',
            applicantEmailAddress: result.applicantEmailAddress || '',
            gstNumber: result.gstNumber || '',
            vatNumber: result.vatNumber || '',
            businessTypeId: result.businessTypeId || '',
            averageCustomer: result.averageCustomer || '',
            certificateOfIncorporation: {
              id: result.certificateOfInCorporationFileId,
              path: result.certificateOfInCorporationFilePath,
            },
            galleryIds: result.frontView || [],
          };

          // Delay field initialization until data is ready
          this.initializeFields();
          this.form.patchValue(this.model);
          this.cdr.markForCheck();
        }
      },
      error: (err) => {
        console.error('Error fetching business info:', err);
      },
    });
  }

  onBusinessTypeChange(value: any) {
    const businessTypeField = this.findFieldByKey(
      this.fields,
      'businessTypeId'
    );
    if (businessTypeField) {
      this.api.getBusinessTypeList('').subscribe((businessTypes: any[]) => {
        const selectedType = businessTypes.find(
          (type) => type.businessTypeId === value
        );
        if (selectedType) {
          this.formStateService1.setSelectedBusinessType(selectedType);
        }
      });
    }
  }

  onSubmit() {
    if (this.form.valid) {
      const temporaryRegId = localStorage.getItem('temporaryRegId') || '';
      const phoneNumber = localStorage.getItem('phoneNumber') || '';

      if (!temporaryRegId || !phoneNumber) {
        console.error('TemporaryRegId or PhoneNumber missing in local storage.');
        return;
      }

      const transformedData = {
        ...this.transformFormData(this.form.value),
        temporaryRegId,
        phoneNumber,
      };

      this.api.postBusinessInfo(transformedData).subscribe({
        next: (response) => {
          if (response.isSuccess) {
            this.toastService.showToast('Data saved successfully!', 'success');
            console.log('Data successfully saved:', response);
            this.nextSection.emit();
            this.onFinish.emit();
          } else {
            console.error('Failed to save business info:', response.errors);
            this.toastService.showToast('Failed to save data.', 'error');
          }
        },
        error: (err) => {
          console.error('Error posting business info:', err);
          this.toastService.showToast('An error occurred while saving data.', 'error');
        },
      });
    } else {
      console.warn('Form is invalid. Please check all required fields.');
    }
  }

  transformFormData(formData: any): any {
    return {
      businessName: formData.name,
      applicantName: formData.applicantName,
      applicantEmailAddress: formData.applicantEmailAddress,
      yearOfCommencement: formData.yearOfEstablishment,
      gstNumber: formData.gstNumber || null,
      vatNumber: formData.vatNumber || null,
      averageCustomer: formData.averageCustomer,
      certificateOfInCorporationFileId: formData.certificateOfIncorporation?.id || null,
      frontViewFileIds: formData.galleryIds.map((file: any) => file.fileId),
      businessTypeId: formData.businessTypeId,
    };
  }
}
