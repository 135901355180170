import { Component, ViewChild, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { Calendar } from 'primeng/calendar';

@Component({
    selector: 'formly-custom-datepicker',
    template: `
    <div class="p-input-icon-right">
    <label for="buttondisplay">{{to.label}}</label>
      <p-calendar 
        #calendar
        [formControl]="formControl" 
        [formlyAttributes]="field"
        [(ngModel)]="date"
        [showIcon]="true"
        [iconDisplay]="'input'"
        placeholder="Select dates">
      </p-calendar>
</div>
  `,
    styles:`
    ::ng-deep .p-highlight {
       color: #D47500;
       background: #FFF1E0;
   }`
   
})
export class CustomDateTypeComponent extends FieldType<FieldTypeConfig> implements OnInit {
    @ViewChild('calendar') calendar!: Calendar;
    date!: Date;
    dateTime = new Date();

    ngOnInit() {
        this.initializeDate();
    }

    private initializeDate() {
        if (this.formControl.value) {
            const dateValue = new Date(this.formControl.value);
            this.date = dateValue;
            this.formControl.setValue(dateValue);
        }
    }
}
