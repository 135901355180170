<!-- <app-location-details></app-location-details> -->
<div class="flex justify-content-between flex-wrap" style="
padding-top: 10px;
padding-right: 2px;
padding-left: 82px;
border-bottom: 1px solid #f2f4f7;
">
<div class="img">
  <div class="flex justify-content-start flex-wrap">
    <div class="flex align-items-center justify-content-center mr-1">
      <img src="assets/image/Logomark.png" alt="Logo" class="svg-black" />
    </div>
    <div class="flex align-items-center justify-content-center">
      <p style="color: #1d2939; font-weight: 700; font-size: 14px">
        Auto E Care
      </p>
    </div>
  </div>
</div>
<!-- <div class="flex align-items-center justify-content-center">
  <p style="font-weight: 700; color: #333; font-size: 24px;">
    {{ editMode ? 'Add Service Provider Form' : 'Update Service Provider Form' }}
  </p>
</div> -->
<div class="align-items-center justify-content-center m-3">
  <i class="pi pi-times" style="cursor: pointer; font-size: 1em" (click)="close()"></i>
</div>
</div>
<div class="flex-container">
  <div class="main-content">
    <h1>Add Location Details</h1>
    <div class="input-container">
      <span class="p-input-icon-left search-box">
        <i class="pi pi-search"></i>
        <input type="text" pInputText placeholder="     Search" id="search-box" class="search-input" />
      </span>
      <span class="mx-2" style="color: #98A2B3; font-weight: bold;">or</span>

      <div class="gps-container" (click)="getCurrentLocation()">
        <i class="pi pi-compass icon"></i>
        <div class="text-container">
          <h3>Get current location</h3>
          <p>Using GPS</p>
        </div>
      </div>
    </div>
    <div class="address-map-container">
      <div style="display: flex; align-items: center;">
        <i class="pi pi-map-marker icon"></i>
        <div class="py-2">

          <h3 style="margin-top: 10px;">Address</h3>
          <p>{{ address }}</p>
        </div>
      </div>
      <div id="map" class="map"></div>
    </div>
    <button pButton color="primary" (click)="saveLocation()">Proceed</button>
  </div>
</div>