import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ServiceProviderService } from '../../services/serviceProvider/service-provider.service';
import { LocationService } from '../../service/location.service';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-address-proof',
  templateUrl: './address-proof.component.html',
  styleUrls: ['./address-proof.component.scss'],
})
export class AddressProofComponent implements OnInit {
  @Output() hideLocationDetails = new EventEmitter<string>();
  locationData: any = {};
  form = new FormGroup({});
  model: any = {};
  fields = [
    {
      key: 'addressProof',
      type: 'file',
      templateOptions: {
        placeholder: 'Upload',
        required: true,
        description: 'Supported file format: [add]',
        
      },
    },
  ];

  constructor(
    private locationDataService: LocationService,
    private serviceProviderService: ServiceProviderService
  ) {}

  ngOnInit() {
    this.locationData = this.locationDataService.getLocationData();
    if (!this.locationData) {
      alert('No location data available. Please select a location first.');
    }
  }

  onFinish(): void {
    if (this.form.valid) {
      const serviceProviderSubTypeId = localStorage.getItem('serviceProviderSubTypeId');

      // Validate and process the form
      const addressProof = this.model['addressProof'];
      const addressProofFileId = addressProof?.id || null;

      if (!addressProofFileId) {
        alert('Please upload a valid address proof.');
        return;
      }

      const payload = {
        longitude: this.locationData.longitude,
        latitude: this.locationData.latitude,
        address: this.locationData.address,
        city: this.locationData.city,
        state: this.locationData.state,
        zip: this.locationData.zip,
        countryId: this.locationData.countryId || 1,
        addressProofFileId,
        temporaryRegId: this.locationData.temporaryRegId,
        phoneNumber: this.locationData.phoneNumber,
      };

      this.serviceProviderService.postLocation(payload).subscribe({
        next: (response) => {
          if (response.isSuccess) {
            console.log('Location and Address Proof Submitted:', response);

            // Emit event to switch to the appropriate view
            if (serviceProviderSubTypeId === '11') {
              this.hideLocationDetails.emit('welcome'); // Switch to WelcomeDashboardComponent
            } else {
              this.hideLocationDetails.emit('capture-business-location'); // Switch to CaptureBusinessLocationComponent
            }
          } else {
            console.error('Error submitting data:', response.errors);
          }
        },
        error: (err) => {
          console.error('API Error:', err);
        },
      });
    } else {
      alert('Please complete the form.');
    }
  }
}
