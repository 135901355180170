<div>
  <p class="payment">Payment</p>
  <p class="details">Order details</p>
</div>

<div class="box">
  <div style="display: flex; align-items: center">
    <div class="plan-icon">
      <i class="pi pi-bolt" style="color: #ff8c00; font-size: 16px"></i>
    </div>
    <div class="plan-details">
      <p>{{ selectedPlan.name }}</p>
      <p class="supporting-text">{{ selectedPlan.description }}</p>
    </div>
    <div class="plan-price price">₹{{ selectedPlan.price }}</div>
  </div>
</div>
<div class="box">
  <div class="flex justify-content-start flex-wrap">
    <div class="flex align-items-center justify-content-center m-2">
      <input type="checkbox">
    </div>
    <div class="flex align-items-center justify-content-center m-2">
      <label for="additionalService1">
        One time Diag-Tool kit (No recurring payment)
        <br>
        <span class="supporting-text">Eliminate the manual way of diagnosing the car health</span>
      </label>

    </div>
  </div>
</div>
<div class="box">
  <div class="flex justify-content-start flex-wrap">
    <div class="flex align-items-center justify-content-center m-2">
      <input type="checkbox">
    </div>
    <div class="flex align-items-center justify-content-center m-2">
      <label for="additionalService1">
        Advertisement on own wall (1 change/month)
        <br>
        <span class="supporting-text">Promote your products on your page </span>
      </label>

    </div>
  </div>
</div>

<!-- Taxable amount and total cost section -->
<div class="amount-container mt-5">
  <div class="amount-row" style="border-bottom: 1px solid #0f0f0f33; padding-bottom: 24px">
    <div class="label total">Taxable amount :</div>
    <div class="value">₹{{ taxableAmount | number : "1.2-2" }}</div>
  </div>
  <div class="amount-row">
    <div class="label total">Tax(5%) :</div>
    <div class="value">₹{{ taxableAmount * taxRate | number : "1.2-2" }}</div>
  </div>
  <div class="amount-row">
    <div class="label total">Total :</div>
    <div class="value">₹{{ totalCost | number: "1.2-2" }}</div>
  </div>
</div>

<!-- Submit button to trigger payment modal -->
<button type="button" class="btn btn-primary mt-5" (click)="initiateRazorpayPayment()">
  Proceed to Payment
  <i class="pi pi-arrow-right arrow-icon"></i>
</button>

<!-- PrimeNG Dialog for Payment Options -->
<p-dialog header="Choose Payment Method" [(visible)]="showPaymentOptions" [modal]="true" [closable]="true"
  [style]="{ width: '30rem'}">
  <div class="p-fluid">
    <div class="field-radiobutton mt-3">
      <p-radioButton name="paymentMethod" value="offline" [(ngModel)]="paymentMethod"
        label="Pay to representative (offline)" (onClick)="selectPaymentMethod('offline')"></p-radioButton>
    </div>
    <div class="field-radiobutton">
      <p-radioButton name="paymentMethod" value="online" [(ngModel)]="paymentMethod" label="Pay via online"
        (onClick)="selectPaymentMethod('online')"></p-radioButton>
    </div>

    <!-- Online Payment button -->
    <div class="p-field mt-2" *ngIf="paymentMethod === 'online'">
      <button type="button" class="btn btn-primary" (click)="initiateRazorpayPayment()" [disabled]="isLoading">
        Pay Now
      </button>
    </div>

    <!-- Offline Payment button -->
    <div class="p-field mt-2" *ngIf="paymentMethod === 'offline'">
      <button type="button" class="btn btn-primary" (click)="completePayment()">Complete Offline Payment</button>
    </div>
  </div>
</p-dialog>

<!-- Loading overlay -->
<div *ngIf="isLoading" class="overlay">
  <div class="spinner">
    <div class="spinner-bar"></div>
  </div>
</div>