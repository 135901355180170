import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-custom-add-box',
  template: `
    <div class="custom-add-box" (click)="onClick($event)">
      <i class="fa fa-plus-circle add-icon"></i>
      <label>{{ to.label }}</label>
    </div>
  `,
  styles: [`
    .custom-add-box {
      display: flex;
      align-items: center;
      cursor: pointer;
      border: 2px dashed #d0d5dd;
      border-radius: 4px;
      padding: 8px;
      transition: background-color 0.3s ease;
      width: 100%;
      height: 40px;
      justify-content: center;
    }
    .custom-add-box:hover {
      background-color: #f0f0f0;
    }
    .add-icon {
      margin-right: 8px;
    }
  `]
})
export class CustomAddBoxComponent extends FieldType {
    onClick($event: Event) {
        if (this.props['onClick']) {
            this.props['onClick']($event);
        }
    }
}
