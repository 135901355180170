import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-location-details',
  templateUrl: './location-details.component.html',
  styleUrl: './location-details.component.scss'
})
export class LocationDetailsComponent {

  editMode: boolean = false;
  activeSection: string = 'map-location'; // Default section
  isVisible: boolean = true; // Control visibility of this component
  currentView: string = 'map-location'; // Default to map-location

  switchView(view: string): void {
    console.log('Switching view to:', view); // Debug log
    this.currentView = view;
  }

  setActiveSection(section: string): void {
    this.activeSection = section;
  }
  hideComponent(): void {
    this.isVisible = false; // Hide the component
    console.log('LocationDetailsComponent hidden');
  }
  shouldShowLocationDetails(): boolean {
    return this.currentView === 'map-location' || this.currentView === 'address-proof';
  }

}
