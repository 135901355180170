import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ToastService } from '../toastr/toastr_service/service.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private toastService: ToastService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    console.log('AuthGuard triggered for URL:', state.url); // Log attempted route
  
    const temporaryRegId = localStorage.getItem('temporaryRegId');
    const phoneNumber = localStorage.getItem('phoneNumber');
  
    if (temporaryRegId && phoneNumber) {
      return true; // Allow access
    } else {
      console.warn('Unauthorized access to:', state.url);
      localStorage.clear(); // Clear session
      this.router.navigate(['/otp']);
      return false; // Block access
    }
  }
  
  
}
