<div class="get-started-container">
    <h1 class="title">Get started with Auto E Care</h1>
    <p class="subtitle">Join us and make car care easy and reliable!</p>
  
    <div class="card-container">
      <div
        class="card"
        (click)="navigateTo('service-provider')"
        pRipple
      >
        <img
          src="assets/images/thank-you.jpg"
          alt="Service Provider"
          class="card-image"
        />
        <p class="card-text">Are you a Service Provider?</p>
      </div>
  
      <div
        class="card"
        (click)="navigateTo('service-seeker')"
        pRipple
      >
        <img
          src="assets/images/asking-question.jpg"
          alt="Service Seeker"
          class="card-image"
        />
        <p class="card-text">Are you a Service Seeker?</p>
      </div>
    </div>
  </div>
  
  